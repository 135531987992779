import { Redirect } from 'react-router-dom'

export const APIError = ({
  error,
  withRedirect = false,
}: {
  error: any
  withRedirect?: boolean
}) => {
  if (!error) return null
  if (!error.response) return <div>Something went wrong...</div>
  switch (error.response.status) {
    case 400:
      return <div>There was an error handling your request..</div>
    case 401:
      return <div>Unable to authenticate request</div>
    case 403:
      return withRedirect ? (
        <Redirect to="/login" />
      ) : (
        <div>Your username or password were not correct</div>
      )
    case 500:
      return <div>An internal server error occured</div>
    case 502:
      return <div>This request is taking longer than expected. Please try again later</div>
    case 503:
      return <div>This request is taking longer than expected. Please try again later</div>
    case 504:
      return <div>This request is taking longer than expected. Please try again later</div>
    default:
      return <div>Something went wrong...</div>
  }
}
