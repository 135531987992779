import { getV2ApiUrl } from '../../../api/auth'
import { axiosRequest } from '../../../utils/axiosRequest'

export interface PartnerEditableData {
  name?: string
  paymentStructureId?: number
  invoiceFirstName?: string
  invoiceLastName?: string
  invoiceCcEmails?: string[]
  invoiceEmail?: string
  ppgfEmail?: string | null
  inKindDonations?: boolean
  usePpgf?: boolean
  transactionVerificationRequired?: boolean
  isActive?: boolean
  liveInCbe?: boolean
  onDiscovery?: boolean
  invoiceMinimum?: number | null
  donateForUnredeemed?: boolean
  locations?: number
}

interface PartnerUpdateApiResponse {
  chainId: number
  status: string
}

export async function updatePartnerData(
  chainId: number,
  partnerEditData: PartnerEditableData
): Promise<PartnerUpdateApiResponse> {
  const res = await axiosRequest('put', `${getV2ApiUrl()}/admin/partner/${chainId}/edit`, {
    ...partnerEditData,
    paymentStructureId: partnerEditData.paymentStructureId
      ? +partnerEditData.paymentStructureId
      : undefined,
  })
  return res.data as PartnerUpdateApiResponse
}
