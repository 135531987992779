/**
 *
 * @param numerator {number | null} - Numerator. It is assumed that this will always be a decimal number (< 1).
 * @param [denominator = undefined] {number | null | undefined} - Denominator.
 * @param [decimalDigitsMax = 1] {number | null | undefined} - The number of decimal places to display.
 * @param [exact = false] {boolean | undefined} - show percentages higher than 99% or lower than 1%.
 *    (e.g. with exact=true 0.005 --> 0.5%)
 *    (e.g. with exact=false 0.005 --> <1% )
 * @returns {string}
 */
const percentFormat = (numerator, denominator, decimalDigitsMax = 1, exact = false) => {
  if (numerator === null) return '-'

  let percentage = numerator * 100

  if (denominator !== undefined && denominator !== null && denominator !== 0) {
    percentage = (numerator / denominator) * 100
  }

  if (isNaN(percentage) || percentage >= Infinity) return '-'
  if (percentage < 1 && !exact) return '<1%'
  if (percentage > 99 && !exact) return '>99%'

  // if the number is single digit add a decimal place, unless the decimal place has no value.
  if (percentage < 10) {
    const hasTrailingDecimal = percentage % 1 > 0
    decimalDigitsMax = hasTrailingDecimal && !exact ? 1 : 0
  }

  // show 4 decimal places when percentage number is very small
  if (exact && percentage < 0.01) {
    decimalDigitsMax = 4
  }

  return `${percentage.toFixed(decimalDigitsMax || 0)}%`
}

/**
 * Returns the given value in dollar format. Invalid values return 'N/A'
 * @param value {number | null | undefined}
 * @param [decimalPlaces=0] {number | undefined} Number of decimal places to show. None by default.
 * Does NOT round up whole numbers.
 * @returns {string}
 *
 * @example
 * dollarFormat(100.558) // --> '$100'
 * dollarFormat(100.558, 2) // --> '$100.56'
 */
const dollarFormat = (value, decimalPlaces = 0) => {
  if ((!value && value !== 0) || typeof value !== 'number') return 'N/A'

  // avoid showing 0 when there's a non-zero value
  if (decimalPlaces === 0 && ((value < 1 && value > 0) || (value > -1 && value < 0))) {
    return value.toLocaleString('en-us', {
      style: 'currency',
      currency: 'USD',
      maximumFractionDigits: 2,
    })
  }

  return value.toLocaleString('en-us', {
    style: 'currency',
    currency: 'USD',
    maximumFractionDigits: decimalPlaces,
  })
}

function debounce(func, timeout = 300) {
  let timer
  return (...args) => {
    // eslint-disable-next-line no-undef
    window.clearTimeout(timer)
    // eslint-disable-next-line no-undef
    timer = window.setTimeout(() => {
      func.apply(this, args)
    }, timeout)
  }
}

const processApiError = error => {
  if (!error || !error.response) return 'Something went wrong...'
  if (error.response.data.error) return JSON.stringify(error.response.data.error)
  if (error.response.data) return JSON.stringify(error.response.data)
  if (error.response.statusMessage) return JSON.stringify(error.response.statusMessage)
  return 'Something went wrong...'
}

const dateFileFormat = () => {
  const now = new Date()
  let month = now.getMonth() + 1
  month = month < 10 ? '0' + month : month
  let date = now.getDate()
  date = date < 10 ? '0' + date : date
  return `${now.getFullYear()}_${month}_${date}`
}

/**
 * @param date {string | Date}
 */
const htmlDateFormat = inputDate => {
  const date = new Date(inputDate)
  let month = date.getMonth() + 1
  month = month < 10 ? `0${month}` : month

  let day = date.getDate()
  day = day < 10 ? `0${day}` : day

  return `${date.getFullYear()}-${month}-${day}`
}

export { percentFormat, dollarFormat, debounce, processApiError, dateFileFormat, htmlDateFormat }
