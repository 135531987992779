import '../../style/root/navigation.css'

import Badge from '@material-ui/core/Badge'
import AccountBoxOutlinedIcon from '@material-ui/icons/AccountBoxOutlined'
import AccountCircleOutlinedIcon from '@material-ui/icons/AccountCircleOutlined'
import ApartmentIcon from '@material-ui/icons/Apartment'
import ExitToAppOutlinedIcon from '@material-ui/icons/ExitToAppOutlined'
import HomeOutlinedIcon from '@material-ui/icons/HomeOutlined'
import ReceiptOutlinedIcon from '@material-ui/icons/ReceiptOutlined'
import AddBusinessIcon from '@mui/icons-material/AddBusiness'
import EngineeringIcon from '@mui/icons-material/Engineering'
import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1'

import { isAdminOrSuper } from '../../utils/helpers/isAdminOrSuper'
import { TUser } from '../../utils/types'
import { SideNavLink } from '../root/SideNavLink'

interface AdminSideNavBarProps {
  user: TUser
  pathname: string | null
  toggleFunction?: () => void
  invoicesCount: number
}

export const AdminSideNavBar = ({
  user,
  pathname,
  toggleFunction,
  invoicesCount,
}: AdminSideNavBarProps) => {
  return (
    <div className="nav-list" onClick={toggleFunction} onKeyDown={toggleFunction}>
      <SideNavLink path="/" active={pathname === '/'}>
        <HomeOutlinedIcon />
        <div>Home</div>
      </SideNavLink>
      <SideNavLink path="/admin/partners/all" active={pathname === '/admin/partners/all'}>
        <AccountBoxOutlinedIcon />
        <div>Our Partners</div>
      </SideNavLink>
      {isAdminOrSuper(user?.type) && (
        <SideNavLink path="/admin/nonprofits/all" active={pathname === '/admin/nonprofits/all'}>
          <ApartmentIcon />
          <div>Our Nonprofits</div>
        </SideNavLink>
      )}
      <SideNavLink path="/admin/invoices" active={pathname === '/admin/invoices'}>
        <ReceiptOutlinedIcon />
        <div>Invoices</div>
        <Badge
          overlap="rectangular"
          badgeContent={invoicesCount}
          color="secondary"
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}>
          <div style={{ height: '16px', width: '20px' }} />
        </Badge>
      </SideNavLink>
      <SideNavLink path="/profile" active={pathname === '/profile'}>
        <AccountCircleOutlinedIcon />
        <div>{user && user.firstName}</div>
      </SideNavLink>
      {isAdminOrSuper(user?.type) && (
        <SideNavLink path="/admin/apikeys" active={pathname === '/admin/apikeys'}>
          <EngineeringIcon />
          <div>Api Keys</div>
        </SideNavLink>
      )}
      {isAdminOrSuper(user?.type) && (
        <SideNavLink path="/admin/createuser" active={pathname === '/admin/createuser'}>
          <PersonAddAlt1Icon />
          <div>Create User</div>
        </SideNavLink>
      )}
      {isAdminOrSuper(user?.type) && (
        <SideNavLink path="/admin/createpartner" active={pathname === '/admin/createpartner'}>
          <AddBusinessIcon />
          <div>Add Partner</div>
        </SideNavLink>
      )}
      <SideNavLink path="/logout" active={pathname === '/logout'}>
        <ExitToAppOutlinedIcon />
        <div>Log out</div>
      </SideNavLink>
    </div>
  )
}
