import { API_BASE_PATH_PORTAL } from '../../api/auth'
import { axiosRequest } from '../../utils/axiosRequest'
import { isAdminUser } from '../../utils/isAdminUser'
import { setAccessToken, setLoadingUser, setSite, setSites, setUser } from '../actions'

export const fetchUser = () => {
  return dispatch => {
    dispatch(setLoadingUser({ loading: true, error: null }))
    axiosRequest('get', `${API_BASE_PATH_PORTAL}/users/auth/session`)
      .then(res => {
        dispatch(setUser(res.data))
        dispatch(setLoadingUser({ loading: false, error: null }))
      })
      .catch(error => {
        dispatch(setLoadingUser({ loading: false, error }))
        dispatch(setUser({}))
      })
  }
}

export const updateUser = props => {
  return dispatch => {
    dispatch(setLoadingUser({ loading: true, error: null, message: 'Updating your account' }))
    axiosRequest('put', `${API_BASE_PATH_PORTAL}/users/update/one`, { ...props })
      .then(() => {
        dispatch(fetchUser())
      })
      .catch(error => {
        dispatch(setLoadingUser({ loading: false, error }))
      })
  }
}

export function logIn({ email, password }, isReskin = false) {
  return async dispatch => {
    dispatch(setLoadingUser({ loading: true, error: null }))

    try {
      const res = await axiosRequest('post', `${API_BASE_PATH_PORTAL}/v2/users/auth/login`, {
        email,
        password,
        isReskin,
      })

      const responseData = res.data || undefined
      const user = responseData?.user || null

      const isAdmin = isAdminUser(user)
      const isTwoFactorResponse = !!responseData?.user?.twoFactorToken

      if (isTwoFactorResponse || isAdmin) {
        dispatch(setAccessToken(responseData?.accessToken || null))
        dispatch(setUser(user))
        dispatch(setLoadingUser({ loading: false, error: null }))
      } else {
        dispatch(setLoadingUser({ loading: false, error: 'Invalid email or password' }))
      }
    } catch (error) {
      dispatch(setLoadingUser({ loading: false, error }))
    }
  }
}

export const twoFactorLogin = (token, id, code) => {
  return dispatch => {
    dispatch(setLoadingUser({ loading: true, error: null }))
    axiosRequest('post', `${API_BASE_PATH_PORTAL}/users/auth/two-factor-login`, { token, id, code })
      .then(res => {
        dispatch(setAccessToken(res?.data?.accessToken || null))
        dispatch(setUser(res.data.user))
        dispatch(setLoadingUser({ loading: false, error: null }))
      })
      .catch(error => {
        dispatch(setLoadingUser({ loading: false, error }))
      })
  }
}

export const logOut = userId => {
  return dispatch => {
    dispatch(setLoadingUser({ loading: true, error: null }))
    axiosRequest('delete', `${API_BASE_PATH_PORTAL}/v2/users/auth/logout`, { userId })
      .then(() => {
        dispatch(setUser({}))
        dispatch(setAccessToken(null))
        dispatch(setSites(null))
        dispatch(setSite(null))
        dispatch(setLoadingUser({ loading: false, error: null }))
      })
      .catch(error => {
        dispatch(setUser({}))
        dispatch(setSites(null))
        dispatch(setSite(null))
        dispatch(setLoadingUser({ loading: false, error }))
      })
  }
}
