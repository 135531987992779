export const validatePassword = (p1: string, p2: string) => {
  if (p1 !== p2) return 'Passwords do not match'
  if (p1.length < 8) return 'Password must be at least 8 characters'
  if (!/[A-Z]/.test(p1)) return 'Password must contain at least one uppercase letter'
  if (!/[a-z]/.test(p1)) return 'Password must contain at least one lowercase letter'
  if (!/[0-9]/.test(p1)) return 'Password must contain at least one number'
}

export const authErrorHelper = (error: any) => {
  const res = JSON.stringify(
    (error && error.response && error.response.data && error.response.data.error) || error
  )
  if (res?.includes('User not found')) {
    return 'User not found, your session may have timed out. Please try again.'
  }
  if (
    res?.includes('invalid token') ||
    res?.includes('Inactive session') ||
    res?.includes('expired')
  ) {
    return 'Seems like your session has timed out. Please try again.'
  }
  return 'Seems like something went wrong. Please try again or reach out to us through another method.'
}

export const submissionErrorHelper = (error: any) => {
  const res = JSON.stringify(
    (error && error.response && error.response.data && error.response.data.error) || error
  )
  if (res?.includes('Passwords do not match')) {
    return 'Passwords do not match'
  }
  if (res?.toLowerCase().includes('user')) {
    return 'User not found, your session may have timed out. Please try again or reach out to us through another method.'
  }
  return 'Seems like something went wrong. Please try again or reach out to us through another method.'
}
