import { useDispatch } from 'react-redux'

import { fetchInvoices } from '../../../redux/thunks/invoiceThunks'
import { BeamButton } from '../../../stories/BeamButton'
import { BeamModal } from '../../../stories/BeamModal'
import { updateLineItemPaidStatus } from './AdminInvoices.api'

interface MarkLineItemPaidConfirmationModalProps {
  open: boolean
  lineItem: Record<string, any> | null
  unselectLineItem: () => void
}

export const MarkLineItemPaidConfirmationModal = ({
  open,
  lineItem,
  unselectLineItem,
}: MarkLineItemPaidConfirmationModalProps) => {
  const dispatch = useDispatch()

  async function handleMarkLineItemPaid() {
    if (!lineItem) return

    const lineItemId = lineItem.id
    try {
      await updateLineItemPaidStatus({ lineItemId: lineItem.id })
    } catch (error: any) {
      console.log(`Failed to mark lineItem ${lineItemId} as paid --- Error: ${error.message}`)
    }

    dispatch(fetchInvoices())
  }

  return (
    <BeamModal
      open={open && !!lineItem}
      body={
        <div className={'pt-4 flex flex-col items-center'}>
          <p className={'text-center'}>
            Are you sure you want to mark line item <strong>&quot;{lineItem?.name}&quot;</strong> as
            paid?
          </p>

          <div className={'pt-12 pb-6'}>
            <BeamButton
              variant={'elevated'}
              label={'Mark as paid'}
              className={'w-min'}
              onClick={handleMarkLineItemPaid}
            />
          </div>
        </div>
      }
      label={'Mark line item as paid'}
      onCloseCallback={unselectLineItem}
    />
  )
}
