import { lazy, memo, useCallback, useEffect, useReducer, useRef, useState } from 'react'
import { useParams } from 'react-router'

import { BeamButton } from '../../../stories/BeamButton'
import { BeamToast } from '../../../stories/BeamToast'
import { PartnerUploadBody } from '../../../utils/types'
import { BeamSEO } from '../../root/BeamSEO'
import { PartnerUploadsTable } from '../../root/PartnerUploadsTable/PartnerUploadsTable'
import {
  deletePartnerUpload,
  fetchAdminPartnerUploads,
  requestPresignedUploadUrl,
  restorePartnerUpload,
  saveNewPartnerUpload,
  uploadFileToS3,
} from './AdminPartnerUploadsPage.api'
import {
  adminPartnerUploadsInitialState,
  adminPartnerUploadsReducer,
} from './adminPartnerUploadsReducer'
import { ArchivedPartnerUploadsTable } from './ArchivedPartnerUploadsTable'

const ConfirmDeletePartnerUploadModalLazy = lazy(() =>
  import('./ConfirmDeletePartnerUploadModal').then(module => ({
    default: module.ConfirmDeletePartnerUploadModal,
  }))
)

const mimeTypeToFileExtensionMap = {
  'application/pdf': 'pdf',
}
const allowedFileTypes = Object.keys(mimeTypeToFileExtensionMap)

// handles fetching presigned upload url, uploading file to s3, and saving the record in the db
async function handleFileUpload(newFile: File, chainId: number): Promise<PartnerUploadBody> {
  // fetch presigned url
  const fileExtension =
    mimeTypeToFileExtensionMap[newFile.type as keyof typeof mimeTypeToFileExtensionMap]

  if (!fileExtension) {
    throw new Error('Reports can only be uploaded as a pdf')
  }

  const presignedUploadUrlResponse = await requestPresignedUploadUrl({
    chainId,
    fileName: newFile.name,
    fileExtension,
  })

  // upload the file to s3
  const { success } = await uploadFileToS3(presignedUploadUrlResponse.presignedUploadUrl, newFile)

  if (!success) {
    throw new Error('Failed to upload file. Please try again.')
  }

  // save the file
  const savedPartnerUpload = await saveNewPartnerUpload({
    chainId,
    fileName: presignedUploadUrlResponse.metaData.fileName,
    s3ObjectKey: presignedUploadUrlResponse.metaData.s3ObjectKey,
    s3BucketName: presignedUploadUrlResponse.metaData.s3BucketName,
  })

  return savedPartnerUpload
}

const UploadFileButton = memo(function UploadFileButton(props: {
  chainId: number
  reFetchPartnerUploads: () => Promise<void>
  setError: (error: string) => void
  loading: boolean
}) {
  const inputFileRef = useRef<HTMLInputElement>(null)
  const { chainId, reFetchPartnerUploads, setError } = props

  // handles uploading process when the user selects a file
  function onFileUpload(file: File) {
    handleFileUpload(file, chainId)
      .then(result => {
        // rehydrate the page
        if (result) {
          reFetchPartnerUploads()
        }
      })
      .catch(error => {
        console.error(error)
        setError(error.message)
      })
  }

  return (
    <>
      <input
        type={'file'}
        ref={inputFileRef}
        className={'hidden'}
        accept={allowedFileTypes.join(',')}
        onChange={e => {
          const target = e.target as HTMLInputElement
          if (target.files) {
            onFileUpload(target.files[0])
          }
        }}
      />
      <BeamButton
        label={'Upload PDF'}
        className={'min-w-[140px]'}
        fontWeight={'medium'}
        onClick={() => {
          inputFileRef?.current?.click()
        }}
        disabled={props.loading}
      />
    </>
  )
})

export const AdminPartnerUploadsPage = () => {
  const routeParams: any = useParams()
  const chainId: number = routeParams.chainId
  const [state, dispatch] = useReducer(adminPartnerUploadsReducer, adminPartnerUploadsInitialState)
  const { partnerUploads, loading, errorMessage } = state
  const [selectedDeleteFile, setSelectedDeleteFile] = useState<PartnerUploadBody | null>(null)

  // fetches partner uploads and updates state
  const handleReFetchPartnerUploads = useCallback(async () => {
    dispatch({ type: 'loading' })

    try {
      const data = await fetchAdminPartnerUploads(chainId)
      dispatch({ type: 'data_loaded', partnerUploads: data })
    } catch (error: any) {
      console.error(error)
      dispatch({ type: 'error', errorMessage: error.message, loading: false })
    }
  }, [chainId])

  // fetch partner uploads on first render
  useEffect(() => {
    if (!chainId) return

    handleReFetchPartnerUploads()
  }, [chainId, handleReFetchPartnerUploads])

  const handleDeletePartnerUpload = useCallback(
    async (partnerUploadId: string) => {
      const { ok } = await deletePartnerUpload(partnerUploadId)

      if (!ok) {
        dispatch({ type: 'error', errorMessage: 'Failed to delete file. Please try again.' })
        return
      }

      // close the modal and refresh the table
      setSelectedDeleteFile(null)
      await handleReFetchPartnerUploads()
    },
    [handleReFetchPartnerUploads]
  )

  const handleRestorePartnerUpload = useCallback(
    async (partnerUpload: PartnerUploadBody) => {
      dispatch({ type: 'loading' })

      try {
        const data = await restorePartnerUpload(partnerUpload.id)

        if (!data) {
          dispatch({
            type: 'error',
            errorMessage: 'Failed to restore file. Please try again.',
            loading: false,
          })
          return
        }

        await handleReFetchPartnerUploads()
        dispatch({ type: 'end_loading' })
      } catch (error: any) {
        console.error(error)
        dispatch({
          type: 'error',
          errorMessage: 'Failed to restore file. Please try again.',
          loading: false,
        })
      }
    },
    [handleReFetchPartnerUploads]
  )

  const activePartnerUploads: PartnerUploadBody[] = partnerUploads.filter(
    partnerUpload => partnerUpload.deletedAt === null
  )
  const archivedPartnerUploads: PartnerUploadBody[] = partnerUploads.filter(
    partnerUpload => partnerUpload.deletedAt !== null
  )

  if (!chainId) return null

  return (
    <>
      <BeamSEO title={'Partner Uploads'} />

      <main className={'pt-14 pr-20'}>
        <BeamToast
          open={!!errorMessage}
          text={errorMessage || ''}
          variant={'error'}
          onClose={() => dispatch({ type: 'error', errorMessage: null, loading: false })}
        />

        <ConfirmDeletePartnerUploadModalLazy
          isOpen={!!selectedDeleteFile}
          fileData={selectedDeleteFile}
          onClose={() => {
            setSelectedDeleteFile(null)
          }}
          handleDeletePartnerUpload={handleDeletePartnerUpload}
        />

        <div className={'flex flex-row justify-between'}>
          <div className={'pr-5'}>
            <h1 className="my-0 text-xl leading-none">Custom Reports</h1>
            <p className={'text-md pt-2'}>
              Please name each document with report type (e.g. AB, ROI, Campaign) followed by the
              date range for the report (e.g. “AB Test Results 02.02.2023 - 03.03.23”)
            </p>
          </div>
          <div>
            <UploadFileButton
              chainId={chainId}
              reFetchPartnerUploads={handleReFetchPartnerUploads}
              setError={(error: string) =>
                dispatch({ type: 'error', errorMessage: error, loading: false })
              }
              loading={loading}
            />
          </div>
        </div>

        <div className={'pt-12'}>
          <PartnerUploadsTable
            data={activePartnerUploads}
            isAdminView
            loading={loading}
            onClickDelete={fileToDelete => setSelectedDeleteFile(fileToDelete)}
          />
        </div>

        {archivedPartnerUploads.length > 0 && (
          <div className={'pt-12'}>
            <ArchivedPartnerUploadsTable
              data={archivedPartnerUploads}
              loading={loading}
              onClickRestore={handleRestorePartnerUpload}
            />
          </div>
        )}
      </main>
    </>
  )
}
