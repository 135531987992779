import SlMenuItem from '@shoelace-style/shoelace/dist/react/menu-item'
import { useCallback, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router'
import { useHistory } from 'react-router-dom'

import { useBeamSelector } from '../../hooks'
import { fetchCentralBackendPartner } from '../../redux/thunks/adminThunks'
import { updateCentralBackendPartner } from '../../redux/thunks/newAdminThunks'
import { BeamTrueDropdownMenu } from '../../stories/BeamTrueDropdownMenu'
import styles from '../../style/partner/cb-partner-report.module.css'
import { APIError } from '../root/APIError'
import BeamButton from '../root/BeamButton'
import BeamTable from '../root/BeamTable'
import { LoginPage } from '../root/LoginPage'
import { PageNotFound } from '../root/PageNotFound'
import { mappingStoreData } from './CentralBackendPartnerReport.helpers'
import {
  AggregateTargetChainNonprofitInfo,
  CBPartnerInfo,
  StoreInfo,
} from './CreateCentralBackendPartner'
import { EditCBPartnerForm } from './EditCBPartnerForm'

const NonprofitInfoTable = ({
  targetChainNonprofitInfo,
}: {
  targetChainNonprofitInfo: AggregateTargetChainNonprofitInfo[]
}): React.ReactElement => {
  const tableHeaders = [
    {
      field: 'name',
      headerName: 'Name',
      dataType: 'string',
    },
    {
      field: 'targetUserDonationAmount',
      headerName: 'Personal Target Amount',
      dataType: 'number',
    },
    {
      field: 'userImpactDescription',
      headerName: 'Personal Target Text',
      dataType: 'string',
    },
    {
      field: 'targetChainDonationAmount',
      headerName: 'Community Target Amount',
      dataType: 'number',
    },
    {
      field: 'chainImpactDescription',
      headerName: 'Community Target Text',
      dataType: 'string',
    },
    {
      field: 'chainImpactImage',
      headerName: 'Community Target Image',
      dataType: 'string',
    },
    {
      field: 'causeDisplayName',
      headerName: 'Cause Display Name',
      dataType: 'string',
    },
    {
      field: 'causeDisplayIcon',
      headerName: 'Cause Icon',
      dataType: 'string',
    },
    {
      field: 'causeSelectedImage',
      headerName: 'Cause Selected Icon',
      dataType: 'string',
    },
    {
      field: 'sortOrder',
      headerName: 'Sort Order',
      dataType: 'number',
    },
    {
      field: 'active',
      headerName: 'Active',
      dataType: 'string',
    },
    {
      field: 'includeInPersonalImpact',
      headerName: 'Include In Personal Impact',
      dataType: 'boolean',
    },
    {
      field: 'filters',
      headerName: 'Filters',
      dataType: 'string',
    },
    {
      field: 'id',
      headerName: 'ID',
      dataType: 'number',
    },
  ]

  const targetChainNonprofitInfoRows = targetChainNonprofitInfo.map(
    (nonprofitInfo: AggregateTargetChainNonprofitInfo) => {
      return {
        ...nonprofitInfo,
        causeDisplayIcon: {
          value: (
            <a target="_blank" rel="noreferrer" href={nonprofitInfo.causeDisplayIcon}>
              {nonprofitInfo.causeDisplayIcon}
            </a>
          ),
          sortOn: nonprofitInfo.causeDisplayIcon,
        },
        causeSelectedImage: {
          value: (
            <a target="_blank" rel="noreferrer" href={nonprofitInfo.causeSelectedImage}>
              {nonprofitInfo.causeSelectedImage}
            </a>
          ),
          sortOn: nonprofitInfo.causeSelectedImage,
        },
        chainImpactImage: {
          value: (
            <a target="_blank" rel="noreferrer" href={nonprofitInfo.chainImpactImage}>
              {nonprofitInfo.chainImpactImage}
            </a>
          ),
          sortOn: nonprofitInfo.chainImpactImage,
        },
        active: {
          value:
            nonprofitInfo.active !== null && nonprofitInfo.active !== undefined
              ? nonprofitInfo?.active.toString()
              : '',
          sortOn:
            nonprofitInfo.active !== null && nonprofitInfo.active !== undefined
              ? nonprofitInfo?.active.toString()
              : '',
        },
        includeInPersonalImpact: {
          value:
            nonprofitInfo.includeInPersonalImpact !== null &&
            nonprofitInfo.includeInPersonalImpact !== undefined
              ? nonprofitInfo?.includeInPersonalImpact.toString()
              : '',
          sortOn:
            nonprofitInfo.includeInPersonalImpact !== null &&
            nonprofitInfo.includeInPersonalImpact !== undefined
              ? nonprofitInfo?.includeInPersonalImpact.toString()
              : '',
        },
        filters: {
          value: nonprofitInfo.filters?.toString() || '',
          sortOn: nonprofitInfo.filters?.toString() || '',
        },
        id: {
          value: nonprofitInfo.nonprofitId,
          sortOn: nonprofitInfo.nonprofitId,
        },
      }
    }
  )

  return (
    <div>
      <BeamTable
        title="Nonprofits"
        headers={tableHeaders}
        rows={targetChainNonprofitInfoRows}
        id="nonprofit-info-table"
        defaultSortAscending={true}
        defaultSortColumn="targetChainDonationAmount"
        defaultSortDataType="string"
        rowStyle={{ height: '200px' }}
      />
    </div>
  )
}

export const CentralBackendPartnerReport = () => {
  const [inEditMode, setInEditMode] = useState(false)
  const [partnerData, setPartnerData] = useState<CBPartnerInfo>()
  const [updatedPartnerData, setUpdatedPartnerData] = useState<CBPartnerInfo>()
  const user = useBeamSelector((state: any) => state.user)
  const history = useHistory()

  const dispatch = useDispatch()
  const { chain, loadingStates }: { chain: any; loadingStates: any } = useBeamSelector(state => ({
    chain: state.cbPartner,
    loadingStates: state.loadingStates,
  }))

  const { id }: any = useParams()
  useEffect(() => {
    dispatch(fetchCentralBackendPartner(id))
  }, [dispatch, id])

  // Format the nonprofits from the partner response into an object with the data we need
  const mapNonprofitData = useCallback((): AggregateTargetChainNonprofitInfo[] | null => {
    if (!chain) return null

    const targets = chain.donationType?.targets
    const chainNonprofits = chain.chainNonprofits

    if (!targets || !chainNonprofits || chainNonprofits.length === 0 || targets.length === 0)
      return null

    const result: AggregateTargetChainNonprofitInfo[] = targets.reduce(
      (resultArr: AggregateTargetChainNonprofitInfo[], target: any) => {
        const chainNonprofit = chainNonprofits.find(
          (cnp: any) => cnp.nonprofitId == target.nonprofitId
        )
        if (chainNonprofit) {
          const result: AggregateTargetChainNonprofitInfo = {
            chainNonprofitId: chainNonprofit.id,
            active: chainNonprofit.active,
            causeDisplayIcon: chainNonprofit.causeDisplayIcon,
            chainImpactImage: target.chainImpactImage,
            causeDisplayName: chainNonprofit.causeDisplayName,
            causeSelectedImage: chainNonprofit.causeSelectedImage,
            chainImpactDescription: target.chainImpactDescription,
            includeInPersonalImpact: chainNonprofit.includeInPersonalImpact,
            filters: chainNonprofit.filters,
            name: chainNonprofit.nonprofit.name,
            nonprofitId: chainNonprofit.nonprofitId,
            sortOrder: chainNonprofit.sortOrder,
            targetChainDonationAmount: target.targetChainDonationAmount,
            targetUserDonationAmount: target.targetUserDonationAmount,
            userImpactDescription: target.userImpactDescription,
          }
          resultArr.push(result)
        }

        return resultArr
      },
      []
    )

    return result
  }, [chain])

  const mapStoreData = useCallback((): StoreInfo[] | null => {
    return mappingStoreData(chain)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chain.name, chain.stores])

  useEffect(() => {
    if (!chain) return

    // map the partner data to an object we understand
    const newPartner: CBPartnerInfo = {
      id: chain.id,
      name: chain.name,
      logoImage: chain?.logoImage,
      communityImpactLink: chain.communityImpactLink,
      isPercentBased: chain.donationType?.isPercentBased,
      isInKind: chain.donationType?.isInKind,
      donationPercentage: chain.donationType?.donationPercentage,
      donationAmount: chain.donationType?.donationAmount,
      useRegionalization: chain.stores ? chain.stores.length > 1 : false,
      targetChainNonprofitInfo: mapNonprofitData() || [],
      stores: mapStoreData() || [],
    }

    setPartnerData(newPartner)
    setUpdatedPartnerData(newPartner)
  }, [mapNonprofitData, mapStoreData, chain, setPartnerData, setUpdatedPartnerData])

  const closeEditView = () => {
    setUpdatedPartnerData(partnerData)
    setInEditMode(!inEditMode)
  }

  if (loadingStates.user && loadingStates.user.loading) return <div>One moment please...</div>
  if (loadingStates.user && loadingStates.user.error)
    return <APIError error={loadingStates.user.error} />
  if (!user.type) return <LoginPage />
  if (['Admin', 'Super'].indexOf(user.type) === -1) return <PageNotFound />

  if (!inEditMode && loadingStates.cbPartner && loadingStates.cbPartner.loading)
    return <div>Getting partner data...</div>
  if (!inEditMode && loadingStates.cbPartner && loadingStates.cbPartner.error)
    return (
      <div>
        <h2>{chain && chain.name}</h2>
        <APIError error={loadingStates.cbPartner.error} withRedirect={true} />
      </div>
    )

  if (!partnerData || (!partnerData.id && !partnerData.name)) {
    return <h2>No Central Backend partner data found.</h2>
  }

  return (
    <section className={styles.CentralBackendPartnerReport}>
      <div className="flex flex-col items-end space-y-4">
        <BeamButton
          text={inEditMode ? 'Done' : 'Edit'}
          variant={inEditMode ? 'outlined' : 'filled'}
          handler={() => closeEditView()}
          style={{ width: 100, height: 40 }}
        />

        <BeamTrueDropdownMenu
          label={'Menu'}
          menuContents={
            <>
              {[
                {
                  label: 'Customize Widgets',
                  value: `/admin/partners/${chain.id}/widget-config`,
                },
                { label: 'Klaviyo', value: `/admin/partners/${chain.id}/klaviyo` },
                { label: 'Sales Channels', value: `/admin/partners/${chain.id}/salesChannels` },
                { label: 'Partner Uploads', value: `/admin/partners/${chain.id}/uploads` },
                { label: 'Discounts', value: `/admin/partners/${chain.id}/discounts` },
              ].map(item => (
                <SlMenuItem
                  value={item.value}
                  key={item.value}
                  onClick={e => {
                    e.preventDefault()
                    history.push(item.value)
                  }}>
                  {item.label}
                </SlMenuItem>
              ))}
            </>
          }
        />
      </div>

      <div className={'flex justify-end'} />

      {inEditMode && updatedPartnerData ? (
        <EditCBPartnerForm
          formTitle="Update Partner In Central Backend"
          partnerData={updatedPartnerData}
          setPartnerData={setUpdatedPartnerData}
          submitHandler={formData => dispatch(updateCentralBackendPartner(formData))}
        />
      ) : (
        <>
          <img src={chain?.logoImage} width={200} alt={`${chain.name} Logo`} />

          <h1>{chain.name}</h1>

          <div className={styles.LabelValueWrapper}>
            <p>Community Impact Link:</p>
            <p>{chain.communityImpactLink}</p>
          </div>

          <div className={styles.LabelValueWrapper}>
            <p>Donation Amount:</p>
            <p>{chain.donationType?.donationName}</p>
          </div>

          <NonprofitInfoTable targetChainNonprofitInfo={partnerData.targetChainNonprofitInfo} />

          <div className={styles.StoreSection}>
            <h2>Stores</h2>

            {partnerData.stores.map(store => {
              return (
                <div key={store.name} className={styles.StoreDetails}>
                  <h3>{store.name}</h3>

                  <div className={styles.LabelValueWrapper}>
                    <p>Store Id: </p>
                    <p>{store.id}</p>
                  </div>

                  <div className={styles.LabelValueWrapper}>
                    <p>Type: </p>
                    <p>{store.type}</p>
                  </div>

                  {store.communityPageURL && (
                    <div className={styles.LabelValueWrapper}>
                      <p>Store Site: </p>
                      <p>{store.communityPageURL}</p>
                    </div>
                  )}

                  <div className={styles.StoreNonprofits}>
                    <p>Nonprofits: </p>
                    <ul>
                      {store.nonprofitIds?.map(nonprofitId => (
                        <li key={nonprofitId.value}>{nonprofitId.display}</li>
                      ))}
                    </ul>
                  </div>
                </div>
              )
            })}
          </div>
        </>
      )}
    </section>
  )
}
