import { useEffect } from 'react'
import { useDispatch } from 'react-redux'

import { useBeamSelector } from '../../hooks'
import { fetchPartnerByChainId } from '../../redux/thunks/adminThunks'
import { TPPPartner } from '../../utils/types'
import { APIError } from '../root/APIError'
import { LoginPage } from '../root/LoginPage'
import { PageNotFound } from '../root/PageNotFound'
import { PartnerReport } from './partnerReport/PartnerReport'
import { PartnerReport as PartnerReportV2 } from './partnerReport/PartnerReportV2'

const Partner = () => {
  const dispatch = useDispatch() // FIXME: Use `useDispatch` when we have typedefs
  const loadingStates = useBeamSelector(({ loadingStates }) => loadingStates) as any // FIXME: Remove any when we have typedefs for reducers
  const user = useBeamSelector(({ user }) => user)
  const partner = useBeamSelector(({ partner }) => partner) as TPPPartner // FIXME: Remove typecasting when we have typedefs for reducers

  const queryParams = new URLSearchParams(window.location.search)
  const chainId = queryParams.get('chainId')

  useEffect(() => {
    if (chainId) {
      dispatch(fetchPartnerByChainId(chainId))
    }
  }, [chainId, dispatch])

  if (loadingStates.user?.loading) return <div>One moment please...</div>
  if (loadingStates.user?.error) return <APIError error={loadingStates.user.error} />
  if (!user?.type) return <LoginPage />
  if (['Admin', 'Super'].indexOf(user.type) === -1) return <PageNotFound />
  if (loadingStates.partner?.loading) {
    return (
      <div>
        <h2>{partner && partner.name}</h2>
        <div>Getting brand data...</div>
      </div>
    )
  }
  if (loadingStates.partner?.error) {
    return (
      <div>
        <h2>{partner && partner.name}</h2>
        <APIError error={loadingStates.partner.error} withRedirect={true} />
      </div>
    )
  }
  if (location.pathname.includes('v2')) {
    return (
      <div>
        <PartnerReportV2 />
      </div>
    )
  }
  return (
    <div>
      <PartnerReport />
    </div>
  )
}

export default Partner
