import { createColumnHelper, Row, RowSelectionState } from '@tanstack/react-table'
import { capitalize, round } from 'lodash'
import { useMemo } from 'react'

import { BeamTable } from '../../../stories/BeamTable'
import { AdminDiscountsBody } from '../../../utils/types'

interface DiscountsTableProps {
  nonDeletedDiscounts: AdminDiscountsBody[]
  selectedRows: RowSelectionState
  onRowSelectionChange: (newRowSelectionState: RowSelectionState) => void
}

export const DiscountsTable = ({
  nonDeletedDiscounts,
  selectedRows,
  onRowSelectionChange,
}: DiscountsTableProps) => {
  const columnHelper = createColumnHelper<AdminDiscountsBody>()

  const columns = useMemo(
    () => [
      columnHelper.accessor('discountPercent', {
        header: 'Discount %',
        cell: ({ row }) => {
          const integerPercentValue = row.original.discountPercent * 100
          return `${round(integerPercentValue, 2)}%`
        },
        enableSorting: false,
      }),
      columnHelper.accessor('status', {
        header: 'Status',
        enableSorting: true,
        cell: ({ row, column }) => {
          const value: string = row.getValue(column.id)
          return capitalize(value)
        },
      }),
      columnHelper.accessor('internalDiscountReason', {
        header: 'Reason',
        enableSorting: false,
      }),
      columnHelper.accessor('externalDiscountReason', {
        header: 'External Reason',
        enableSorting: false,
      }),
      columnHelper.accessor('reportPeriodName', {
        header: 'Invoice',
        enableSorting: false,
      }),
      columnHelper.accessor('createdBy', {
        header: 'Added By',
        enableSorting: true,
      }),
    ],
    [columnHelper]
  )

  return (
    <div>
      <BeamTable
        columns={columns}
        data={nonDeletedDiscounts}
        noDataMessage={'No discounts for this brand'}
        rowSelectionOptions={{
          selectedRows,
          onRowSelectionChange,
          enableRowSelection: (row: Row<AdminDiscountsBody>) => row.original.status === 'pending',
        }}
        enableSorting={true}
      />
    </div>
  )
}
