import { RowSelectionState } from '@tanstack/react-table'

import { AdminDiscountsBody } from '../../../utils/types'

// ============================ Type defs ============================

type DiscountFetchActions =
  | { type: 'loading_discounts' }
  | { type: 'end_loading_discounts' }
  | {
      type: 'discounts_loaded'
      discounts: AdminDiscountsBody[]
      errorMessage?: string | null
    }
  | {
      type: 'error_loading_discounts'
      errorMessage: string | null
      loading?: boolean
    }
type ModalActions =
  | {
      type: 'open_modal'
      modal: DiscountsPageModalsEnum
    }
  | {
      type: 'close_modals'
    }
type ActionTypes =
  | DiscountFetchActions
  | ModalActions
  | {
      type: 'row_selected'
      newSelectedRows: RowSelectionState
    }
  | {
      type: 'generic_error'
      errorMessage: string
    }

enum DiscountsPageModalsEnum {
  ADD_DISCOUNT = 'add_discount',
  DELETE_DISCOUNT = 'delete_discount',
}

type DiscountState = {
  discounts: AdminDiscountsBody[]
  loading: boolean
  discountsFetchError: string | null
}

interface AdminDiscountsPageState {
  discountsState: DiscountState
  selectedRows: RowSelectionState
  activeModal: DiscountsPageModalsEnum | null
  errorMessage: string | null
}

// ============================ State ============================

const adminDiscountsPageInitialState: AdminDiscountsPageState = {
  activeModal: null,
  discountsState: {
    discounts: [],
    loading: false,
    discountsFetchError: null,
  },
  selectedRows: {},
  errorMessage: null,
}

function adminDiscountsPageReducer(
  state: AdminDiscountsPageState,
  action: ActionTypes
): AdminDiscountsPageState {
  switch (action.type) {
    case 'loading_discounts': {
      return {
        ...state,
        discountsState: {
          ...state.discountsState,
          loading: true,
        },
      }
    }
    case 'end_loading_discounts': {
      return {
        ...state,
        discountsState: {
          ...state.discountsState,
          loading: false,
        },
      }
    }
    case 'discounts_loaded': {
      return {
        ...state,
        discountsState: {
          ...state.discountsState,
          loading: false,
          discounts: action.discounts,
          discountsFetchError: action.errorMessage || null,
        },
      }
    }
    case 'error_loading_discounts': {
      return {
        ...state,
        discountsState: {
          ...state.discountsState,
          loading: false,
          discountsFetchError: action.errorMessage || null,
        },
      }
    }
    case 'row_selected': {
      return {
        ...state,
        selectedRows: action.newSelectedRows,
      }
    }
    case 'open_modal': {
      return {
        ...state,
        activeModal: action.modal,
      }
    }
    case 'close_modals': {
      return {
        ...state,
        activeModal: null,
      }
    }
    case 'generic_error': {
      return {
        ...state,
        errorMessage: action.errorMessage,
      }
    }
  }
}

export { adminDiscountsPageReducer, adminDiscountsPageInitialState, DiscountsPageModalsEnum }
