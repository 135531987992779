import { REDESIGN_ROOT_PATH } from '../../../helpers'
import { TUser } from '../../../utils/types'
import { BeamSideNavigationLink } from '../BeamSideNavigationLink'

interface UserBadgeProps {
  user: TUser
  pathname: string | null
}

export const BeamUserBadge = ({ user, pathname }: UserBadgeProps) => {
  return (
    <div className="beam-navigation--links">
      <div className="beam-navigation--user-badge--header">Account</div>
      <div className="flex flex-row">
        <div className="flex items-center justify-center w-12 h-20 ml-4">
          <div>
            <img src={user.partner.logo} />
          </div>
        </div>
        <div className="beam-navigation--links beam-navigation--user-badge-links">
          <BeamSideNavigationLink
            key={'profile'}
            path={`${REDESIGN_ROOT_PATH}/profile`}
            itemLabel={'Settings'}
            active={
              pathname?.startsWith(`${REDESIGN_ROOT_PATH}/profile`) ||
              pathname?.startsWith(`${REDESIGN_ROOT_PATH}/paypal-giving-fund`)
            }>
            <span className="beam-navigation--link">Settings</span>
          </BeamSideNavigationLink>
          <BeamSideNavigationLink
            key={'logout'}
            path={`${REDESIGN_ROOT_PATH}/logout`}
            itemLabel={'Logout'}
            active={pathname?.startsWith(`${REDESIGN_ROOT_PATH}/logout`)}>
            <span className="beam-navigation--link">Log Out</span>
          </BeamSideNavigationLink>
        </div>
      </div>
    </div>
  )
}
