import '../../style/partner/invoices.css'

import { useState } from 'react'

import CreateCentralBackendPartner from './CreateCentralBackendPartner'
import { CreatePartner } from './CreatePartner'

export const CreatePartnerTabs = () => {
  const [showCentralBackendCreation, setShowCentralBackendCreation] = useState<boolean>(false)

  return (
    <div>
      <div style={{ display: 'flex', marginTop: '35px' }}>
        <div
          onClick={() => setShowCentralBackendCreation(false)}
          className="invoice-status-select"
          id={`${!showCentralBackendCreation ? 'selected-status' : ''}`}>
          Partner Portal
        </div>
        <div
          onClick={() => setShowCentralBackendCreation(true)}
          className="invoice-status-select"
          id={`${showCentralBackendCreation ? 'selected-status' : ''}`}>
          Central Backend
        </div>
      </div>
      <div>{showCentralBackendCreation ? <CreateCentralBackendPartner /> : <CreatePartner />}</div>
    </div>
  )
}
