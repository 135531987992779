import { API_BASE_PATH_PORTAL } from '../../../api/auth'
import { axiosRequest } from '../../../utils/axiosRequest'

export const resetPasswordAxios = async (
  password: string,
  confirmPassword: string,
  userId: number,
  token: string
) => {
  return await axiosRequest(
    'post',
    `${API_BASE_PATH_PORTAL}/v2/users/reset-password/${userId}/${token}`,
    {
      password,
      confirmPassword,
    }
  )
}

export const resetPasswordAuthAxios = async (userId: number, token: string) => {
  return await axiosRequest(
    'get',
    `${API_BASE_PATH_PORTAL}/users/reset-password-auth/${userId}/${token}`
  )
}
