import Box from '@material-ui/core/Box'
import CircularProgress from '@material-ui/core/CircularProgress'
import Typography from '@mui/material/Typography'

import styles from '../../style/root/beam-circular-progress.module.css'

export const BeamCircularProgress = ({ progress, label }: { progress: number; label?: string }) => {
  return (
    <Box className={styles.container} sx={{ position: 'relative', display: 'inline-flex' }}>
      <CircularProgress variant="determinate" value={progress} />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}>
        <Typography variant="caption" component="div">
          {label}
        </Typography>
      </Box>
    </Box>
  )
}
