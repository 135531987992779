import { MouseEventHandler } from 'react'

import styles from '../../style/root/beam-button.module.css'

interface BeamButtonProps {
  disabled?: boolean
  handler?: MouseEventHandler<HTMLButtonElement>
  href?: string
  // If `href` prop is provided, this makes the link open in a new tab. Default `true`.
  openInNewTab?: boolean
  selected?: boolean | undefined
  style?: React.CSSProperties
  text: string
  variant?: 'filled' | 'outlined' | 'gradient'
}

export const BeamButton = ({
  disabled = false,
  handler,
  href,
  openInNewTab = true,
  selected,
  style,
  text,
  variant = 'filled',
}: BeamButtonProps) => {
  const classes = {
    outlined: styles['button-outlined'],
    filled: styles.button,
    gradient: styles.button__gradient,
  }

  const selectedStyles = selected ? styles['button__gradient--selected'] : ''

  if (href) {
    let linkProps = {}
    if (openInNewTab) {
      linkProps = {
        rel: 'noreferrer',
        target: '_blank',
      }
    }
    return (
      <a
        {...linkProps}
        className={`${classes[variant]} ${selectedStyles}`}
        href={href}
        style={style}>
        {text}
      </a>
    )
  }

  return (
    <button
      onClick={handler}
      disabled={disabled}
      className={`${classes[variant]} ${selectedStyles}`}
      style={style}>
      {text}
    </button>
  )
}

export default BeamButton
