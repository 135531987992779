import { createColumnHelper, RowSelectionState } from '@tanstack/react-table'
import { capitalize, round } from 'lodash'

import { BeamTable } from '../../../stories/BeamTable'
import { AdminDiscountsBody } from '../../../utils/types'

interface DeletedDiscountsTableProps {
  discounts: AdminDiscountsBody[]
  selectedDiscounts?: RowSelectionState
  onRowSelectionChange?: (newRowSelectionState: RowSelectionState) => void
}

export const DeletedDiscountsTable = ({ discounts }: DeletedDiscountsTableProps) => {
  const columnHelper = createColumnHelper<AdminDiscountsBody>()

  const columns = [
    columnHelper.accessor('discountPercent', {
      header: 'Discount %',
      cell: ({ row }) => {
        const integerPercentValue = row.original.discountPercent * 100
        return `${round(integerPercentValue, 2)}%`
      },
    }),
    columnHelper.accessor('status', {
      header: 'Status',
      enableSorting: false,
      cell: ({ row, column }) => {
        const value: string = row.getValue(column.id)
        return capitalize(value)
      },
    }),
    columnHelper.accessor('deletedReason', {
      header: 'Reason for Deletion',
      enableSorting: false,
    }),
    columnHelper.accessor('reportPeriodName', {
      header: 'Invoice',
    }),
    columnHelper.accessor('deletedBy', {
      header: 'Deleted By',
      enableSorting: true,
    }),
    columnHelper.accessor('deletedAt', {
      header: 'Deletion Date',
      enableSorting: true,
      cell: ({ row }) => {
        const deletedAt = row.original.deletedAt
        if (!deletedAt) {
          return null
        }
        return new Date(deletedAt).toLocaleDateString()
      },
    }),
  ]

  return (
    <div>
      <BeamTable
        columns={columns}
        data={discounts}
        noDataMessage={'No discounts for this brand'}
        enableSorting={true}
      />
    </div>
  )
}
