import { CSSProperties, MouseEventHandler } from 'react'

import styles from '../../style/root/beam-switch.module.css'
import { BEAM_COLORS } from './constants'

export const BeamSwitch = ({
  on = false,
  handleClick,
  label,
  styleOverrides,
}: {
  on?: boolean
  handleClick: MouseEventHandler
  label?: string
  styleOverrides?: CSSProperties
}) => {
  return (
    <div className={styles.container} style={styleOverrides}>
      <div
        className={styles['switch-container']}
        onClick={handleClick}
        style={{
          backgroundColor: on ? BEAM_COLORS.orange : BEAM_COLORS.darkGray,
          justifyContent: on ? 'flex-end' : 'flex-start',
        }}>
        <div className={styles.switch} style={{ backgroundColor: BEAM_COLORS.lightGray }} />
      </div>
      <div className={styles.label}>{label}</div>
    </div>
  )
}
