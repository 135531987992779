import { CSSProperties } from 'react'

import styles from '../../style/root/beam-dropdown.module.css'

interface BeamFuzzySearchProps {
  placeholder?: string
  tabIndex?: number
  style?: CSSProperties
  handler: (e: string) => void
  inputValue: string
}

export const BeamFuzzySearch = ({
  placeholder,
  tabIndex,
  style,
  handler,
  inputValue,
}: BeamFuzzySearchProps) => {
  return (
    <div
      tabIndex={tabIndex || 0}
      className={styles['beam-dropdown-container']}
      style={{ cursor: 'text', ...style }}>
      <input
        className={styles['beam-dropdown-placeholder']}
        style={{ cursor: 'text' }}
        value={inputValue}
        placeholder={placeholder}
        onChange={e => handler(e.target.value)}
        onFocus={e => e.preventDefault()}
      />
    </div>
  )
}
