import { useCallback, useEffect, useReducer } from 'react'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router'

import { useBeamSelector } from '../../../hooks'
import { fetchCentralBackendPartner } from '../../../redux/thunks/adminThunks'
import { BeamButton } from '../../../stories/BeamButton'
import { TCBPartner } from '../../../utils/types'
import { BeamSEO } from '../../root/BeamSEO'
import { getAllDiscountsForChain } from './AdminDiscountsPage.api'
import {
  adminDiscountsPageInitialState,
  adminDiscountsPageReducer,
  DiscountsPageModalsEnum,
} from './adminDiscountsPageReducer'
import { CreateDiscountModal } from './CreateDiscountModal'
import { DeletedDiscountsTable } from './DeletedDiscountsTable'
import { DeleteDiscountModal } from './DeleteDiscountModal'
import { DiscountsTable } from './DiscountsTable'

export const AdminDiscountsPage = () => {
  const [state, dispatch] = useReducer(adminDiscountsPageReducer, adminDiscountsPageInitialState)
  const discountsState = state.discountsState

  const chain = useBeamSelector(state => state.cbPartner) as TCBPartner
  const { chainId }: { chainId: string } = useParams()
  const globalDispatch = useDispatch()

  const fetchDiscounts = useCallback(async () => {
    try {
      const chainDiscounts = await getAllDiscountsForChain(+chainId)
      dispatch({ type: 'discounts_loaded', discounts: chainDiscounts })
    } catch (error: any) {
      dispatch({ type: 'error_loading_discounts', errorMessage: error.message, loading: false })
    }
  }, [chainId])

  // fetch chain info
  useEffect(() => {
    globalDispatch(fetchCentralBackendPartner(chainId))
  }, [globalDispatch, chainId])

  // fetch discounts
  useEffect(() => {
    if (discountsState.discounts.length > 0) return

    dispatch({ type: 'loading_discounts' })

    fetchDiscounts()
  }, [discountsState.discounts.length, fetchDiscounts])

  const onRowSelectionChangeHandler = useCallback(newSelectedRows => {
    dispatch({ type: 'row_selected', newSelectedRows })
  }, [])

  async function refreshTableData() {
    dispatch({ type: 'row_selected', newSelectedRows: {} })
    await fetchDiscounts()
  }

  function closeAllModals() {
    dispatch({ type: 'close_modals' })
  }

  const deletedDiscounts = discountsState.discounts.filter(d => d.status === 'deleted')
  const nonDeletedDiscounts = discountsState.discounts.filter(d => d.status !== 'deleted')

  return (
    <>
      <BeamSEO title={'Discounts'} />

      <main className={'pt-14 pr-20'}>
        <CreateDiscountModal
          isOpen={state.activeModal === DiscountsPageModalsEnum.ADD_DISCOUNT}
          closeModal={closeAllModals}
          refreshTableData={refreshTableData}
        />
        <DeleteDiscountModal
          isOpen={state.activeModal === DiscountsPageModalsEnum.DELETE_DISCOUNT}
          selectedRows={state.selectedRows}
          nonDeletedDiscounts={nonDeletedDiscounts}
          closeModal={closeAllModals}
          refreshTableData={refreshTableData}
        />

        <img src={chain?.logoImage} width={200} alt={`${chain.name} Logo`} />
        <h1>{chain.name}</h1>

        <div className="flex flex-col desktop:flex-row desktop:justify-between">
          <div>
            <h2>Discounts</h2>
          </div>
          <div className={'flex flex-row space-x-6 pb-4 desktop:pb-0'}>
            {Object.keys(state.selectedRows).length > 0 && (
              <div className={'my-auto flex-grow min-w-[150px] desktop:w-[248px]'}>
                <BeamButton
                  label={
                    Object.keys(state.selectedRows).length > 1
                      ? 'Delete Discounts'
                      : 'Delete Discount'
                  }
                  variant={'flat_white'}
                  onClick={() =>
                    dispatch({ type: 'open_modal', modal: DiscountsPageModalsEnum.DELETE_DISCOUNT })
                  }
                />
              </div>
            )}

            <div className={'my-auto flex-grow min-w-[150px] desktop:w-[248px]'}>
              <BeamButton
                label="Add Discount"
                onClick={() =>
                  dispatch({
                    type: 'open_modal',
                    modal: DiscountsPageModalsEnum.ADD_DISCOUNT,
                  })
                }
              />
            </div>
          </div>
        </div>

        <DiscountsTable
          nonDeletedDiscounts={nonDeletedDiscounts}
          selectedRows={state.selectedRows}
          onRowSelectionChange={onRowSelectionChangeHandler}
        />
        <h2>Deleted Discounts</h2>
        <DeletedDiscountsTable discounts={deletedDiscounts} />
      </main>
    </>
  )
}
