import { CloseButton } from '../../root/CloseButton'

export type StatusBannerType = 'success' | 'error' | 'warning'

interface StatusBannerProps {
  message: string
  onClose: (type: StatusBannerType, msg: string) => void
  type: StatusBannerType
}
export const StatusBanner = ({ message, onClose, type }: StatusBannerProps) => {
  const bgColor =
    type === 'success'
      ? 'bg-lime-500 text-white'
      : type === 'error'
      ? 'bg-cherry-200 text-white'
      : 'bg-sun-200 text-black'

  return (
    <div className={`flex items-center justify-between w-full rounded-md p-2 ${bgColor}`}>
      <p className="pr-3">{message}</p>
      <CloseButton onClick={() => onClose(type, message)} ariaLabel="Close" />
    </div>
  )
}
