import { cloneElement, Dispatch, SetStateAction, useState } from 'react'

import styles from '../../../style/root/beam-dropdown.module.css'
import BeamButton from '../../root/BeamButton'

/**
 * Searches for API key in local storage to render widgets that
 * require an api to render or prompts user for one. Clones and injects
 * API key prop to top level child element
 */
export const AuthenticatedWidgetWithForm = ({
  children,
}: {
  children: JSX.Element
}): JSX.Element => {
  const [apiKey, setApiKey] = useState('')

  const renderChildWithApiKey = () =>
    cloneElement(children, {
      apiKey,
    })
  return apiKey ? (
    <>
      {renderChildWithApiKey()}
      <BeamButton
        text="Clear Key"
        handler={() => {
          setApiKey('')
        }}
        disabled={!apiKey}
        style={{ marginTop: '40px' }}
      />
    </>
  ) : (
    <AuthenticateWidgetForm apiKey={apiKey} setApiKey={setApiKey} />
  )
}

export const AuthenticateWidgetForm = ({
  setApiKey,
}: {
  apiKey: string
  setApiKey: Dispatch<SetStateAction<string>>
}): any => {
  const [formInput, setFormInput] = useState('')
  return (
    <form
      className="flex flex-col items-center"
      onSubmit={e => {
        e.preventDefault()
        setApiKey(formInput)
      }}>
      <h2>Insert API Key to Continue</h2>
      <input
        className={`${styles['beam-dropdown-placeholder']} cursor-text mt-12 w-full`}
        name="api key input"
        type="text"
        placeholder="Enter API Key"
        onChange={e => setFormInput(e.target.value)}
        value={formInput}
      />
      <BeamButton
        text="Confirm Key"
        handler={() => {
          setApiKey(formInput)
        }}
        disabled={!formInput}
        style={{ marginTop: '40px' }}
      />
    </form>
  )
}
