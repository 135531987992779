import SlSwitchElement from '@shoelace-style/shoelace/dist/components/switch/switch'
import { SlSwitch } from '@shoelace-style/shoelace/dist/react'
import cx from 'classnames'
import React, { forwardRef } from 'react'

import { BeamShoelaceProps } from '../interface'
import $$ from './beam-toggle.module.css'

interface ToggleProps extends BeamShoelaceProps {
  /**
   * Lable that appears next to toggle
   */
  label?: string
  /**
   * name of toggle
   */
  name?: string
  /**
   * Is the toggle disabled
   */
  disabled?: boolean
  /**
   * onChange handler; Triggers whenever the toggle is changed (on click).
   */
  onChange?: (e: Event) => void
  /**
   * Value of toggle
   */
  value?: string
  /**
   * Is toggle on
   */
  checked?: boolean
}

/**
 * Beam Toggle component
 */
export const BeamToggle = forwardRef<SlSwitchElement, ToggleProps>(function BeamToggle(
  { name, value, onChange, label, checked = false, disabled = false, ...props },
  ref
) {
  return (
    <SlSwitch
      {...props}
      ref={ref}
      disabled={disabled}
      name={name}
      value={value}
      checked={checked}
      onSlChange={onChange}
      className={cx(props.className, 'beam--toggle', $$.beamToggle)}>
      {label}
    </SlSwitch>
  )
})
