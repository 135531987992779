import { fetchPresignedDownloadUrl } from '../../admin/AdminPartnerUploads/AdminPartnerUploadsPage.api'

/**
 * Handles triggering the file download dialog for partnerUploads.
 */
export async function handleClickDownloadPartnerUpload({
  chainId,
  partnerUploadId,
  fileName,
}: {
  chainId: number
  partnerUploadId: string
  fileName: string
}) {
  const { presignedDownloadUrl } = await fetchPresignedDownloadUrl(chainId, partnerUploadId)

  const anchorElement = document.createElement('a')
  anchorElement.href = presignedDownloadUrl
  anchorElement.download = fileName
  anchorElement.style.display = 'none'

  document.body.appendChild(anchorElement)

  anchorElement.click()

  document.body.removeChild(anchorElement)
}
