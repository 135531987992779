import { getV2ApiUrl } from '../../../api/auth'
import { axiosRequest } from '../../../utils/axiosRequest'
import { AdminDiscountsBody } from '../../../utils/types'
import {
  DiscountableReportPeriod,
  DiscountCreationRequestPayload,
  DiscountCreationResponse,
} from './AdminDiscountsPage.types'

export async function deleteDiscount(discountIds: string[], deletionReason: string) {
  const res = await axiosRequest('DELETE', `${getV2ApiUrl()}/admin/invoices/discounts/remove`, {
    discountIds,
    deletionReason,
  })
  return res?.data
}

export async function getAllDiscountsForChain(chainId: number): Promise<AdminDiscountsBody[]> {
  const res = await axiosRequest('GET', `${getV2ApiUrl()}/admin/invoices/discounts/${chainId}/all`)
  return res?.data
}

export async function fetchDiscountableReportPeriods(
  chainId: number
): Promise<DiscountableReportPeriod[]> {
  const res = await axiosRequest(
    'GET',
    `${getV2ApiUrl()}/admin/invoices/discounts/${chainId}/discountableReportPeriods`
  )
  return res?.data
}

export async function createDiscount(
  chainId: number,
  data: DiscountCreationRequestPayload
): Promise<DiscountCreationResponse> {
  const res = await axiosRequest(
    'POST',
    `${getV2ApiUrl()}/admin/invoices/discounts/${chainId}/create`,
    data
  )
  return res?.data
}
