import axios from 'axios'
import { applyMiddleware, createStore } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'
// import { createLogger } from 'redux-logger'
import thunkMiddleware from 'redux-thunk'

import { rootReducer } from './redux/rootReducer'

const middleware = [thunkMiddleware.withExtraArgument({ axios })]

// eslint-disable-next-line no-undef
// if (process.env.NODE_ENV === 'development') middleware.push(createLogger({ collapsed: true }))

const store = createStore(rootReducer, composeWithDevTools(applyMiddleware(...middleware)))

export default store
