export const ADD_NONPROFIT = 'ADD_NONPROFIT'
export const SET_ALL_PARTNERS = 'SET_ALL_PARTNERS'
export const SET_API_KEYS = 'SET_API_KEYS'
export const SET_CAUSES = 'SET_CAUSES'
export const SET_CB_PARTNER = 'SET_CB_PARTNER'
export const SET_EXTERNAL_PARTNERS = 'SET_EXTERNAL_PARTNERS'
export const SET_IMPACT = 'SET_IMPACT'
export const SET_INVOICES = 'SET_INVOICES'
export const SET_LOADING_ALL_PARTNERS = 'SET_LOADING_ALL_PARTNERS'
export const SET_LOADING_API_KEYS = 'SET_LOADING_API_KEYS'
export const SET_LOADING_CAUSES = 'SET_LOADING_CAUSES'
export const SET_LOADING_CB_PARTNER = 'SET_LOADING_CB_PARTNER'
export const SET_LOADING_IMPACT = 'SET_LOADING_IMPACT'
export const SET_LOADING_INVOICES = 'SET_LOADING_INVOICES'
export const SET_LOADING_NONPROFIT = 'SET_LOADING_NONPROFIT'
export const SET_LOADING_NONPROFITS = 'SET_LOADING_NONPROFITS'
export const SET_LOADING_PARTNER = 'SET_LOADING_PARTNER'
export const SET_LOADING_PARTNERS = 'SET_LOADING_PARTNERS'
export const SET_LOADING_PAYMENT_STRUCTURES = 'SET_LOADING_PAYMENT_STRUCTURES'
export const SET_LOADING_REPORT = 'SET_LOADING_REPORT'
export const SET_LOADING_SUPPORT_EMAIL = 'SET_LOADING_SUPPORT_EMAIL'
export const SET_LOADING_SITES = 'SET_LOADING_SITES'
export const SET_LOADING_USER = 'SET_LOADING_USER'
export const SET_NEW_API_KEY = 'SET_NEW_API_KEY'
export const SET_NONPROFIT = 'SET_NONPROFIT'
export const SET_NONPROFITS = 'SET_NONPROFITS'
export const SET_PARTNER = 'SET_PARTNER'
export const SET_PARTNERS = 'SET_PARTNERS'
export const SET_PAYMENT_STRUCTURES = 'SET_PAYMENT_STRUCTURES'
export const SET_REPORT = 'SET_REPORT'
export const SET_REPORT_V2 = 'SET_REPORT_V2'
export const SET_TOAST = 'SET_TOAST'
export const SET_SITE = 'SET_SITE'
export const SET_SITES = 'SET_SITES'
export const SET_DISPLAY_LIST_OF_SITES = 'SET_DISPLAY_LIST_OF_SITES'
export const SET_USER = 'SET_USER'
export const SET_ACCESS_TOKEN = 'SET_ACCESS_TOKEN'
