import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router'

import { fetchNonprofit } from '../../redux/thunks/adminThunks'
import styles from '../../style/admin/nonprofit.module.css'
import { TCauseNonprofit, TNonprofit as NonprofitType } from '../../utils/types'
import { APIError } from '../root/APIError'
import BeamButton from '../root/BeamButton'
import { LoginPage } from '../root/LoginPage'
import { PageNotFound } from '../root/PageNotFound'
import { NonprofitEditModal } from './NonprofitEditModal'

const Nonprofit = () => {
  const dispatch = useDispatch()

  const loadingStates = useSelector((state: any) => state.loadingStates)
  const nonprofit: NonprofitType = useSelector((state: any) => state.nonprofit)
  const user = useSelector((state: any) => state.user)

  const [showEditNonprofitModal, setShowEditNonprofitModal] = useState<boolean>(false)
  const toggleModal = () => {
    setShowEditNonprofitModal(false)
  }

  const { id }: any = useParams()
  useEffect(() => {
    if (!nonprofit || loadingStates.nonprofit?.loading || loadingStates.nonprofit?.error) return

    dispatch(fetchNonprofit(id))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (loadingStates.user && loadingStates.user.loading) return <div>One moment please...</div>
  if (loadingStates.user && loadingStates.user.error)
    return <APIError error={loadingStates.user.error} />
  if (!user.type) return <LoginPage />
  if (['Admin', 'Super'].indexOf(user.type) === -1) return <PageNotFound />
  if (!nonprofit.name)
    return (
      <div>
        <div>Getting nonprofit data...</div>
      </div>
    )
  if (loadingStates.nonprofit && loadingStates.nonprofit.loading && !showEditNonprofitModal)
    return (
      <div>
        <h2>{nonprofit && nonprofit.name}</h2>
        <div>Getting nonprofit data...</div>
      </div>
    )
  if (loadingStates.nonprofit && loadingStates.nonprofit.error && !showEditNonprofitModal)
    return (
      <div>
        <h2>{nonprofit && nonprofit.name}</h2>
        <APIError error={loadingStates.nonprofit.error} withRedirect={true} />
      </div>
    )

  if (!nonprofit) {
    return (
      <div>
        <h2>Nonprofit {id} not found</h2>
      </div>
    )
  }

  const causes: string[] = nonprofit.causeNonprofits
    ? nonprofit.causeNonprofits.reduce((result: string[], causeNonprofit: TCauseNonprofit) => {
        if (causeNonprofit.cause) result.push(causeNonprofit.cause.name)
        return result
      }, [])
    : []

  return (
    <div className={styles['nonprofit-conatiner']}>
      <h2>{nonprofit.name}</h2>
      <h4>
        Launch Date:{' '}
        {nonprofit.createdAt
          ? new Date(nonprofit.createdAt).toLocaleDateString()
          : 'Launch Date Not Found'}
      </h4>
      <BeamButton
        text="EDIT NONPROFIT INFO"
        style={{ width: '180px', height: '28px', marginBottom: '20px' }}
        handler={() => setShowEditNonprofitModal(true)}
      />
      <h3>Nonprofit Information:</h3>
      <p>
        Last Updated:{' '}
        {nonprofit.updatedAt
          ? new Date(nonprofit.updatedAt).toLocaleDateString()
          : 'Last Updated Date Not Found'}
      </p>
      <div className={styles['nonprofit-conatiner']}>
        <b>Personal Photo:</b>
        {nonprofit.personalPhoto ? (
          <img src={nonprofit.personalPhoto} id={styles['nonprofit-image']} />
        ) : (
          'No Personal Photo'
        )}
      </div>
      <div className={styles['nonprofit-conatiner']}>
        <b>Community Photo:</b>
        {nonprofit.communityPhoto ? (
          <img src={nonprofit.communityPhoto} id={styles['nonprofit-image']} />
        ) : (
          'No Community Photo'
        )}
      </div>
      <b>Related Causes: </b>
      {causes.length !== 0 ? causes.join(', ') : 'None'}
      <b>Description: </b>
      {nonprofit.description ? nonprofit.description : 'No Description'}
      <b>How to Help Beyond Beam: </b>
      {nonprofit.howToHelpBeyondBeam || 'No Description'}
      <b>Website</b>
      {nonprofit.website ? (
        <a target="_blank" rel="noreferrer" href={nonprofit.website}>
          {nonprofit.website}
        </a>
      ) : (
        'No Website Provided'
      )}
      <b>Donation Link: </b>
      {nonprofit.donationLink ? nonprofit.donationLink : 'No Donation Link'}
      <b>Action Link: </b>
      {nonprofit.actionLink ? nonprofit.actionLink : 'No Action Link'}
      <b>PayPal Giving Fund Link: </b>
      {nonprofit.ppgfLink ? nonprofit.ppgfLink : 'No PayPal Link'}
      <b>PayPal Giving Fund Id: </b>
      {nonprofit.ppgfId ? nonprofit.ppgfId : 'No PayPal Id'}
      <b>Point of Contact:</b>
      <li>
        <b>Name: </b>
        {nonprofit.pointOfContactName ? nonprofit.pointOfContactName : 'No Name Provided'}
      </li>
      <li>
        <b>Title: </b>
        {nonprofit.pointOfContactTitle ? nonprofit.pointOfContactTitle : 'No Title Provided'}
      </li>
      <li>
        <b>Email: </b>
        {nonprofit.pointOfContactEmail ? nonprofit.pointOfContactEmail : 'No Email Provided'}
      </li>
      <li>
        <b>Phone Number: </b>
        {nonprofit.pointOfContactPhone ? nonprofit.pointOfContactPhone : 'No Phone Number Provided'}
      </li>
      <NonprofitEditModal
        isOpen={showEditNonprofitModal}
        nonprofit={nonprofit}
        onClose={toggleModal}
      />
      <b>Scope Of Work: </b>
      {nonprofit.regions && nonprofit.regions.length > 0 ? nonprofit.regions.join(', ') : 'None'}
    </div>
  )
}

export default Nonprofit
