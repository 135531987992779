import styles from '../../style/root/close-button.module.css'

interface CloseButtonProps {
  ariaLabel?: string
  dark?: boolean
  onClick: () => void
}

export const CloseButton = ({ ariaLabel, dark, onClick }: CloseButtonProps) => {
  const color = dark ? 'before:bg-black after:bg-black' : `before:bg-white after:bg-white`
  return (
    <button
      aria-label={ariaLabel}
      className={`${styles['CloseButton']} ${color}`}
      onClick={onClick}
    />
  )
}
