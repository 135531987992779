import { useEffect } from 'react'
import { useDispatch } from 'react-redux'

import { useBeamSelector } from '../../hooks'
import { fetchPartnerByChainId } from '../../redux/thunks/adminThunks'
import { APIError } from '../root/APIError'

export const PartnerHome = (): JSX.Element | null => {
  // FIXME: Remove all `any` and use correct types
  const user = useBeamSelector(({ user }) => user) as Record<any, any> | undefined
  const loadingStates = useBeamSelector(({ loadingStates }) => loadingStates) as any
  const partner = useBeamSelector(({ partner }) => partner)
  const dispatch = useDispatch()

  const chainId: number | undefined = partner?.chainId

  // Fetch Partner
  useEffect(() => {
    if (!user?.partnerId || loadingStates.user?.loading || !(Object.keys(partner).length === 0)) {
      return
    }

    dispatch(fetchPartnerByChainId(chainId))
  }, [chainId, dispatch, loadingStates.user?.loading, partner, user?.partnerId])

  if (loadingStates.user?.loading)
    return <div className="loading-message">One moment please...</div>
  if (loadingStates.user?.error)
    return <APIError error={loadingStates.user.error} withRedirect={true} />

  if (!user) return null

  return (
    <div>
      <h1>Page no longer in service</h1>
    </div>
  )
}
