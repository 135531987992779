import { API_BASE_PATH_PORTAL } from '../../../api/auth'
import { axiosRequest } from '../../../utils/axiosRequest'
import { convertObjectToCss } from '../../../utils/convertObjectToCss'
import { getWidgetScriptUrl } from '../../../utils/getWidgetScriptUrl'
import { TRemoteConfig } from '../../../utils/types'
import { WidgetName } from '../../../utils/types/WidgetNames'

export const widgetStyleElementId = 'beam-widget-customizations'

export const loadWidgetScript = (widget: WidgetName, onLoadCallback?: () => void) => {
  const scriptId = `beam-${widget}-script`
  const existingScript = document.querySelector(`#${scriptId}`)
  const existingStyleEl = document.querySelector(`style#${widgetStyleElementId}`)

  if (existingScript) {
    existingScript.remove()
  }

  const scriptEl = document.createElement('script')
  scriptEl.src = getWidgetScriptUrl(widget)
  scriptEl.type = 'module'
  scriptEl.async = true
  scriptEl.crossOrigin = 'true'
  scriptEl.id = scriptId
  if (onLoadCallback) {
    scriptEl.onload = () => onLoadCallback()
  }
  document.head.appendChild(scriptEl)

  existingStyleEl?.remove()
  const styleEl = document.createElement('style')
  styleEl.id = widgetStyleElementId
  document.head.appendChild(styleEl)
}

export const fetchWidgetData = async (
  widgetName: WidgetName,
  chainId: string | number,
  widgetVersion: string
) => {
  const remoteConfigBasePath = `${API_BASE_PATH_PORTAL}/v2/remote-config`

  try {
    const { data } = await axiosRequest('GET', remoteConfigBasePath, null, {
      widgetName,
      chainId: +chainId,
      widgetVersion,
    })

    return data
  } catch (error) {
    console.error(error)
    return null
  }
}

export function updateStyleElContent(newThemeData: { [key: string]: string }) {
  const styleEl = document.querySelector(`style#${widgetStyleElementId}`)
  if (styleEl) {
    styleEl.textContent = `.custom { ${convertObjectToCss(newThemeData)} }`
  }
}

export const saveConfig = async (
  type: 'draft' | 'live',
  body: {
    chainId: string | number
    config?: TRemoteConfig['config']['draft']['web']
    widgetId?: string | number
    widgetName: WidgetName
    widgetVersion: string
  }
): Promise<{ data: TRemoteConfig | null; error?: any }> => {
  if (type === 'draft' && !body.config) {
    console.error('No config body provided.')
    return { data: null, error: null }
  }

  const method = type === 'live' ? 'POST' : 'PUT'
  try {
    const res = await axiosRequest(method, `${API_BASE_PATH_PORTAL}/v2/remote-config`, body)
    return { data: res.data, error: null }
  } catch (error: any) {
    console.error(error)
    return { data: null, error: error?.response?.data ? error.response.data : error }
  }
}
