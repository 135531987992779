function asNumber(value: string | number | null | undefined): number | undefined {
  const isEmptyString = typeof value === 'string' && !value
  const isInvalidString = typeof value === 'string' && isNaN(+value)

  if (value === null || value === undefined || isEmptyString || isInvalidString) return undefined

  return +value
}

export default asNumber
