import { useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router'

import { BeamButton } from '../../../stories/BeamButton'
import { BeamDropdown } from '../../../stories/BeamDropdown'
import { BeamLoadingIndicator } from '../../../stories/BeamLoadingIndicator'
import { BeamTextfield } from '../../../stories/BeamTextfield'
import { BeamToast } from '../../../stories/BeamToast'
import { handleAxiosError } from '../../../utils/helpers/handleAxiosError'
import {
  fetchEmailTriggers,
  sendSampleKlaviyoTriggers,
  TChain,
  TKlaviyoTrigger,
  updateEspInfo,
} from './KlaviyoPage.helpers'
import { KlaviyoTriggersGrid } from './KlaviyoTriggersGrid'
import { TriggerEditModal } from './TriggerEditModal'

export const KlaviyoPage = () => {
  // When we support other email service providers, these options will probably come from our db
  const espOptions = [{ label: 'Klaviyo', value: 'KL' }]

  const { id: chainId } = useParams<{ id: string }>()
  const [originalEspInfo, setOriginalEspInfo] = useState({
    apiKey: '',
    emailServiceProvider: '',
  })
  const [espInfo, setEspInfo] = useState({
    apiKey: '',
    emailServiceProvider: '',
  })
  const [chain, setChain] = useState<TChain | null>(null)
  const [klaviyoTriggers, setKlaviyoTriggers] = useState<TKlaviyoTrigger[]>([]) // array of all triggers (both progress & restart types)
  const [modalOpen, setModalOpen] = useState<boolean>(false)
  const [selectedTrigger, setSelectedTrigger] = useState<TKlaviyoTrigger | null>(null)
  const [loadingTriggers, setLoadingTriggers] = useState(false)
  const [loadingEspInfo, setLoadingEspInfo] = useState(false)
  const [loadingSampleTriggers, setLoadingSampleTriggers] = useState(false)

  const [successToastMessage, setSuccessToastMessage] = useState('')
  const [errorToastMessage, setErrorToastMessage] = useState('')

  // Fetch all triggers on initial load
  useEffect(() => {
    handleFetchTriggers()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const espInfoChanged = useMemo(() => {
    return (
      espInfo.apiKey !== originalEspInfo.apiKey ||
      espInfo.emailServiceProvider !== originalEspInfo.emailServiceProvider
    )
  }, [
    espInfo.apiKey,
    espInfo.emailServiceProvider,
    originalEspInfo.apiKey,
    originalEspInfo.emailServiceProvider,
  ])

  function resetToasts() {
    setSuccessToastMessage('')
    setErrorToastMessage('')
  }

  function handleError(error: any) {
    handleAxiosError(error)
    resetToasts()
    setErrorToastMessage(error.response?.data?.message || error.message)
  }

  async function handleFetchTriggers() {
    setLoadingTriggers(true)

    try {
      const { chain, triggers } = await fetchEmailTriggers(chainId)
      setChain(chain)
      setOriginalEspInfo({
        apiKey: chain.espEpiKey || '',
        emailServiceProvider: chain.emailServiceProvider || '',
      })
      setEspInfo({
        apiKey: chain.espEpiKey || '',
        emailServiceProvider: chain.emailServiceProvider || '',
      })
      setKlaviyoTriggers(triggers)
      setLoadingTriggers(false)
    } catch (error: any) {
      handleError(error)
      setLoadingTriggers(false)
    }
  }

  function onCreateSuccess(newTrigger: TKlaviyoTrigger) {
    setKlaviyoTriggers([...klaviyoTriggers, newTrigger])
    setModalOpen(false)
    resetToasts()
    setSuccessToastMessage('Trigger successfully created!')
  }

  // Replace trigger in array with its updated version
  function onUpdateSuccess(updatedTrigger: TKlaviyoTrigger) {
    const newArray = [...klaviyoTriggers]
    const itemIndex = newArray.findIndex(trigger => trigger.id === updatedTrigger.id)
    newArray.splice(itemIndex, 1, updatedTrigger)

    setKlaviyoTriggers(newArray)
    setModalOpen(false)
    resetToasts()
    setSuccessToastMessage('Trigger successfully updated!')
    setSelectedTrigger(null)
  }

  async function handleEspInfoSave() {
    setLoadingEspInfo(true)
    try {
      const { ok } = await updateEspInfo(espInfo, chainId)

      if (ok) {
        setOriginalEspInfo(espInfo)
        setLoadingEspInfo(false)
      }
    } catch (error) {
      handleError(error)
      setLoadingEspInfo(false)
    }
  }

  async function handleSendSampleTriggers() {
    setLoadingSampleTriggers(true)

    try {
      const res = await sendSampleKlaviyoTriggers(chainId)

      if (res.ok) {
        resetToasts()
        setSuccessToastMessage('Sample triggers successfully sent!')
        setLoadingSampleTriggers(false)
      }
    } catch (error) {
      handleError(error)
      setLoadingSampleTriggers(false)
    }
  }

  const defaultEspOptionIndex = espOptions.findIndex(
    opt => opt.value === espInfo.emailServiceProvider
  )

  return (
    <div>
      <h1 className="text-center desktop:mt-10">
        <span className="underline text-charcoal-500 decoration-4">{chain?.name}</span> Klaviyo
        Config
      </h1>
      <div className="relative flex flex-col space-y-2 w-max">
        {loadingEspInfo && (
          <div className="absolute inset-0 z-10 backdrop-blur-[1px] flex items-center justify-center">
            <BeamLoadingIndicator className="p-0 " />
          </div>
        )}

        <BeamTextfield
          disabled={loadingEspInfo}
          variant="small"
          className="w-28"
          name="apiKey"
          label="Public API Key"
          value={espInfo.apiKey}
          onChange={e => setEspInfo({ ...espInfo, apiKey: e.target?.value })}
        />

        <div className="w-max">
          <BeamDropdown
            options={espOptions}
            label="Email service provider"
            defaultValue={defaultEspOptionIndex === -1 ? undefined : defaultEspOptionIndex}
            onChange={e => {
              setEspInfo({ ...espInfo, emailServiceProvider: e.target.value })
            }}
          />
        </div>

        <BeamButton disabled={!espInfoChanged} label="Save" onClick={handleEspInfoSave} />
      </div>

      <section>
        <div className="flex items-center justify-between">
          <h2>Klaviyo Triggers</h2>
          <BeamButton
            variant="basic"
            label={loadingSampleTriggers ? 'Sending...' : 'Send Sample Triggers 🚀'}
            onClick={handleSendSampleTriggers}
            disabled={loadingSampleTriggers}
          />
        </div>
        <div className="h-[1px] w-full bg-charcoal-100 mb-8" />

        {loadingTriggers && (
          <div className="flex items-center justify-center p-12">
            <BeamLoadingIndicator className="p-0 backdrop-blur-[1px]" />
          </div>
        )}

        {!loadingTriggers && (
          <KlaviyoTriggersGrid
            klaviyoTriggers={klaviyoTriggers}
            setModalOpen={setModalOpen}
            setSelectedTrigger={setSelectedTrigger}
          />
        )}
      </section>

      <TriggerEditModal
        chainId={chainId}
        close={() => {
          setModalOpen(false)
          setSelectedTrigger(null)
        }}
        onSuccess={selectedTrigger ? onUpdateSuccess : onCreateSuccess}
        onError={(error: any) => {
          handleError(error)
        }}
        open={!!modalOpen}
        oldTriggerData={selectedTrigger}
      />

      <BeamToast
        variant="success"
        text={successToastMessage}
        open={!!successToastMessage}
        onClose={() => setSuccessToastMessage('')}
        closable
      />
      <BeamToast
        variant="error"
        text={errorToastMessage}
        open={!!errorToastMessage}
        onClose={() => setErrorToastMessage('')}
        closable
      />
    </div>
  )
}
