import { useEffect } from 'react'
import { useDispatch } from 'react-redux'

import { useBeamSelector } from '../../hooks'
import { fetchUser, updateUser } from '../../redux/thunks/authThunks'
import { TUser } from '../../utils/types'
import { LoginPage } from '../root/LoginPage'
import { APIError } from './APIError'
import { BeamSwitch } from './BeamSwitch'

export const AccountPage = () => {
  const user = useBeamSelector(({ user }) => user) as TUser
  const loadingStates = useBeamSelector(({ loadingStates }) => loadingStates) as any
  const dispatch = useDispatch()

  useEffect(() => {
    if (user?.type) return

    dispatch(fetchUser())
  }, [user, dispatch])

  if (loadingStates?.user?.loading) return <div>One moment please...</div>
  if (loadingStates?.user?.error) return <APIError error={loadingStates.user.error} />
  if (!user?.type) return <LoginPage />

  const handleToggle = () => {
    if (loadingStates?.user?.loading) return
    dispatch(updateUser({ useTwoFactor: !user.useTwoFactor }))
  }

  return (
    <div>
      <h1>Account Page</h1>
      <div>
        <div>
          <b>Name: </b>
          <span>
            {user.firstName} {user.lastName}
          </span>
        </div>
        <div>
          <b>Email: </b>
          <span>
            {user.email && user.email[0]}****{user.email && user.email.replace(/[^@]*/, '')}
          </span>
        </div>
        <br />
        <BeamSwitch
          label="Two Factor Authentication"
          on={user.useTwoFactor || false}
          handleClick={handleToggle}
        />
      </div>
    </div>
  )
}
