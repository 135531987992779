import IconButton from '@material-ui/core/IconButton'
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward'
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp'
import PropTypes from 'prop-types'
import React from 'react'
import { Component } from 'react'
import { CSVLink } from 'react-csv'

import buttonStyles from '../../style/root/beam-button.module.css'
import styles from '../../style/root/beam-table.module.css'
import BeamButton from './BeamButton'

class BeamTable extends Component {
  constructor(props) {
    super(props)
    this.state = {
      hoveringColumn: null,
      sortColumn: props.defaultSortColumn,
      sortDataType: props.defaultSortDataType,
      sortAscending: props.defaultSortAscending,
      // eslint-disable-next-line no-undef
      expandedRows: new Set(),
      rowsPerPage: 5,
      pages: 0,
      minRowsPerPage: 5,
    }
    this.showSortArrow = this.showSortArrow.bind(this)
    this.hideSortArrow = this.hideSortArrow.bind(this)
    this.showDownArrow = this.showDownArrow.bind(this)
    this.shwowUpArrow = this.showUpArrow.bind(this)
    this.sortFunction = this.sortFunction.bind(this)
    this.sortedRows = this.sortedRows.bind(this)
    this.selectSortColumn = this.selectSortColumn.bind(this)
    this.headerCellDisplay = this.headerCellDisplay.bind(this)
    this.cellValue = this.cellValue.bind(this)
    this.setOpen = this.setOpen.bind(this)
    this.showMoreRows = this.showMoreRows.bind(this)
    this.showLessRows = this.showLessRows.bind(this)
    this.showPreviousPage = this.showPreviousPage.bind(this)
    this.showNextPage = this.showNextPage.bind(this)
  }
  showSortArrow(column) {
    this.setState({ hoveringColumn: column })
  }
  hideSortArrow() {
    this.setState({ hoveringColumn: null })
  }
  showDownArrow(sortColumn, column, ascending, hoveringColumn) {
    return (
      ((sortColumn === column && !ascending) ||
        (sortColumn !== column && hoveringColumn === column)) &&
      column !== 'image'
    )
  }
  showUpArrow(sortColumn, column, ascending) {
    return sortColumn === column && ascending && column !== 'image'
  }
  setOpen(row) {
    const expandedRows = this.state.expandedRows
    if (expandedRows.has(row)) {
      expandedRows.delete(row)
    } else {
      expandedRows.add(row)
    }
    this.setState({ expandedRows })
  }
  sortFunction(sortColumn, sortAscending, sortDataType) {
    if (sortDataType === 'number') {
      return function (a, b) {
        const n1 = a[sortColumn]
          ? a[sortColumn].sortOn !== undefined
            ? a[sortColumn].sortOn
            : a[sortColumn]
          : 0
        const n2 = b[sortColumn]
          ? b[sortColumn].sortOn !== undefined
            ? b[sortColumn].sortOn
            : b[sortColumn]
          : 0
        return sortAscending ? n1 - n2 : n2 - n1
      }
    } else if (sortDataType === 'date') {
      return function (a, b) {
        const date1 = new Date(a[sortColumn] ? a[sortColumn].sortOn || a[sortColumn] : 0)
        const date2 = new Date(b[sortColumn] ? b[sortColumn].sortOn || b[sortColumn] : 0)
        return sortAscending ? date1 - date2 : date2 - date1
      }
    } else if (sortDataType === 'string') {
      return function (a, b) {
        const aColumnIsObject =
          a[sortColumn] && Object.prototype.hasOwnProperty.call(a[sortColumn], 'sortOn')

        const bColumnIsObject =
          b[sortColumn] && Object.prototype.hasOwnProperty.call(b[sortColumn], 'sortOn')

        const str1 = ((aColumnIsObject ? a[sortColumn].sortOn : a[sortColumn]) || '').toLowerCase()
        const str2 = ((bColumnIsObject ? b[sortColumn].sortOn : b[sortColumn]) || '').toLowerCase()

        if (str1 !== '' && str2 === '') return sortAscending ? -1 : 1
        if (str1 === '' && str2 !== '') return sortAscending ? 1 : -1
        if (str1 < str2) return sortAscending ? -1 : 1
        if (str1 > str2) return sortAscending ? 1 : -1
        return 0
      }
    } else {
      return function () {
        return 0
      }
    }
  }
  cellValue(row, field, isImage) {
    if (!row[field]) return null

    if (isImage && row[field].value !== undefined) {
      return <img src={row[field].value} style={{ maxHeight: '200px' }} />
    }

    if (row[field].value !== undefined || row[field].sortOn !== undefined) {
      return row[field].value
    }

    return isImage ? <img src={row[field]} style={{ maxHeight: '200px' }} /> : row[field]
  }
  sortedRows() {
    const {
      sortFunction,
      cellValue,
      setOpen,
      props: {
        headers,
        rows,
        emptyTableMessage,
        loading,
        loadingMessage,
        expandable,
        simplePagination,
        pagination,
        rowStyle,
      },
      state: { sortColumn, sortAscending, sortDataType, expandedRows, rowsPerPage, pages },
    } = this
    if (loading)
      return (
        <div className={`${styles.row} ${styles['empty-table']}`}>
          {loadingMessage || 'Loading data...'}
        </div>
      )
    if (!rows || !rows.length)
      return (
        <div className={`${styles.row} ${styles['empty-table']}`}>
          {emptyTableMessage || 'No data to display'}
        </div>
      )
    const sliceStart =
      rows.length <= (pages + 1) * rowsPerPage || (!pagination && !simplePagination)
        ? 0
        : pages * rowsPerPage
    const sliceEnd =
      rows.length <= (pages + 1) * rowsPerPage ? rowsPerPage : pages * rowsPerPage + rowsPerPage
    return rows
      .sort(sortFunction(sortColumn, sortAscending, sortDataType))
      .slice(sliceStart, !simplePagination && !pagination ? rows.length : sliceEnd)
      .map((row, r) =>
        expandable ? (
          <div key={`tr-${r}`}>
            <div className={styles.row} style={rowStyle || {}}>
              <div className={styles['expander-cell']}>
                <IconButton aria-label="expand row" size="small" onClick={() => setOpen(row.id)}>
                  {expandedRows.has(row.id) ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                </IconButton>
              </div>
              {headers.map(({ field, width }, c) => (
                <div
                  key={`tc-${r}-${c}`}
                  className={`${styles.cell}${c === 0 ? ` ${styles['frozen-column']}` : ''}`}
                  style={{ width, overflow: 'hidden' }}>
                  {cellValue(row, field)}
                </div>
              ))}
            </div>
            <div
              className={styles['expandable-row']}
              style={{ height: expandedRows.has(row.id) ? 'auto' : '0px' }}>
              {row.expandedRow}
            </div>
          </div>
        ) : (
          <div key={`tr-${r}`} className={styles.row} style={rowStyle || {}}>
            {headers.map(({ field, dataType, width }, c) => {
              const isImage = dataType === 'image'

              return (
                <div
                  key={`tc-${r}-${c}`}
                  className={`${styles.cell}${c === 0 ? ` ${styles['frozen-column']}` : ''}`}
                  style={{ width, overflow: 'hidden' }}>
                  {cellValue(row, field, isImage)}
                </div>
              )
            })}
          </div>
        )
      )
  }
  selectSortColumn(sortColumn, sortDataType) {
    if (sortColumn === 'image') return
    if (this.state.sortColumn === sortColumn) {
      this.setState({ sortAscending: !this.state.sortAscending })
    } else {
      this.setState({ sortColumn, sortDataType, sortAscending: false })
    }
  }
  headerCellDisplay(column, sortColum, hoveringColumn) {
    if (column === sortColum || column === hoveringColumn) return 'flex'
    return ''
  }
  showLessRows() {
    if (this.state.rowsPerPage === this.state.minRowsPerPage) return
    this.setState({ rowsPerPage: this.state.rowsPerPage - this.state.minRowsPerPage })
  }
  showMoreRows() {
    this.setState({ rowsPerPage: this.state.rowsPerPage + this.state.minRowsPerPage })
  }
  showPreviousPage() {
    this.setState({ pages: this.state.pages - 1 })
  }
  showNextPage() {
    this.setState({ pages: this.state.pages + 1 })
  }
  render() {
    const {
      showSortArrow,
      hideSortArrow,
      showDownArrow,
      showUpArrow,
      sortedRows,
      selectSortColumn,
      headerCellDisplay,
      showLessRows,
      showMoreRows,
      showPreviousPage,
      showNextPage,
      state: { hoveringColumn, sortColumn, sortAscending, rowsPerPage, minRowsPerPage, pages },
      props: {
        headers,
        title,
        tableContainerStyle,
        topLevelContainerStyle,
        id,
        expandable,
        exportable,
        exportData,
        exportFileName,
        pagination,
        rows,
        simplePagination,
      },
    } = this
    if (!headers) return null
    const remainingRows = rows ? rows.length - rowsPerPage * (pages + 1) : 0
    return (
      <div
        id={id || ''}
        className={styles['top-level-container']}
        style={{ ...topLevelContainerStyle }}>
        <div className={styles['title-container']}>
          {title && <h2 className={styles['table-title']}>{title}</h2>}
          {exportable && (
            <CSVLink
              className={buttonStyles.button}
              style={{
                textDecoration: 'none',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: '100px',
                height: '35px',
                marginRight: '15px',
              }}
              data={exportData.rows}
              headers={exportData.headers}
              filename={exportFileName}
              target="_blank">
              Export
            </CSVLink>
          )}
        </div>
        <div
          className={
            pagination || simplePagination
              ? styles['pagination-scroll-container']
              : styles['scroll-container']
          }>
          <div className={styles['table-container']} style={tableContainerStyle}>
            <div className={styles['headers-container']}>
              {headers && expandable && <div className={styles['expander-cell']} />}
              {!headers
                ? JSON.stringify(headers)
                : headers.map((column, i) => (
                    <div
                      onMouseOver={() => showSortArrow(column.field)}
                      onMouseLeave={hideSortArrow}
                      onClick={() => selectSortColumn(column.field, column.dataType)}
                      key={`col-${i}`}
                      style={{
                        width: column.width,
                        display: headerCellDisplay(column.field, sortColumn, hoveringColumn),
                      }}
                      className={`${styles.header}${i === 0 ? ` ${styles['frozen-column']}` : ''}`}>
                      <div>{column.headerName}</div>
                      {showDownArrow(sortColumn, column.field, sortAscending, hoveringColumn) && (
                        <ArrowDownwardIcon style={{ height: '15px', width: '15px' }} />
                      )}
                      {showUpArrow(sortColumn, column.field, sortAscending) && (
                        <ArrowUpwardIcon style={{ height: '15px', width: '15px' }} />
                      )}
                    </div>
                  ))}
            </div>
            {sortedRows()}
          </div>
        </div>
        {pagination && rows && (
          <div className={styles['pagination-container']}>
            <div className={styles['pagination-stats-container']}>
              <div>
                Showing {rows.length > rowsPerPage ? rowsPerPage : rows.length} of{' '}
                {rows ? rows.length : 0} rows
              </div>
              <div>
                Showing Page {rows.length > rowsPerPage ? pages + 1 : 1} of{' '}
                {Math.ceil(rows.length / rowsPerPage)}
              </div>
            </div>
            <div className={styles['pagination-stats-container']}>
              <div className={styles['pagination-buttons-container']}>
                {rowsPerPage > minRowsPerPage && (
                  <BeamButton
                    handler={showLessRows}
                    text="LESS ROWS"
                    style={{ width: '150px', height: '30px', marginRight: '15px' }}
                  />
                )}
                {(pages + 1) * rowsPerPage < rows.length &&
                  (pages + 1) * rowsPerPage + Math.min(minRowsPerPage, remainingRows) <=
                    rows.length && (
                    <BeamButton
                      handler={showMoreRows}
                      text="MORE ROWS"
                      style={{ width: '150px', height: '30px' }}
                    />
                  )}
              </div>

              <div className={styles['pagination-buttons-container']}>
                {pages > 0 && rows.length > rowsPerPage && (
                  <BeamButton
                    handler={showPreviousPage}
                    text="PREV PAGE"
                    style={{ width: '150px', height: '30px', marginRight: '15px' }}
                  />
                )}
                {(pages + 1) * rowsPerPage < rows.length && (
                  <BeamButton
                    handler={showNextPage}
                    text="NEXT PAGE"
                    style={{ width: '150px', height: '30px' }}
                  />
                )}
              </div>
            </div>
          </div>
        )}
        {simplePagination && rows && rowsPerPage < rows.length && (
          <div className={styles['simple-pagination-container']}>
            <BeamButton text="LOAD MORE" handler={showMoreRows} />
          </div>
        )}
      </div>
    )
  }
}

BeamTable.propTypes = {
  headers: PropTypes.array,
  rows: PropTypes.array,
  defaultSortColumn: PropTypes.string,
  defaultSortAscending: PropTypes.bool,
  title: PropTypes.string,
  tableContainerStyle: PropTypes.object,
  defaultSortDataType: PropTypes.string,
  topLevelContainerStyle: PropTypes.object,
  numRows: PropTypes.number,
  emptyTableMessage: PropTypes.string,
  loading: PropTypes.bool,
  loadingMessage: PropTypes.string,
  id: PropTypes.string,
  expandable: PropTypes.bool,
  expanderWidth: PropTypes.string,
  exportable: PropTypes.bool,
  exportData: PropTypes.object,
  exportFileName: PropTypes.string,
  pagination: PropTypes.bool,
  simplePagination: PropTypes.bool,
  rowStyle: PropTypes.object,
}

export default BeamTable
