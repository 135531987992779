import { replace } from 'lodash'

import { PAGE_TITLE_SUFFIX } from '../helpers'

export function getCurrentPageTitle() {
  const title = document?.title
  if (!title) {
    return ''
  }

  return replace(title, PAGE_TITLE_SUFFIX, '').trim()
}
