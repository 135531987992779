let baseUrl = ''

// TODO: Use env variables once we flesh out our deployment flow.
if (window.location.href.includes('localhost')) {
  baseUrl = 'http://localhost:4000/api'
} else if (window.location.href.includes('dev')) {
  baseUrl = 'https://development-partner-portal-api.beamimpact.com/api'
} else if (window.location.href.includes('vercel')) {
  baseUrl = 'https://development-partner-portal-api.beamimpact.com/api'
} else if (window.location.href.includes('staging')) {
  baseUrl = 'https://staging-partner-portal-api.beamimpact.com/api'
} else {
  baseUrl = 'https://production-partner-portal-api.beamimpact.com/api'
}

export const API_BASE_PATH_PORTAL = baseUrl

/**
 * Returns Beam's Partner Portal API url. Defaults to v2 API.
 */
export function getV2ApiUrl(options?: { v1?: boolean }): string {
  let baseUrl = ''
  const href = window.location.href

  if (href.includes('localhost')) {
    baseUrl = 'http://localhost:4000/api'
  } else if (href.includes('dev') || href.includes('vercel')) {
    baseUrl = 'https://development-partner-portal-api.beamimpact.com/api'
  } else if (href.includes('staging')) {
    baseUrl = 'https://staging-partner-portal-api.beamimpact.com/api'
  } else {
    baseUrl = 'https://production-partner-portal-api.beamimpact.com/api'
  }

  // default to v2 unless v1 is specified
  if (!options?.v1) {
    baseUrl = `${baseUrl}/v2`
  }

  return baseUrl
}

/**
 * Checks if the client is in a dev environment based on the url. localhost and dev are considered dev environments.
 */
export const inDevEnvironment =
  window?.location?.href?.includes('localhost') || window?.location?.href?.includes('dev')

export const inStagingEnvironment = window?.location?.href?.includes('staging')
