import Backdrop from '@material-ui/core/Backdrop'
import Fade from '@material-ui/core/Fade'
import Modal from '@material-ui/core/Modal'
import { ReactNode } from 'react'

import styles from '../../style/root/beam-modal.module.css'

interface BeamModalProps {
  title?: string
  children: ReactNode
  open: boolean
  handleClose: () => void
  titleColor?: string
  isLarge?: boolean
  titleClasses?: string
}

export const BeamModal = ({
  title,
  children,
  open,
  handleClose,
  titleColor,
  isLarge,
  titleClasses = '',
}: BeamModalProps) => {
  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className="modal"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}>
        <Fade in={open}>
          <div className={isLarge ? styles['large-container'] : `${styles.container}`}>
            <div className={styles['modal-close-button-container']}>
              <button className={styles['modal-close-button']} onClick={handleClose}>
                Close
              </button>
            </div>
            <div className={isLarge ? `modal-large-content` : `modal-content`}>
              <h2
                className={titleClasses}
                style={isLarge ? { color: titleColor, alignSelf: 'center' } : { color: titleColor }}
                id="transition-modal-title">
                {title}
              </h2>
              {children}
            </div>
          </div>
        </Fade>
      </Modal>
    </div>
  )
}
