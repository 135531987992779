import '../../style/partner/invoices.css'

import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Typography from '@material-ui/core/Typography'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'
import Tooltip from '@mui/material/Tooltip'
import PropTypes from 'prop-types'
import React from 'react'
import { Component } from 'react'
import { connect } from 'react-redux'

import { API_BASE_PATH_PORTAL } from '../../api/auth'
import { fetchPartnerInvoices } from '../../redux/thunks/invoiceThunks'
import modalStyles from '../../style/root/beam-modal.module.css'
import { axiosRequest } from '../../utils/axiosRequest'
import { dollarFormat, percentFormat } from '../../utils/root'
import { BeamAlert } from '../root/BeamAlert'
import BeamButton from '../root/BeamButton'
import { BeamCircularProgress } from '../root/BeamCircularProgress'
import BeamDropdown from '../root/BeamDropdown'
import { BeamModal } from '../root/BeamModal'
import { BeamProgressBar } from '../root/BeamProgressBar'
import BeamTable from '../root/BeamTable'
import { BEAM_COLORS, INVOICE_STATUS_STYLING } from '../root/constants'

const TABLE_PADDING_VERTICAL = '49px'
const TABLE_PADDING_HORIZONTAL = '32px'
const TABLE_MARGIN_TOP = '30px'
const TABLE_BODY_WIDTH = '100.2%'

const TABLE_HEADERS = [
  {
    field: 'payTo',
    headerName: 'Transaction',
    dataType: 'string',
  },
  { field: 'category', headerName: 'Category', dataType: 'string' },
  { field: 'date', headerName: 'Issue Date', dataType: 'number' },
  { field: 'paidOn', headerName: 'Paid On', dataType: 'number' },
  { field: 'status', headerName: 'Status', dataType: 'string' },
  { field: 'amount', headerName: 'Amount', dataType: 'number' },
]

class PartnerInvoiceTable extends Component {
  constructor() {
    super()
    this.state = {
      today: new Date(),
      selectedStatus: 'all',
      sortColumn: null,
      hoveringColumn: null,
      sortAscending: false,
      statusOptions: [
        { display: 'All Invoices', value: 'all' },
        { display: 'Open Invoices', value: 'open' },
      ],
      selectedPayee: null,
      showCreationModal: false,
      showConfirmationModal: false,
      creatingPpgfLink: false,
      ppgfLink: '',
      ppgfError: null,
      sentDonations: [],
      failedDonations: [],
      sendingDonations: false,
      selectedNonprofitInvoice: {},
      gettingBillingAgreementLink: false,
      billingAgreementLink: '',
      billingAgreementError: '',
    }
    this.invoiceCategory = this.invoiceCategory.bind(this)
    this.invoiceStatus = this.invoiceStatus.bind(this)
    this.invoiceAmount = this.invoiceAmount.bind(this)
    this.invoiceAmountPayable = this.invoiceAmountPayable.bind(this)
    this.displayRows = this.displayRows.bind(this)
    this.selectStatus = this.selectStatus.bind(this)
    this.showSortArrow = this.showSortArrow.bind(this)
    this.setSortColumn = this.setSortColumn.bind(this)
    this.hideSortArrow = this.hideSortArrow.bind(this)
    this.invoiceName = this.invoiceName.bind(this)
    this.payTo = this.payTo.bind(this)
    this.sortFunction = this.sortFunction.bind(this)
    this.showDownArrow = this.showDownArrow.bind(this)
    this.showUpArrow = this.showUpArrow.bind(this)
    this.updateFilter = this.updateFilter.bind(this)
    this.payeeOptions = this.payeeOptions.bind(this)
    this.makeDonations = this.makeDonations.bind(this)
    this.initConfirmationModal = this.initConfirmationModal.bind(this)
    this.closeCreationModal = this.closeCreationModal.bind(this)
    this.invoicePaidInFull = this.invoicePaidInFull.bind(this)
  }
  payeeOptions() {
    const { invoices } = this.props
    return [
      ...invoices.reduce((options, invoice) => {
        options.add(invoice.nonprofit || 'Beam')
        return options
        // eslint-disable-next-line no-undef
      }, new Set()),
    ]
  }
  payTo(invoice) {
    return invoice.nonprofit || 'Beam'
  }
  invoiceCategory(invoice) {
    return invoice.nonprofit ? 'Nonprofits' : 'Beam'
  }
  invoiceStatus(invoice) {
    const { today } = this.state
    if (invoice.isPaid) return 'Paid'
    if (new Date(invoice.dueDate).getTime() < today.setHours(-24)) return 'Past Due'
    return 'Outstanding'
  }
  invoicePaidInFull(invoice) {
    return !!invoice && !!invoice.lineItems && invoice.lineItems.every(item => item.isPaid)
  }
  invoiceAmount(invoice) {
    if (!invoice.lineItems) return 0
    return invoice.lineItems.reduce((sum, item) => sum + item.amount, 0)
  }
  invoiceAmountPayable(invoice) {
    if (!invoice.lineItems) return 0
    return invoice.lineItems.reduce((sum, item) => (item.isPaid ? sum : sum + item.amount), 0)
  }
  showLoadMore() {
    if (this.props.invoices && this.props.invoices.loading) return false
    if (this.props.invoices.length <= this.state.rowsPerPage) return false
    if (this.props.invoices.length <= this.state.rowsPerPage * this.state.pages) return false
    return true
  }
  getBillingAgreementLink() {
    if (!this.props.user || !this.props.user.partnerId) {
      this.setState({ billingAgreementError: 'User has no partner association' })
      return
    }
    if (this.state.billingAgreementLink) return
    this.setState({ gettingBillingAgreementLink: true, billingAgreementError: '' })
    axiosRequest(
      'get',
      `${API_BASE_PATH_PORTAL}/partners/billing-agreement/id/${this.props.user.partnerId}`
    )
      .then(res => {
        this.setState({ billingAgreementLink: res.data, gettingBillingAgreementLink: false })
      })
      .catch(error => {
        this.setState({
          billingAgreementError: error.response && error.response.data,
          gettingBillingAgreementLink: false,
        })
      })
  }
  displayRows(invoices) {
    const {
      invoiceStatus,
      invoiceName,
      invoiceCategory,
      invoiceAmount,
      props: { user },
      state: { selectedStatus, selectedPayee },
    } = this
    return invoices
      .filter(invoice => {
        if (!invoice.paymentRequested || (invoice.id.indexOf('-N') > -1 && !user?.partner?.usePpgf))
          return false
        if (selectedStatus === 'open' && invoiceStatus(invoice) === 'Paid') return false
        if (selectedPayee && selectedPayee !== invoiceCategory(invoice)) return false
        return true
      })
      .map(invoice => ({
        id: invoice.id,
        payTo: { value: invoiceName(invoice), sortOn: invoice.nonprofit ? 'b' : 'a' },
        category: invoiceCategory(invoice),
        date: {
          value: new Date(invoice.date).toLocaleDateString(),
          sortOn: new Date(invoice.date).getTime(),
        },
        paidOn: {
          value: invoice.paidOn && new Date(invoice.paidOn).toLocaleDateString(),
          sortOn: invoice.paidOn ? new Date(invoice.paidOn).getTime() : 0,
        },
        status: {
          value: (
            <div
              className="invoice-status-cell"
              style={INVOICE_STATUS_STYLING[invoiceStatus(invoice)]}>
              {invoiceStatus(invoice)}
            </div>
          ),
          sortOn: invoiceStatus(invoice),
        },
        amount: { value: dollarFormat(invoiceAmount(invoice)), sortOn: invoiceAmount(invoice) },
        expandedRow: (
          <div>
            <Typography v1="subtitle1">
              <a target="_blank" rel="noreferrer" href={invoice.partnerInvoiceLink}>
                Invoice No. {invoice.id}
              </a>
            </Typography>
            <Typography variant="h6" gutterBottom component="div">
              Line Items
            </Typography>
            <Table size="small" aria-label="purchases">
              <TableHead>
                <TableRow>
                  <TableCell>Date</TableCell>
                  <TableCell>
                    {invoice.id.indexOf('-N') > -1 ? 'Nonprofit' : 'Description'}
                  </TableCell>
                  {invoice.id.indexOf('-N') > -1 && <TableCell>Status</TableCell>}
                  <TableCell>Amount</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {invoice.lineItems
                  .sort((a, b) => {
                    const np1 = a.nonprofit
                    const np2 = b.nonprofit
                    if (!np1 || !np2) return 0
                    const npl1 = np1.toLowerCase()
                    const npl2 = np2.toLowerCase()
                    if (npl1 < npl2) return -1
                    if (npl1 > npl2) return 1
                    return 0
                  })
                  .map((item, i) => (
                    <TableRow key={`${invoice.id}-line-item-${i}`}>
                      <TableCell component="th" scope="row">
                        {new Date(item.date).toLocaleDateString()}
                      </TableCell>
                      <TableCell>
                        {invoice.id.indexOf('-N') > -1 && item.nonprofit
                          ? item.nonprofit
                          : item.description}
                      </TableCell>
                      {invoice.id.indexOf('-N') > -1 && (
                        <TableCell>
                          {item.isPaid ? (
                            <span style={{ color: BEAM_COLORS.green }}>
                              <CheckCircleOutlineIcon />
                            </span>
                          ) : item.error ? (
                            <span style={{ color: BEAM_COLORS.red }}>
                              <Tooltip
                                title="A PayPal error occured. Beam has been notified"
                                placement="top">
                                <ErrorOutlineIcon />
                              </Tooltip>
                            </span>
                          ) : (
                            'Not Paid'
                          )}
                        </TableCell>
                      )}
                      <TableCell>{dollarFormat(item.amount, 2)}</TableCell>
                    </TableRow>
                  ))}
                <TableRow key={`${invoice.id}-total`}>
                  <TableCell>
                    <b>Total</b>
                  </TableCell>
                  <TableCell />
                  {invoice.id.indexOf('-N') > -1 && <TableCell />}
                  <TableCell>
                    <b>{dollarFormat(invoiceAmount(invoice), 2)}</b>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </div>
        ),
      }))
  }
  loadMoreRows() {
    this.setState({ pages: this.state.pages + 1 })
  }
  selectStatus(status) {
    this.setState({ selectedStatus: status })
  }
  showSortArrow(column) {
    this.setState({ hoveringColumn: column })
  }
  hideSortArrow() {
    this.setState({ hoveringColumn: null })
  }
  makeDonations(invoice) {
    this.setState({
      showCreationModal: true,
      ppgfError: null,
      selectedNonprofitInvoice: invoice,
      sendingDonations: true,
      showConfirmationModal: false,
    })
    let i = 0
    for (const { nonprofit, isPaid } of invoice.lineItems) {
      if (isPaid) continue
      axiosRequest('post', `${API_BASE_PATH_PORTAL}/invoices/pay/nonprofit/ppgf`, {
        invoiceId: invoice.id,
        nonprofit,
      })
        .then(() => {
          i++
          this.setState({
            sentDonations: [...this.state.sentDonations, nonprofit],
            sendingDonations: i < invoice.lineItems.length,
          })
          if (i >= invoice.lineItems.length)
            axiosRequest('post', `${API_BASE_PATH_PORTAL}/invoices/ppgf/email-confirmation`, {
              invoiceId: invoice.id,
            })
        })
        .catch(() => {
          i++
          this.setState({
            failedDonations: [...this.state.failedDonations, nonprofit],
            sendingDonations: i < invoice.lineItems.length,
          })
          if (i >= invoice.lineItems.length)
            axiosRequest('post', `${API_BASE_PATH_PORTAL}/invoices/ppgf/email-confirmation`, {
              invoiceId: invoice.id,
            })
        })
    }
  }
  invoiceName(invoice) {
    return invoice.partnerInvoiceLink ? (
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <a target="_blank" rel="noreferrer" href={invoice.partnerInvoiceLink}>
          Beam Service Invoice
        </a>
        <div>{invoice.description}</div>
        <BeamButton
          href={invoice.partnerInvoiceLink}
          text="Pay"
          style={{
            height: '30px',
            width: '100px',
            marginTop: '10px',
            background: BEAM_COLORS.orangeYellowGradient,
          }}
        />
      </div>
    ) : (
      <div>
        <div>{invoice.nonprofit}</div>
        {!invoice.isPaid || !invoice.nonprofitLink ? (
          <BeamButton
            text="Make Donations"
            disabled={this.invoicePaidInFull(invoice)}
            handler={() => this.initConfirmationModal(invoice)}
            style={{
              height: '30px',
              width: '170px',
              marginTop: '10px',
              background: BEAM_COLORS.orangeYellowGradient,
            }}
          />
        ) : (
          <BeamButton
            text="Make Donations"
            disabled={invoice.isPaid}
            href={invoice.nonprofitLink}
            style={{
              height: '30px',
              width: '170px',
              marginTop: '10px',
              background: BEAM_COLORS.orangeYellowGradient,
            }}
          />
        )}
      </div>
    )
  }
  initConfirmationModal(invoice) {
    if (this.invoicePaidInFull(invoice)) return
    this.setState({
      showConfirmationModal: true,
      selectedNonprofitInvoice: invoice,
    })
    this.getBillingAgreementLink()
  }
  sortFunction(ascending, column) {
    const { invoiceCategory, invoiceStatus, invoiceAmount } = this
    const dateComparator = invoice => new Date(invoice.date)
    const comparatorMap = {
      payTo: invoice =>
        invoice.nonprofit ? invoice.nonprofit.toLowerCase() : 'beam service invoice',
      category: invoiceCategory,
      date: dateComparator,
      paidOn: dateComparator,
      status: invoiceStatus,
      amount: invoiceAmount,
    }
    const comparator = comparatorMap[column]
    const numberComparison = column === 'amount'
    if (!comparator) return null
    return ascending
      ? function (row1, row2) {
          if (numberComparison) return comparator(row1) - comparator(row2)
          if (comparator(row1) > comparator(row2)) return 1
          if (comparator(row1) < comparator(row2)) return -1
          return 0
        }
      : function (row1, row2) {
          if (numberComparison) return comparator(row2) - comparator(row1)
          if (comparator(row1) < comparator(row2)) return 1
          if (comparator(row1) > comparator(row2)) return -1
          return 0
        }
  }
  setSortColumn(column) {
    if (this.state.sortColumn === column) {
      this.setState({ sortAscending: !this.state.sortAscending })
    } else {
      this.setState({ sortColumn: column, sortAscending: false })
    }
  }
  showDownArrow(sortColumn, column, ascending, hoveringColumn) {
    return (
      (sortColumn === column && !ascending) || (sortColumn !== column && hoveringColumn === column)
    )
  }
  showUpArrow(sortColumn, column, ascending) {
    return sortColumn === column && ascending
  }
  updateFilter(target, value) {
    this.setState({ [target]: value })
  }
  closeCreationModal() {
    const { user, fetchPartnerInvoices } = this.props
    this.setState({
      showCreationModal: false,
      sentDonations: [],
      failedDonations: [],
      selectedNonprofitInvoice: {},
    })
    fetchPartnerInvoices(user.partnerId)
  }
  render() {
    const {
      displayRows,
      selectStatus,
      updateFilter,
      makeDonations,
      invoiceAmountPayable,
      closeCreationModal,
      props: { loadingStates, invoices },
      state: {
        selectedStatus,
        statusOptions,
        selectedPayee,
        showCreationModal,
        sentDonations,
        failedDonations,
        sendingDonations,
        showConfirmationModal,
        selectedNonprofitInvoice,
        gettingBillingAgreementLink,
        billingAgreementLink,
        billingAgreementError,
      },
    } = this
    const attemptedDonations = sentDonations.length + failedDonations.length
    const donationsToSend =
      selectedNonprofitInvoice && selectedNonprofitInvoice.lineItems
        ? selectedNonprofitInvoice.lineItems.filter(item => item.payable && !item.isPaid)
        : 0
    return (
      <div
        style={{
          boxSizing: 'border-box',
          width: TABLE_BODY_WIDTH,
          overflowX: 'auto',
        }}>
        <div style={{ display: 'flex' }}>
          <div
            onClick={() => selectStatus('open')}
            className="invoice-status-select"
            id={`${selectedStatus === 'open' ? 'selected-status' : ''}`}>
            Open Invoices
          </div>
          <div
            onClick={() => selectStatus('all')}
            className="invoice-status-select"
            id={`${selectedStatus === 'all' ? 'selected-status' : ''}`}>
            All Invoices
          </div>
        </div>
        <div
          style={{
            minWidth: '991px',
            backgroundColor: '#FFF',
            borderRadius: '8px',
            boxShadow: '10px 5px 32px #e3e6ec',
            padding: `${TABLE_PADDING_VERTICAL} ${TABLE_PADDING_HORIZONTAL}`,
            marginTop: TABLE_MARGIN_TOP,
            boxSizing: 'border-box',
          }}>
          <div className="filter-container">
            <div className="table-label">
              {selectedStatus === 'all' ? 'All Invoices' : 'Open Invoices'}
            </div>
            <div className="invoice-filter-dropdowns">
              <BeamDropdown
                changeHandler={updateFilter}
                name="selectedPayee"
                options={['Beam', 'Nonprofits']}
                selectedValue={selectedPayee}
                placeholderDisplay="Filter By Payee"
                spaceAround={{ right: '10px' }}
              />
              <BeamDropdown
                changeHandler={updateFilter}
                name="selectedStatus"
                options={statusOptions}
                selectedValue={selectedStatus}
              />
            </div>
          </div>
          <BeamTable
            headers={TABLE_HEADERS}
            rows={displayRows(invoices)}
            loading={loadingStates.invoices && loadingStates.invoices.loading}
            title={selectedStatus === 'all' ? 'All Invoices' : 'Open Invoices'}
            defaultSortColum="payTo"
            defaultSortAscending={true}
            emptyTableMessage="You don't have any invoices yet"
            expandable={true}
            simplePagination={true}
          />
        </div>
        <BeamModal
          open={showCreationModal}
          handleClose={closeCreationModal}
          title={
            sendingDonations || attemptedDonations / donationsToSend < 0.99
              ? 'Sending Your Donations'
              : 'Sent'
          }>
          <div className={modalStyles['modal-container']} style={{ maxWidth: '380px' }}>
            {sendingDonations || attemptedDonations / donationsToSend < 0.99 ? (
              <BeamCircularProgress
                progress={(attemptedDonations / donationsToSend) * 100}
                label={percentFormat(attemptedDonations, donationsToSend, 0, true)}
              />
            ) : (
              <div>
                <div style={{ textAlign: 'center' }}>
                  Attempted to send {attemptedDonations} Donations.{' '}
                  {`${sentDonations.length} (${
                    !isNaN(
                      sentDonations.length / (sentDonations.length + failedDonations.length)
                    ) &&
                    percentFormat(
                      sentDonations.length,
                      sentDonations.length + failedDonations.length,
                      0,
                      true
                    )
                  }) completed
                successfully.`}
                </div>
                {
                  <BeamAlert
                    text={
                      failedDonations.length > 0
                        ? 'A PayPal error occured while sending donations. Beam has been notified and will follow up with a resolution. Thank you for your patience!'
                        : 'All of your donations were sent successfully'
                    }
                    variant={failedDonations.length >= 1 ? 'error' : 'success'}
                  />
                }
              </div>
            )}
            <div
              style={{
                display: 'flex',
                alignItems: 'flex-start',
                flexDirection: 'column',
                maxHeight: '200px',
                overflowY: 'auto',
                marginTop: '15px',
              }}>
              {sentDonations.map(nonprofit => (
                <div
                  style={{ color: BEAM_COLORS.green, display: 'flex', alignItems: 'center' }}
                  key={`sent-${nonprofit}`}>
                  <CheckCircleOutlineIcon />
                  {nonprofit}
                </div>
              ))}
              {failedDonations.map(nonprofit => (
                <div
                  style={{ color: BEAM_COLORS.red, display: 'flex', alignItems: 'center' }}
                  key={`failed-${nonprofit}`}>
                  <ErrorOutlineIcon />
                  {nonprofit}
                </div>
              ))}
            </div>
          </div>
        </BeamModal>
        <BeamModal
          open={showConfirmationModal}
          handleClose={() => this.setState({ showConfirmationModal: false })}
          title={billingAgreementLink ? 'Confirmation' : "Let's get you set up"}>
          <div className={modalStyles['modal-container']}>
            <div style={{ maxWidth: '300px' }}>
              {gettingBillingAgreementLink && <BeamProgressBar />}
              {!gettingBillingAgreementLink && !billingAgreementError && (
                <div>
                  <div>
                    <ul>
                      <li>
                        You will be charged a total of{' '}
                        {dollarFormat(invoiceAmountPayable(selectedNonprofitInvoice), true)} for
                        tax-deductible donations owed during this period.
                      </li>
                      <li>
                        Your funds will go to{' '}
                        <a
                          href="https://www.paypal.com/us/webapps/mpp/givingfund/home"
                          target="_blank"
                          rel="noreferrer">
                          PayPal Giving Fund PayPal Giving Fund
                        </a>
                        , an IRS-registered 501(c)(3) nonprofit organization. PPGF receives the
                        donation and distributes the appropriate amount to each of your partner
                        nonprofits.
                      </li>
                    </ul>
                  </div>
                  <div
                    style={{
                      marginTop: '20px',
                      width: '100%',
                      display: 'flex',
                      justifyContent: 'center',
                    }}>
                    <BeamButton
                      text="CONFIRM"
                      handler={() => makeDonations(selectedNonprofitInvoice)}
                    />
                  </div>
                </div>
              )}
              {!billingAgreementLink && !gettingBillingAgreementLink && billingAgreementError && (
                <div style={{ wordWrap: 'break-word' }}>
                  To make donations in one click, we just need you to authorize a connection to
                  PayPal Giving Fund. Connect to PPGF{' '}
                  <a
                    href={`https://partner-portal.beamimpact.com/partner/paypal/onboarding`}
                    target="_blank"
                    rel="noreferrer">
                    here.
                  </a>
                </div>
              )}
            </div>
          </div>
        </BeamModal>
      </div>
    )
  }
}

PartnerInvoiceTable.propTypes = {
  invoices: PropTypes.array,
  loadingStates: PropTypes.object,
  user: PropTypes.object,
  fetchPartnerInvoices: PropTypes.func,
}

const mapState = state => {
  return {
    loadingStates: state.loadingStates,
    errors: state.errors,
  }
}

const mapDispatch = dispatch => {
  return {
    fetchPartnerInvoices: partnerId => dispatch(fetchPartnerInvoices(partnerId)),
  }
}

export default connect(mapState, mapDispatch)(PartnerInvoiceTable)
