import LinearProgress from '@mui/material/LinearProgress'

import styles from '../../style/root/beam-progress-bar.module.css'

export const BeamProgressBar = () => {
  return (
    <div className={styles['progress-bar']}>
      <LinearProgress />
    </div>
  )
}
