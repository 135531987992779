import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'

import { BEAM_COLORS } from './constants'

export const BeamAlert = ({ text, variant }: { text?: string; variant?: string }) => {
  return (
    <div
      style={{
        color: variant === 'error' ? BEAM_COLORS.red : BEAM_COLORS.green,
        borderRadius: '8px',
        padding: '10px',
        margin: '10px',
        fontSize: '12px',
        position: 'relative',
        border: `1px solid ${BEAM_COLORS.darkGray}`,
      }}>
      {variant === 'error' ? (
        <ErrorOutlineIcon
          style={{
            position: 'absolute',
            top: '-10px',
            left: '-11px',
            background: '#FFF',
          }}
        />
      ) : (
        <CheckCircleOutlineIcon
          style={{
            position: 'absolute',
            top: '-10px',
            left: '-11px',
            background: '#FFF',
          }}
        />
      )}
      <span style={{ color: BEAM_COLORS.darkGray }}>{text}</span>
    </div>
  )
}
