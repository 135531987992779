import './welcome-note.css'

import { Maybe } from '../../utils/types'

export const WelcomeNote = ({
  firstName,
  lastName,
  pageTitle,
}: {
  firstName?: Maybe<string>
  lastName?: Maybe<string>
  pageTitle?: Maybe<string>
}) => {
  return (
    <div className="beam-large-text">
      <div style={{ fontSize: '24px', fontWeight: '500', lineHeight: 1.3 }}>
        Hi {firstName} {lastName},
      </div>
      <div style={{ lineHeight: 1.4, fontSize: '48px', fontWeight: 600 }}>{pageTitle}</div>
    </div>
  )
}
