import { Dispatch } from 'redux'

import { setAccessToken, setUser } from '../actions'

export const updateAccessToken = (accessToken: string | null) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(setAccessToken(accessToken))
    } catch (error: any) {
      dispatch(setAccessToken(null))
      if (error.response && [401, 403].includes(error.response.status)) {
        dispatch(setUser({}))
        return
      }
    }
  }
}
