import { useEffect, useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'

import { useBeamSelector } from '../../hooks'
import { fetchAllPartners, FetchAllPartnersResponse } from '../../redux/thunks/newAdminThunks'
import { dateFileFormat } from '../../utils/root'
import { APIError } from '../root/APIError'
import { BeamFuzzySearch } from '../root/BeamFuzzySearch'
import BeamTable from '../root/BeamTable'
import { LoginPage } from '../root/LoginPage'
import { PageNotFound } from '../root/PageNotFound'

interface TCsvRow {
  partner: string | undefined
  name: any
  reportId: any
  partnerId: any
  type: any
  quarterId: any
  yearId: any
  beamTransactions: any
  nonBeamTransactions: any
  beamSpending: any
  nonBeamSpending: any
  beamUniqueCustomers: any
  nonBeamUniqueCustomers: any
  beamRepeatCustomers: any
  nonBeamRepeatCustomers: any
  startDate: any
  endDate: any
  donationsValue: any
  matchDonations: any
  month: any
  yearNumber: any
  uniqueNonprofitSelections: any
  shopifyConversionRate: any
  updatedAt: any
  createdAt: any
}

const TABLE_HEADERS = [
  {
    field: 'name',
    headerName: 'Name',
    dataType: 'string',
  },
  {
    field: 'launchDate',
    headerName: 'Launch',
    dataType: 'date',
  },
  {
    field: 'paymentStructure',
    headerName: 'Payment Structure',
    width: '200px',
    dataType: 'string',
  },
  {
    field: 'invoiceEmail',
    headerName: 'Invoicee',
    dataType: 'string',
  },
  {
    field: 'lastReport',
    headerName: 'Last Report',
    dataType: 'string',
  },
]

export const Partners = () => {
  const [partnerSearchInput, setPartnerSearchInput] = useState('')

  const dispatch = useDispatch() // FIXME: Use `useDispatch` when we have typedefs
  const loadingStates = useBeamSelector(({ loadingStates }) => loadingStates) as any // FIXME: Remove any when we have typedefs for reducers
  const user = useBeamSelector(({ user }) => user)
  const partners = useBeamSelector(({ allPartners }) => allPartners) as FetchAllPartnersResponse // FIXME: Remove typecasting when we have typedefs for reducers

  useEffect(() => {
    dispatch(fetchAllPartners(true))
  }, [dispatch])

  const partnerRows = useMemo(() => {
    const formattedPaymentStructureName = (paymentStructureName: string | null) => {
      return !paymentStructureName ? null : paymentStructureName.replace(/ Pricing Model/, '')
    }

    const tzOffset = new Date().getTimezoneOffset() * 60000
    const searchTerm = partnerSearchInput?.toLowerCase()
    const usePartners = partnerSearchInput
      ? partners?.filter(p => {
          const name = p?.partnerPortal
            ? p.partnerPortal.name.toLowerCase()
            : p.centralBackend?.name.toLowerCase()

          if (!name) return false

          return (
            name === searchTerm || name.indexOf(searchTerm) > -1 || searchTerm.indexOf(name) > -1
          )
        })
      : partners

    return usePartners.map(partner => {
      const ppPartner = partner?.partnerPortal
      const cbPartner = partner?.centralBackend

      return {
        invoiceEmail: ppPartner?.invoiceEmail,
        name: {
          value: (
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              {ppPartner ? ppPartner.name : cbPartner?.name}:
              {ppPartner && (
                <Link
                  to={`/admin/partners/partner-portal/${ppPartner.id}?chainId=${cbPartner?.id}`}>
                  Partner Portal
                </Link>
              )}
              {cbPartner && (
                <div>
                  <Link to={`/admin/partners/central-backend/${cbPartner.id}`}>
                    Central Backend
                  </Link>
                  <br />
                  <Link to={`/admin/partners/partner-portal/v2/${ppPartner?.id}`}>Reports V2</Link>
                </div>
              )}
            </div>
          ),
          sortOn: ppPartner?.name || cbPartner?.name,
        },
        launchDate: ppPartner?.launchDate
          ? new Date(new Date(ppPartner.launchDate).getTime() + tzOffset).toLocaleDateString()
          : null,
        paymentStructure:
          ppPartner && formattedPaymentStructureName(ppPartner?.paymentStructure?.name || null),
      }
    })
  }, [partners, partnerSearchInput])

  const csvCreateRows = useMemo(() => {
    const csvRows: TCsvRow[] = []
    if (!partners || !partners.length) return []

    const reports: any[] = []
    const partnerNameMap = new Map<number, string>()
    partners.forEach(partner => {
      const portalPartner = partner?.partnerPortal
      if (portalPartner) {
        partnerNameMap.set(portalPartner.id, portalPartner.name)
      }
    })

    reports.forEach(report => {
      csvRows.push({
        partner: partnerNameMap.get(report.partnerId),
        name: report.name,
        reportId: report.id,
        partnerId: report.partnerId,
        type: report.type,
        quarterId: report.quarterId,
        yearId: report.yearId,
        beamTransactions: report.beamTransactions,
        nonBeamTransactions: report.nonBeamTransactions,
        beamSpending: report.beamSpending,
        nonBeamSpending: report.nonBeamSpending,
        beamUniqueCustomers: report.beamUniqueCustomers,
        nonBeamUniqueCustomers: report.nonBeamUniqueCustomers,
        beamRepeatCustomers: report.beamRepeatCustomers,
        nonBeamRepeatCustomers: report.nonBeamRepeatCustomers,
        startDate: report.startDate,
        endDate: report.endDate,
        donationsValue: report.donationsValue,
        matchDonations: report.matchDonations,
        month: report.month,
        yearNumber: report.yearNumber,
        uniqueNonprofitSelections: report.uniqueNonprofitSelections,
        shopifyConversionRate: report.shopifyConversionRate,
        updatedAt: report.updatedAt,
        createdAt: report.createdAt,
      })
    })
    return csvRows
  }, [partners])

  if (loadingStates?.user?.loading) return <div>One moment please...</div>
  if (loadingStates?.user?.error) {
    return <APIError error={loadingStates.user.error} />
  }

  if (!user?.type) return <LoginPage />
  if (['Admin', 'Super'].indexOf(user.type) === -1) return <PageNotFound />

  const csvRows = csvCreateRows

  return (
    <div>
      <h1>All Partners</h1>
      <div>
        <BeamFuzzySearch
          placeholder="Filter By Partner"
          handler={(input: string) => setPartnerSearchInput(input)}
          inputValue={partnerSearchInput}
          style={{ width: '250px' }}
        />
      </div>
      <BeamTable
        headers={TABLE_HEADERS}
        rows={partnerRows}
        defaultSortAscending={true}
        defaultSortColumn="name"
        defaultSortDataType="string"
        loading={loadingStates?.allPartners?.loading}
        pagination={true}
        exportable={csvRows && csvRows.length > 0}
        exportData={{ rows: csvRows }}
        exportFileName={`Partners_Monthly_Reports_${dateFileFormat()}`}
      />
    </div>
  )
}
