import type { IconLibrary } from '@shoelace-style/shoelace/dist/components/icon/library'
import systemLibrary from '@shoelace-style/shoelace/dist/components/icon/library.system'

/**
 * BEAM OVERRIDES for Shoelace System Icons
 * https://shoelace.style/components/icon#customizing-the-system-library
 * https://github.com/shoelace-style/shoelace/blob/next/src/components/icon/library.system.ts
 */
const icons = {
  // Used in dropdowns
  // Src: https://github.com/material-icons/material-icons/tree/master/svg/arrow_drop_down
  'chevron-down': `
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="currentColor">
      <path d="M7 10l5 5l5-5H7z"/>
    </svg>
  `,
  // Used for close icons in modals, etc.
  // Src: https://github.com/material-icons/material-icons/tree/master/svg/close
  'x-lg': `
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="currentColor">
      <path d="M19 6.41L17.59 5L12 10.59L6.41 5L5 6.41L10.59 12L5 17.59L6.41 19L12 13.41L17.59 19L19 17.59L13.41 12L19 6.41z"/>
    </svg>
  `,
}

export const customSystemLibrary: IconLibrary = {
  name: 'system',
  resolver: (name: string) => {
    if (name in icons) {
      return `data:image/svg+xml,${encodeURIComponent(icons[name as keyof typeof icons])}`
    }
    return systemLibrary.resolver(name)
  },
}
