import { CellContext, createColumnHelper } from '@tanstack/react-table'
import { isArray } from 'lodash'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router'

import { getV2ApiUrl } from '../../../api/auth'
import { BeamButton } from '../../../stories/BeamButton'
import { BeamTable } from '../../../stories/BeamTable'
import { BeamTextfield } from '../../../stories/BeamTextfield'
import { BeamToast } from '../../../stories/BeamToast'
import { BeamToggle } from '../../../stories/BeamToggle'
import { axiosRequest } from '../../../utils/axiosRequest'
import { ChainIntegratedSalesChannel } from './SalesChannels.types'

type SalesChannelColumns = {
  salesChannel: string
  includeInReporting: boolean
  totalRedemptions: number
  totalSelections: number
  totalTransactions: number
  updatedAt: string
  save: string
}

const TableCell = ({ getValue, row, column, table }: CellContext<SalesChannelColumns, any>) => {
  const initialValue = getValue()
  const [value, setValue] = useState(initialValue)
  const tableMeta = table.options.meta as any

  // If the initialValue is changed externally, sync it up with this state
  useEffect(() => {
    setValue(initialValue)
  }, [initialValue])

  if (!tableMeta?.updateData) {
    console.error(`No 'updateData' table meta present.`)
    return
  }

  // When the input is blurred, we'll call our table meta's updateData function
  function onBlur() {
    tableMeta.updateData(row.index, column.id, value)
  }

  // if you want a cell to be an editable text field, include it in this array
  const editableTextColumns: string[] = []

  if (column.id === 'includeInReporting') {
    return (
      <BeamToggle
        checked={!!value}
        label={value ? 'Yes' : 'No'}
        onChange={(e: any) => {
          const newValue = e.target.checked

          tableMeta.updateData(row.index, column.id, newValue)
          setValue(!!newValue)
        }}
      />
    )
  }

  if (editableTextColumns.includes(column.id)) {
    return (
      <BeamTextfield
        value={value}
        placeholder={'[Untitled]'}
        onChange={e => setValue(e.target.value)}
        onBlur={onBlur}
        name={column.id}
      />
    )
  }

  return value
}

async function fetchSalesChannels(chainId: number): Promise<ChainIntegratedSalesChannel[] | null> {
  const response = await axiosRequest('GET', `${getV2ApiUrl()}/admin/salesChannel/${chainId}`)
  return (response?.data as ChainIntegratedSalesChannel[]) || null
}

async function updateSalesChannel(
  salesChannelData: ChainIntegratedSalesChannel
): Promise<ChainIntegratedSalesChannel | null> {
  const { chainIntegratedSalesChannelId, salesChannel, includeInReporting } = salesChannelData
  const reqBody = {
    salesChannel,
    includeInReporting,
  }

  const response = await axiosRequest(
    'PUT',
    `${getV2ApiUrl()}/admin/salesChannel/${chainIntegratedSalesChannelId}/update`,
    reqBody
  )

  return (response?.data as ChainIntegratedSalesChannel) || null
}

export const SalesChannelsPage = () => {
  const params = useParams()
  const [errorMessage, setErrorMessage] = useState<string | null>(null)
  const [salesChannelData, setSalesChannelData] = useState<ChainIntegratedSalesChannel[]>([])
  // set of indexes for rows with unsaved changes
  const [unsavedRowIndexes, setUnsavedRowIndexes] = useState<Set<number>>(new Set([]))
  // set of indexes of rows with a "loading" state while they update
  const [rowUpdatingIndexes, setRowUpdatingIndexes] = useState<Set<number>>(new Set([]))

  const chainId = +(params as any)?.chainId

  // Fetches sales channels the first time. Doesn't trigger unless salesChannelData is empty.
  useEffect(() => {
    if (salesChannelData.length > 0 || !chainId) return

    async function handleFetchingSalesChannels(chainId: number) {
      try {
        const result = await fetchSalesChannels(chainId)

        if (isArray(result)) {
          setSalesChannelData(result)
        } else {
          setErrorMessage('There was an error fetching sales channels for this chain.')
        }
      } catch (err: any) {
        console.error(err)
        setErrorMessage(err.message)
      }
    }

    handleFetchingSalesChannels(+chainId)
  }, [chainId, salesChannelData.length])

  // Fetches sales channels and stores it in state

  function handleChangeRowUpdatingIndexes(op: 'add' | 'remove', index: number) {
    setRowUpdatingIndexes(prev => {
      const newSet = new Set(prev)
      if (op === 'add') {
        newSet.add(index)
      } else if (op === 'remove') {
        newSet.delete(index)
      }

      return newSet
    })
  }

  // saves the row data in the DB
  function handleSaveRow(rowIndex: number) {
    const rowData = salesChannelData[rowIndex]

    if (!rowData) {
      console.error(`No data found at row ${rowIndex}`)
      return
    }
    if (rowUpdatingIndexes.has(rowIndex)) {
      console.log(`Row ${rowIndex} is already processing...`)
      return
    }

    // set the loading state
    handleChangeRowUpdatingIndexes('add', rowIndex)
    // clear error messages
    setErrorMessage(null)

    // call the api to update the data
    updateSalesChannel(rowData)
      .then(responseData => {
        handleChangeRowUpdatingIndexes('remove', rowIndex)

        if (responseData === null) {
          console.error(`Unknown error while saving sales channel`)
          return
        }

        // remove saved row from unsavedRowIndexes
        setUnsavedRowIndexes(prevState => {
          const newState = new Set(prevState)
          newState.delete(rowIndex)
          return newState
        })

        // replace the item in state with the new data
        setSalesChannelData(prevState => {
          const newState = [...prevState]
          newState[rowIndex] = responseData
          return newState
        })
      })
      .catch(error => {
        console.error(error)

        setErrorMessage(`Failed to save data for ${rowData.salesChannel} (row ${rowIndex}).`)
        handleChangeRowUpdatingIndexes('remove', rowIndex)
      })
  }

  if (!chainId) {
    return null
  }

  const chainName = salesChannelData[0]?.chainName

  // BEGIN TABLE STUFF

  const tableData: SalesChannelColumns[] = salesChannelData.map(fetchedSalesChannel => ({
    salesChannelId: fetchedSalesChannel.chainIntegratedSalesChannelId,
    salesChannel: fetchedSalesChannel.salesChannel,
    includeInReporting: fetchedSalesChannel.includeInReporting,
    totalRedemptions: fetchedSalesChannel.totalRedemptions,
    totalSelections: fetchedSalesChannel.totalSelections,
    totalTransactions: fetchedSalesChannel.totalTransactions,
    updatedAt: new Date(fetchedSalesChannel.updatedAt).toLocaleDateString(),
    save: '',
  }))

  const columnHelper = createColumnHelper<SalesChannelColumns>()

  const columns = [
    columnHelper.accessor('salesChannel', {
      header: 'Sales Channel',
      cell: TableCell,
    }),
    columnHelper.accessor('includeInReporting', {
      header: 'Included In Reporting',
      cell: TableCell,
    }),
    columnHelper.accessor('totalRedemptions', { header: 'Total Redemptions' }),
    columnHelper.accessor('totalSelections', { header: 'Total Selections' }),
    columnHelper.accessor('updatedAt', { header: 'Last Updated' }),
    columnHelper.accessor('save', {
      header: '',
      cell: ({ row }) => {
        const hasUnsavedChanges = unsavedRowIndexes.has(row.index)
        const isLoading = rowUpdatingIndexes.has(row.index)

        return (
          <div className={'w-[200px] flex justify-center'}>
            <BeamButton
              label={isLoading ? 'Saving...' : 'Save'}
              variant={isLoading ? 'neutral' : 'basic'}
              type={'button'}
              className={'w-[120px]'}
              disabled={!hasUnsavedChanges || isLoading}
              onClick={() => {
                handleSaveRow(row.index)
              }}
            />
          </div>
        )
      },
      size: 150,
      minSize: 150,
    }),
  ]

  // END TABLE STUFF

  return (
    <section className={'grid grid-cols-1'}>
      <BeamToast
        open={!!errorMessage}
        text={errorMessage || 'Unknown error occurred while saving changes.'}
        variant={'error'}
        onClose={() => setErrorMessage(null)}
        closable
      />

      <h1 className={'beam--heading--1'}>Sales Channels for {chainName}</h1>

      <BeamTable
        columns={columns}
        data={tableData}
        variant={'Basic'}
        meta={{
          updateData: (rowIndex: number, columnId: string, newValues: SalesChannelColumns) => {
            // updates salesChannelData with the input values and records the indexes for unsaved rows
            setSalesChannelData(oldData => {
              return oldData.map((row, index) => {
                if (index === rowIndex) {
                  setUnsavedRowIndexes(prev => {
                    const newSet = new Set(prev)
                    newSet.add(index)
                    return newSet
                  })

                  return {
                    ...oldData[rowIndex],
                    [columnId]: newValues,
                  }
                }
                return row
              })
            })
          },
        }}
      />
    </section>
  )
}
