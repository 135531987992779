import { getV2ApiUrl } from '../../../api/auth'
import { axiosRequest } from '../../../utils/axiosRequest'

type UpdateLineItemApiResponse = {
  ok: boolean
}

export async function updateLineItemPaidStatus({
  lineItemId,
}: {
  lineItemId: string
}): Promise<UpdateLineItemApiResponse> {
  const data = await axiosRequest('PUT', `${getV2ApiUrl()}/admin/invoices/lineItem/update`, {
    id: lineItemId,
    isPaid: true,
  })

  return data?.data
}
