import { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'

import { useBeamSelector } from '../../hooks'
import { fetchNonprofits } from '../../redux/thunks/adminThunks'
import { TCauseNonprofit, TNonprofit, TUser } from '../../utils/types'
import { APIError } from '../root/APIError'
import BeamButton from '../root/BeamButton'
import { BeamFuzzySearch } from '../root/BeamFuzzySearch'
import BeamTable from '../root/BeamTable'
import { LoginPage } from '../root/LoginPage'
import { PageNotFound } from '../root/PageNotFound'
import { NonprofitEditModal } from './NonprofitEditModal'

const TABLE_HEADERS = [
  {
    field: 'name',
    headerName: 'Name',
    dataType: 'string',
  },
  {
    field: 'causes',
    headerName: 'Causes',
    dataType: 'string',
  },
  // {
  //   field: 'isActive',
  //   headerName: 'Is Active',
  //   dataType: 'boolean',
  // },
  {
    field: 'scopeOfWork',
    headerName: 'Scope Of Work',
    dataType: 'string',
  },
]

const NonprofitSearchBox = ({
  onChange,
  initialValue,
}: {
  onChange: any
  initialValue: string
}) => {
  const [value, setValue] = useState(initialValue)

  const handleChange = useCallback(onChange, [onChange])

  return (
    <BeamFuzzySearch
      placeholder="Filter Nonprofits"
      handler={text => {
        setValue(text)
        handleChange(text)
      }}
      inputValue={value}
      style={{ width: '250px' }}
    />
  )
}

export const Nonprofits = () => {
  const dispatch = useDispatch()

  // FIXME: Use correct types when we have them
  const loadingStates = useBeamSelector(({ loadingStates }) => loadingStates) as any
  const nonprofits = useBeamSelector(({ nonprofits }) => nonprofits) as TNonprofit[]
  const user = useBeamSelector(({ user }) => user) as TUser

  const [nonprofitSearchInput, setNonprofitSearchInput] = useState<string>('')

  const [showEditNonprofitModal, setShowEditNonprofitModal] = useState<boolean>(false)
  const toggleModal = () => {
    setShowEditNonprofitModal(false)
  }

  useEffect(() => {
    if (nonprofits.length || loadingStates?.nonprofits?.loading || loadingStates?.nonprofits?.error)
      return
    dispatch(fetchNonprofits())
  }, [nonprofits, dispatch, loadingStates?.nonprofits])

  const nonprofitRows = useMemo(() => {
    const searchTerm = nonprofitSearchInput && nonprofitSearchInput.toLowerCase()
    const useNonprofits = nonprofitSearchInput
      ? nonprofits.filter((nonprofit: TNonprofit) => {
          const name = nonprofit.name.toLowerCase()

          const regions = nonprofit.regions
            ? nonprofit.regions.filter((region: string) => {
                return (
                  region.toLocaleLowerCase().includes(searchTerm) ||
                  region.indexOf(searchTerm) > -1 ||
                  searchTerm.indexOf(region) > -1
                )
              })
            : []

          const causes = nonprofit.causeNonprofits
            ? nonprofit.causeNonprofits.filter((causeNonprofit: TCauseNonprofit) => {
                const causeName: string | undefined = causeNonprofit?.cause?.name
                return causeName
                  ? causeName.toLocaleLowerCase().includes(searchTerm) ||
                      causeName.indexOf(searchTerm) > -1 ||
                      searchTerm.indexOf(causeName) > -1
                  : false
              })
            : []

          return (
            regions.length > 0 ||
            causes.length > 0 ||
            name === searchTerm ||
            name.indexOf(searchTerm) > -1 ||
            searchTerm.indexOf(name) > -1
          )
        })
      : nonprofits

    return useNonprofits.map((nonprofit: TNonprofit) => {
      const scopeOfWork: string[] = nonprofit.regions ? nonprofit.regions.sort() : []

      const causes: Array<string | undefined> = nonprofit.causeNonprofits
        ? nonprofit.causeNonprofits
            .map((causeNonprofits: TCauseNonprofit) => causeNonprofits.cause?.name)
            .sort()
        : []
      return {
        name: {
          value: <Link to={`/admin/nonprofits/${nonprofit.id}`}>{nonprofit.name}</Link>,
          sortOn: nonprofit.name,
        },
        causes: {
          value: causes.join(', '),
          sortOn: causes[0],
        },
        scopeOfWork: {
          value: scopeOfWork.join(', '),
          sortOn: scopeOfWork[0],
        },
      }
    })
  }, [nonprofitSearchInput, nonprofits])

  if (loadingStates.user && loadingStates.user.loading) return <div>One moment please...</div>
  if (loadingStates.user && loadingStates.user.error)
    return <APIError error={loadingStates.user.error} />
  if (!user.type) return <LoginPage />
  if (['Admin', 'Super'].indexOf(user.type) === -1) return <PageNotFound />
  return (
    <div>
      <div>
        <h1>All Nonprofits</h1>
      </div>
      <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
        <NonprofitSearchBox
          onChange={setNonprofitSearchInput}
          initialValue={nonprofitSearchInput}
        />
        <BeamButton
          text="CREATE NONPROFIT"
          style={{ width: '180px', height: '50px', marginBottom: '20px' }}
          handler={() => setShowEditNonprofitModal(true)}
        />
      </div>
      <BeamTable
        headers={TABLE_HEADERS}
        rows={nonprofitRows}
        defaultSortAscending={true}
        defaultSortColumn="name"
        defaultSortDataType="string"
        loading={loadingStates.nonprofits && loadingStates.nonprofits.loading}
        pagination={true}
      />

      <NonprofitEditModal isOpen={showEditNonprofitModal} onClose={toggleModal} />
    </div>
  )
}
