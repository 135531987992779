import cx from 'classnames'
import { has } from 'lodash'
import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { Redirect } from 'react-router'

import { useBeamSelector } from '../../../hooks'
import { twoFactorLogin } from '../../../redux/thunks/authThunks'
import { BeamButton } from '../../../stories/BeamButton'
import { BeamLogo } from '../../../stories/BeamLogo'
import { BeamTextfield } from '../../../stories/BeamTextfield'
import { BeamInputChangeEvent } from '../../../stories/BeamTextfield/BeamTextfield'
import { BeamSEO } from '../BeamSEO'
import $$ from './two-factor-login-page.module.css'

interface TwoFactorLoginProps {
  match: {
    params: {
      twoFactorToken: string
      id: string
    }
  }
}

export const TwoFactorLoginPage = ({ match }: TwoFactorLoginProps) => {
  const [authCode, setAuthCode] = useState<string>('')
  const [firstLoad, setFirstLoad] = useState<boolean>(true)
  const { user, loadingStates }: { user: any; loadingStates: any } = useBeamSelector(
    (state: any) => ({
      user: state.user,
      loadingStates: state.loadingStates,
    })
  )
  const dispatch = useDispatch()

  const {
    params: { twoFactorToken, id },
  } = match

  const captureValue: BeamInputChangeEvent = e => {
    const target = e.target as HTMLInputElement
    setAuthCode(target.value)
  }

  const authenticate = (event: any) => {
    event.preventDefault()
    dispatch(twoFactorLogin(twoFactorToken, id, authCode))
    setFirstLoad(false)
  }

  if (!twoFactorToken && !id) return ErrorPage()

  if (user?.type) {
    return <Redirect to={`/`} />
  }

  return (
    <div className={cx($$.twoFactorLoginPage, 'w-full h-full beam-bg--gradient-coral')}>
      <BeamSEO title={`Two Factor Authentication`} />
      <div className="flex items-center justify-center h-screen min-h-full px-4 py-12 sm:px-6 lg:px-8">
        {loadingStates?.user && loadingStates?.user?.loading ? (
          <h2 className="beam--heading--2">Logging you in...</h2>
        ) : (
          <div
            className={cx(
              $$.twoFactorLoginForm,
              'flex flex-col items-center justify-center flex-grow w-full h-full max-w-2xl p-2 space-y-6 text-center desktop:p-28 desktop:h-auto'
            )}>
            <BeamLogo alt="Beam Impact Partner Portal - Reset Password Form" />
            <form onSubmit={authenticate} className="w-full space-y-6">
              <h2 className="beam--heading--2">2 Factor Login</h2>
              <p className="beam--paragraph">Check your email for an authentication code</p>
              <div className="w-full space-y-6">
                <BeamTextfield
                  name="authCode"
                  placeholder="Authentication Code"
                  onChange={captureValue}
                  value={authCode}
                  autofocus
                />
              </div>
              <BeamButton label="Submit" variant="elevated" type="submit" />
            </form>
            {!firstLoad && has(loadingStates, 'user.error') && (
              <p className="beam--paragraph-small">
                The two factor code is incorrect or has expired. Please try again or reach out to us
                through another method.
              </p>
            )}
          </div>
        )}
      </div>
    </div>
  )
}

const ErrorPage = () => {
  return (
    <div className={cx($$.passwordResetPage, 'w-full h-full beam-bg--gradient-coral')}>
      <BeamSEO title={`Two Factor Authentication Error`} />
      <div className="flex items-center justify-center h-screen min-h-full px-4 py-12 sm:px-6 lg:px-8">
        <div
          className={cx(
            $$.passwordResetForm,
            'flex flex-col items-center justify-center flex-grow w-full h-full max-w-2xl p-2 space-y-8 text-center desktop:p-28 desktop:h-auto'
          )}>
          <BeamLogo alt="Beam Impact Partner Portal - Reset Password Form" />
          <p>
            Seems like something went wrong. Please try again or reach out to us through another
            method.
          </p>
        </div>
      </div>
    </div>
  )
}
