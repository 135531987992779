import '../../style/root/navigation.css'

import { toString } from 'lodash'
import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory, useLocation } from 'react-router'

import { REDESIGN_ROOT_PATH } from '../../helpers'
import { useBeamSelector } from '../../hooks'
import { useFeatureFlags } from '../../hooks/useFeatureFlags'
import { setSite } from '../../redux/actions'
import { fetchSites } from '../../redux/thunks/newPartnerThunks'
import { BEAM_DROPDOWN_OPTION } from '../../stories/BeamDropdown/BeamDropdown'
import { BeamLogo } from '../../stories/BeamLogo'
import { BeamSideNavigation } from '../../stories/BeamSideNavigation'
import { TSite } from '../../utils/types'
import { AdminSideNavBar } from '../admin/AdminSideNavBar'
import { PartnerSideNavBar } from '../partner/PartnerSideNavBar'

interface SideNavigationProps {
  logOut?: () => void
  showLogo?: boolean
  toggleFunction?: () => void
}

export const SideNavigation = ({ showLogo, toggleFunction }: SideNavigationProps) => {
  const location = useLocation()
  const featureFlags = useFeatureFlags()
  const dispatch = useDispatch()

  const user = useBeamSelector(({ user }) => user)
  const site: TSite = useBeamSelector(({ site }) => site)
  const sites: TSite[] = useBeamSelector(({ sites }) => sites)
  const displayListOfSites: boolean = useBeamSelector(
    ({ displayListOfSites }) => displayListOfSites
  )
  const invoices = useBeamSelector(({ invoices }) => invoices)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const params = new URLSearchParams(window.location.search)
  const history = useHistory()

  // Fetch sites list
  useEffect(() => {
    if (!featureFlags['can-see-multi-site-features']) return

    dispatch(
      fetchSites({
        chainId: user?.chainId,
      })
    )
  }, [dispatch, featureFlags, user?.chainId, sites.length, displayListOfSites])

  // Sets site state based on url param value
  useEffect(() => {
    if (!sites || !sites[0]) {
      return
    }

    const selectedSite = params.get('site')

    if (selectedSite) {
      const site = sites.find(s => s.storeId === Number(selectedSite))
      if (site) {
        dispatch(setSite(site))
        return
      }
    }

    const defaultSite = sites[0]
    if (defaultSite?.storeId) {
      params.set('site', toString(defaultSite.storeId))
      history.push({ search: params.toString() })
    } else {
      params.delete('site')
    }
    dispatch(setSite(defaultSite))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sites])

  const userSideNav = (pathname: string | null) => {
    const countInvoices = invoices.filter(
      (_i: any) => _i.paymentRequested === true && _i.isPaid === false
    ).length
    const siteDropdownOptions = generateSiteDropdownOptions(sites)

    if (user?.partner?.name) {
      return (
        <BeamSideNavigation
          pathname={pathname}
          user={user}
          site={site}
          siteDropdownOptions={siteDropdownOptions}
          toggleFunction={toggleFunction}
          showMultiSiteFeatures={
            featureFlags['can-see-multi-site-features'] && !!displayListOfSites
          }
          handleSiteChange={(_: any, storeId: number) => {
            const chosenSite =
              sites.find((s: TSite) => s?.storeId !== null && +s.storeId === storeId) ||
              sites.find((s: TSite) => s.storeId === null)
            dispatch(setSite(chosenSite))

            if (chosenSite?.storeId) {
              params.set('site', toString(chosenSite.storeId))
              history.push({ search: params.toString() })
            } else {
              params.delete('site')
              history.push({ search: params.toString() })
            }

            if (
              pathname?.includes(`${REDESIGN_ROOT_PATH}/reports`) &&
              pathname !== `${REDESIGN_ROOT_PATH}/reports`
            ) {
              // TODO: PPOR-2780 [Cleanup] Redirect the user to the Analytics page
            }
          }}
        />
      )
    }

    switch (user.type) {
      case 'Super':
      case 'Admin':
      case 'Staff':
        return (
          <AdminSideNavBar
            pathname={pathname}
            user={user}
            toggleFunction={toggleFunction}
            invoicesCount={countInvoices}
          />
        )
      case 'Executive':
        return (
          <PartnerSideNavBar
            pathname={pathname}
            user={user}
            toggleFunction={toggleFunction}
            invoicesCount={countInvoices}
          />
        )
      case 'Engineering':
        return <PartnerSideNavBar pathname={pathname} user={user} toggleFunction={toggleFunction} />
      default:
        return null
    }
  }

  if (!user.type) return null

  return (
    <nav id="large-screen-side-nav">
      <div>
        {showLogo && (
          <div className="beam-navigation--beam-logo-container">
            <div className="beam-navigation--beam-logo">
              <BeamLogo width="62px" height="32.94px" />
            </div>
          </div>
        )}
        {userSideNav(location?.pathname)}
      </div>
    </nav>
  )
}

const generateSiteDropdownOptions = (sites: TSite[]) => {
  const siteDropdownOptions: BEAM_DROPDOWN_OPTION[] =
    (sites?.map &&
      sites.map((siteOption: TSite) => {
        return {
          label: siteOption.name,
          value: toString(siteOption.storeId),
        }
      })) ||
    []

  return siteDropdownOptions
}
