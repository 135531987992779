import BeamInput, { BeamInputProps } from '../../root/BeamInput'

// Extension of BeamInput with styles specific to widget config
export const WidgetConfigInput = (props: BeamInputProps) => {
  return (
    <BeamInput
      {...props}
      labelStyle={{ fontSize: '14px' }}
      inputContainerStyle={{
        flexDirection: 'column',
        alignItems: 'flex-start',
      }}
      labelContainerStyleOverride={{ width: '100%' }}
    />
  )
}
