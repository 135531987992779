import '../../../style/admin/invoices.css'

import { useEffect } from 'react'
import { useDispatch } from 'react-redux'

import { useBeamSelector } from '../../../hooks'
import { fetchPartners } from '../../../redux/thunks/adminThunks'
import { fetchInvoices } from '../../../redux/thunks/invoiceThunks'
import { APIError } from '../../root/APIError'
import AdminInvoiceTable from './AdminInvoiceTable'

export const AdminInvoices = () => {
  // FIXME: Remove all `any` when we have typedefs
  const invoices = useBeamSelector(({ invoices }) => invoices)
  const loadingStates = useBeamSelector(({ loadingStates }) => loadingStates) as any
  const partners = useBeamSelector(({ partners }) => partners)
  const dispatch = useDispatch()

  useEffect(() => {
    if (!partners?.length) {
      dispatch(fetchPartners())
    }
    if (!invoices?.length) {
      dispatch(fetchInvoices())
    }
  }, [partners, invoices, dispatch])

  const loadingMessage = () => {
    if (loadingStates?.user?.loading) return <div>One moment please...</div>
    if (loadingStates?.invoices?.loading || loadingStates?.invoices?.error) {
      return <div>{loadingStates.invoices.message}</div>
    }
    return null
  }

  if (loadingStates?.user?.error)
    return <APIError error={loadingStates.user.error} withRedirect={true} />
  if (loadingStates?.invoices?.error)
    return <APIError error={loadingStates.invoices.error} withRedirect={true} />

  return (
    <div>
      <br />
      {/* TODO: Remove when AdminInvoiceTable is converted to TS */}
      {/* @ts-ignore */}
      {loadingMessage() || <AdminInvoiceTable invoices={invoices} partners={partners} />}
    </div>
  )
}
