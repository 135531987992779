import { Dispatch } from 'redux'

import { API_BASE_PATH_PORTAL } from '../../api/auth'
import { PartnerInfoPayload } from '../../components/admin/EditCBPartnerForm'
import { axiosRequest } from '../../utils/axiosRequest'
import { TPPPartner } from '../../utils/types'
import {
  setAllPartners,
  setCBPartner,
  setLoadingAllPartners,
  setLoadingCBPartner,
  setUser,
} from '../actions'

interface TFetchAllPartners {
  partnerPortal?: TPPPartner | null
  centralBackend?: TPPPartner | null
}

export type FetchAllPartnersResponse = TFetchAllPartners[]

// Fetches cb and pp partners together
export const fetchAllPartners = (detailed = true) => {
  return async (dispatch: Dispatch) => {
    if (!detailed) {
      dispatch(setLoadingAllPartners({ loading: true, error: null }))
    }
    try {
      const res = await axiosRequest(
        'get',
        `${API_BASE_PATH_PORTAL}/partners/all/includeCentralBackend?detailed=${
          detailed ? '1' : '0'
        }`
      )
      dispatch(setAllPartners(res.data))
      dispatch(setLoadingAllPartners({ loading: false, error: null, success: res.data }))
    } catch (error: any) {
      dispatch(setLoadingAllPartners({ loading: false, error }))
      if (error.response && [401, 403].indexOf(error.response.status) !== -1) {
        dispatch(setUser({}))
        return
      }
    }
  }
}

export const updateCentralBackendPartner = (partnerData: PartnerInfoPayload) => {
  const form = new FormData()

  for (const k in partnerData) {
    const key = k as keyof PartnerInfoPayload
    const fieldValue = partnerData[key]

    if (key === 'targetChainNonprofitInfo' || key === 'stores') {
      form.append(key, JSON.stringify(fieldValue))
      continue
    }

    // If logoImage was unchanged, it'll be a string. So skip the file storing logic.
    if (key === 'logoImage' && typeof fieldValue === 'object') {
      const logoImage = fieldValue as File
      form.append('logoImage', logoImage, logoImage.name)
      form.append(logoImage.name, 'logoImage')
      continue
    }

    if (
      fieldValue !== undefined &&
      fieldValue !== null &&
      fieldValue !== '' &&
      typeof fieldValue !== 'object'
    ) {
      form.append(key, fieldValue)
      continue
    }
  }

  const chainNonprofitImageUrls: { [key: number]: any } = {}

  partnerData.targetChainNonprofitInfo.forEach(nonprofit => {
    if (nonprofit.causeDisplayIcon && typeof nonprofit.causeDisplayIcon === 'object') {
      const causeDisplayIcon = nonprofit.causeDisplayIcon as File
      const identifierFieldName = nonprofit.nonprofitId + causeDisplayIcon.name
      form.append('chainNonprofit/causeDisplayIcon/', causeDisplayIcon, causeDisplayIcon.name)
      form.append(identifierFieldName, 'causeDisplayIcon')
    }

    if (nonprofit?.causeSelectedImage && typeof nonprofit.causeSelectedImage === 'object') {
      const causeSelectedImage = nonprofit.causeSelectedImage as File
      const identifierFieldName = nonprofit.nonprofitId + causeSelectedImage.name
      form.append('chainNonprofit/causeSelectedImage/', causeSelectedImage, causeSelectedImage.name)
      form.append(identifierFieldName, 'causeSelectedImage')
    }

    if (nonprofit?.chainImpactImage && typeof nonprofit.chainImpactImage === 'object') {
      const chainImpactImage = nonprofit.chainImpactImage as File
      const identifierFieldName = nonprofit.nonprofitId + chainImpactImage.name
      form.append('target/', chainImpactImage, chainImpactImage.name)
      form.append(identifierFieldName, 'chainImpactImage')
    }
  })

  form.append('chainNonprofitImageUrls', JSON.stringify(chainNonprofitImageUrls))

  return async (dispatch: Dispatch) => {
    dispatch(setLoadingCBPartner({ loading: true, error: null }))
    try {
      await axiosRequest(
        'put',
        `${API_BASE_PATH_PORTAL}/admin/partner/central/edit/${partnerData.id}`,
        form,
        {},
        'multipart/form-data'
      )
      const res = await axiosRequest(
        'get',
        `${API_BASE_PATH_PORTAL}/partners/central/${partnerData.id}`
      )

      dispatch(setCBPartner(res.data || null))
      dispatch(setLoadingCBPartner({ loading: false, error: null, success: res.data }))
    } catch (error) {
      dispatch(setLoadingCBPartner({ loading: false, error }))
    }
  }
}
