import type { IconLibrary } from '@shoelace-style/shoelace/dist/components/icon/library'

/**
 * https://materialui.co/icons/
 * https://github.com/material-icons/material-icons/tree/master/svg
 */
export const materialIconLibrary: IconLibrary = {
  name: 'material', // will also be set as "default"
  resolver: name => {
    // Translate names with style modifiers like android_rounded into => {iconName: android, iconStyle: rounded}
    // The default if you don't append a style is "outline"
    const match = name.match(
      /^(.*?)(_(round|sharp|twotone|baseline|outline))?$/
    ) as RegExpMatchArray
    const iconName = match[1]
    const iconStyle = match[3] || 'outline'
    // Original source: `https://cdn.jsdelivr.net/npm/@material-icons/svg@1.0.32/svg`
    const CDN_URL = `https://cdn01.beamimpact.com/icons/material-icons/svg`
    return `${CDN_URL}/${iconName}/${iconStyle}.svg`
  },
  mutator: svg => svg.setAttribute('fill', 'currentColor'),
}
