import { ReactNode } from 'react'
import { Link } from 'react-router-dom'

interface SideNavLinkProps {
  path: string
  children?: ReactNode
  active?: boolean
  itemLabel?: string
}

export const BeamSideNavigationLink = ({ path, active = false, children }: SideNavLinkProps) => {
  return (
    <Link to={path} className={active ? 'active' : ''}>
      {children}
    </Link>
  )
}
