import { useBeamSelector } from '../../hooks'
import { TUser } from '../../utils/types'
import { PartnerHome } from '../partner/PartnerHome'
import { LoginPage } from '../root/LoginPage'
import { APIError } from './APIError'

const Home = () => {
  // FIXME: Remove all `any` and typecasting when we have typedefs
  const loadingStates = useBeamSelector(({ loadingStates }) => loadingStates) as any
  const user = useBeamSelector(({ user }) => user) as TUser | undefined

  if (loadingStates.user && loadingStates.user.loading) return <div>One moment please...</div>
  if (loadingStates.user && loadingStates.user.error)
    return <APIError error={loadingStates.user.error} />

  if (!user?.type) return <LoginPage />
  if (user.type === 'Executive' || user.type === 'Engineering') return <PartnerHome />

  return (
    <div>
      <h1>Partner Portal Home</h1>
      <h3>
        Hi {user.firstName} {user.lastName}
      </h3>
    </div>
  )
}

export default Home
