import FormControl from '@material-ui/core/FormControl'
import Grid from '@material-ui/core/Grid'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import TextField from '@material-ui/core/TextField'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'

import { useBeamSelector } from '../../hooks'
import { setToast } from '../../redux/actions'
import {
  createPartner,
  fetchExternalPartners,
  fetchPaymentStructures,
} from '../../redux/thunks/adminThunks'
import { fetchUser } from '../../redux/thunks/authThunks'
import { processApiError } from '../../utils/root'
import { TPaymentStructure } from '../../utils/types'
import { APIError } from '../root/APIError'
import BeamButton from '../root/BeamButton'
import { BeamModal } from '../root/BeamModal'
import { BeamProgressBar } from '../root/BeamProgressBar'
import { PageNotFound } from '../root/PageNotFound'

export const CreatePartner = () => {
  const [partner, setPartner] = useState({
    name: '',
    launchDate: undefined,
    invoiceFirstName: '',
    invoiceLastName: '',
    invoiceEmail: '',
    sdkId: null,
    paymentStructureId: '',
  })
  const [showModal, setShowModal] = useState(false)
  const [errors, setErrors] = useState({
    id: { error: false, message: '' },
    name: { error: false, message: '' },
    launchDate: { error: false, message: '' },
    invoiceFirstName: { error: false, message: '' },
    invoiceLastName: { error: false, message: '' },
    invoiceEmail: { error: false, message: '' },
    centralBackendId: { error: false, message: '' },
    paymentStructureId: { error: false, message: '' },
  })

  // FIXME: Remove all `any` when whe have typedefs
  const user = useBeamSelector(({ user }) => user)
  const loadingStates = useBeamSelector(({ loadingStates }) => loadingStates) as any
  const externalPartners = useBeamSelector(({ externalPartners }) => externalPartners) as any
  const paymentStructures = useBeamSelector(
    ({ paymentStructures }) => paymentStructures
  ) as TPaymentStructure[]

  const dispatch = useDispatch()

  useEffect(() => {
    if (user?.type || loadingStates?.user?.loading) return

    dispatch(fetchUser())

    return () => {
      dispatch(setToast({ showToast: false, message: null, color: null }))
    }
  }, [user?.type, dispatch, loadingStates?.user])

  // Fetch external partners
  useEffect(() => {
    if (
      externalPartners.length ||
      loadingStates?.partners?.loading ||
      loadingStates?.partners?.error
    ) {
      return
    }

    dispatch(fetchExternalPartners())
  }, [dispatch, externalPartners, loadingStates?.partners])

  // Fetch payment structures
  useEffect(() => {
    if (
      paymentStructures.length ||
      loadingStates?.paymentStructures?.loading ||
      loadingStates?.paymentStructures?.error
    ) {
      return
    }

    dispatch(fetchPaymentStructures())
  }, [paymentStructures, dispatch, loadingStates?.paymentStructures])

  const handleInput = (e: any) => {
    setPartner({ ...partner, [e.target.name]: e.target.value })
    setErrors({ ...errors, [e.target.name]: { error: false, message: '' } })
  }

  const handleSubmit = (e: any) => {
    if (e) e.preventDefault()
    if (!/.+@[^@]+\.[^@]{2,}$/.test(partner.invoiceEmail)) {
      setErrors({
        ...errors,
        invoiceEmail: { error: true, message: 'A valid email is required for invoicing' },
      })
      return
    }
    if (!partner.paymentStructureId) {
      setErrors({
        ...errors,
        paymentStructureId: { error: true, message: 'A payment structure is required' },
      })
      return
    }

    setShowModal(true)
    dispatch(createPartner(partner))
  }

  if (['Super', 'Admin'].indexOf(user.type) == -1) return <PageNotFound />
  if (loadingStates?.partners?.loading) return <h2>Loading available partners...</h2>
  if (loadingStates?.paymentStructures?.loading) return <h2>Loading payment structures...</h2>
  if (loadingStates?.partners?.error) return <APIError error={loadingStates?.partners?.error} />
  if (!externalPartners) return <h2>There are no available partners</h2>

  const apiError = loadingStates?.partner?.error
  const isCreatingPartner = loadingStates?.partner?.loading

  return (
    <div>
      <h1>Add a Partner</h1>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={5}>
          <FormControl>
            <InputLabel id="name">Partner</InputLabel>
            <Select
              style={{ minWidth: '120px' }}
              labelId="name-label"
              id="nameSelect"
              required
              value={partner.name}
              defaultValue={partner.name}
              name="name"
              onChange={e => {
                const sdkId = externalPartners.filter((p: any) => p.name === e.target.value)[0].id
                setPartner({ ...partner, sdkId, name: e.target.value as string })
              }}
              error={errors.name.error}>
              {externalPartners
                ?.sort((a: any, b: any) => {
                  if (a.name.toLowerCase() < b.name.toLowerCase()) return -1
                  if (a.name.toLowerCase() > b.name.toLowerCase()) return 1
                  return 0
                })
                ?.map((partner: any) => (
                  <MenuItem key={partner.id} value={partner.name}>
                    {partner.name}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={5}>
          <TextField
            label="Invoice First Name"
            name="invoiceFirstName"
            onChange={handleInput}
            error={errors.invoiceFirstName.error}
            helperText={errors.invoiceFirstName.message}
            value={partner.invoiceFirstName}
          />
        </Grid>
        <Grid item xs={12} sm={5}>
          <TextField
            label="Invoice Last Name"
            name="invoiceLastName"
            onChange={handleInput}
            error={errors.invoiceLastName.error}
            helperText={errors.invoiceLastName.message}
            value={partner.invoiceLastName}
          />
        </Grid>
        <Grid item xs={12} sm={5}>
          <TextField
            required
            label="Invoice Email"
            name="invoiceEmail"
            onChange={handleInput}
            error={errors.invoiceEmail.error}
            helperText={errors.invoiceEmail.message}
            value={partner.invoiceEmail}
          />
        </Grid>
        {paymentStructures && (
          <Grid item xs={12} sm={5}>
            <FormControl>
              <InputLabel id="paymentStructureId">Payment Structure</InputLabel>
              <Select
                style={{ minWidth: '200px' }}
                labelId="paymentStructureId-label"
                id="paymentStructureIdSelect"
                required
                value={partner.paymentStructureId}
                defaultValue={partner.paymentStructureId}
                name="paymentStructureId"
                onChange={handleInput}
                error={errors.paymentStructureId.error}>
                {paymentStructures?.map((paymentStructure: any) => (
                  <MenuItem key={`ps-${paymentStructure.id}`} value={paymentStructure.id}>
                    {paymentStructure.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        )}
        <Grid item xs={12}>
          <BeamButton handler={e => handleSubmit(e)} text="Submit" />
        </Grid>
      </Grid>
      <BeamModal
        title={isCreatingPartner ? 'Submitting Partner...' : apiError ? 'Error' : 'Partner Created'}
        titleColor={isCreatingPartner || apiError ? '#000' : '#4caf50'}
        open={showModal}
        handleClose={() => setShowModal(false)}>
        <div>
          {apiError ? (
            processApiError(apiError)
          ) : isCreatingPartner ? (
            <BeamProgressBar />
          ) : (
            <span>&#127881;</span>
          )}
        </div>
      </BeamModal>
    </div>
  )
}
