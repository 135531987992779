import SlCheckboxElement from '@shoelace-style/shoelace/dist/components/checkbox/checkbox'
import { SlCheckbox } from '@shoelace-style/shoelace/dist/react'
import { forwardRef } from 'react'

import $$ from './beam-checkbox.module.css'

interface BeamCheckboxProps {
  /**
   * Checked state for the checkbox
   */
  checked: boolean
  /**
   * Determines if the checkbox is indeterminate. When true, if `checked` is `false` it shows the indeterminate icon (horizontal line).
   */
  indeterminate?: boolean
  /**
   * Disables interaction with the checkbox
   */
  disabled?: boolean
  /**
   * Callback triggered every time the checkbox state is updated
   */
  onChange: (e: any) => void
}

export const BeamCheckbox = forwardRef<SlCheckboxElement, BeamCheckboxProps>(function BeamCheckbox(
  { checked, indeterminate = false, disabled = false, onChange },
  ref
) {
  return (
    <SlCheckbox
      ref={ref}
      className={$$.beamCheckbox}
      checked={checked}
      indeterminate={indeterminate}
      disabled={disabled}
      onSlChange={onChange}
    />
  )
})
