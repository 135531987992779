import { useDispatch } from 'react-redux'

import { API_BASE_PATH_PORTAL } from '../api/auth'
import { updateAccessToken } from '../redux/thunks/newAuthThunks'
import { axiosRequest } from '../utils/axiosRequest'

export const useRefreshToken = () => {
  const dispatch = useDispatch()

  async function refresh() {
    const response = await axiosRequest('get', `${API_BASE_PATH_PORTAL}/users/auth/refresh`)
    const accessToken = response?.data?.accessToken || null

    dispatch(updateAccessToken(accessToken))

    return accessToken
  }

  return refresh
}
