import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Redirect } from 'react-router'

import { useBeamSelector } from '../../../hooks'
import { logOut } from '../../../redux/thunks/authThunks'
import { APIError } from './../APIError'

export const LogoutPage = () => {
  const [loggedOut, setLoggedOut] = useState(false)
  // FIXME: Remove all `any` when we have typedefs
  const loadingStates = useBeamSelector(({ loadingStates }) => loadingStates) as any
  const user = useBeamSelector(({ user }) => user)
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(logOut(user.id))
    setLoggedOut(true)
  }, [dispatch, user.id])

  if (loggedOut) return <Redirect to={`/`} />
  if (loadingStates?.user?.loading) return <h3>Logging you out...</h3>
  if (loadingStates?.user?.error) return <APIError error={loadingStates?.user?.error} />

  if (!user.type) return <Redirect to={`/`} />

  return null
}
