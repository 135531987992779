interface BeamLogoProps {
  alt?: string
  width?: string
  height?: string
}

export const BeamLogo = ({ alt = 'Beam Impact', width = '151', height = '80' }: BeamLogoProps) => {
  return (
    <img
      src={`https://beam-impact.s3.us-west-2.amazonaws.com/beam/beamlogo_943x500.png`}
      alt={alt}
      width={width}
      height={height}
    />
  )
}
