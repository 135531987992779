// TODO: Break up this component
import TextField from '@material-ui/core/TextField'
import { isNil } from 'lodash'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'

import { useBeamSelector } from '../../../hooks'
import { setToast } from '../../../redux/actions'
import { fetchPaymentStructures } from '../../../redux/thunks/adminThunks'
import styles from '../../../style/admin/partner-report.module.css'
import dropdownStyles from '../../../style/root/beam-dropdown.module.css'
import modalStyles from '../../../style/root/beam-modal.module.css'
import { htmlDateFormat } from '../../../utils/root'
import { TPaymentStructure, TPPPartner } from '../../../utils/types'
import BeamButton from '../../root/BeamButton'
import BeamDropdown from '../../root/BeamDropdown'
import { BeamModal } from '../../root/BeamModal'
import { BeamProgressBar } from '../../root/BeamProgressBar'
import { BeamSwitch } from '../../root/BeamSwitch'
import { PartnerEditableData, updatePartnerData } from './PartnerReport.api'

export interface DateRange {
  start: Date
  end: Date
}

export const PartnerReport = () => {
  const [showEditPartnerModal, setShowEditPartnerModal] = useState(false)
  const [partnerEditApiError, setPartnerEditApiError] = useState(null)
  const [submittingPartnerEditData, setSubmittingPartnerEditData] = useState(false)
  const [partnerEditsSubmitted, setPartnerEditsSubmitted] = useState(false)
  const [partnerEditData, setPartnerEditData] = useState<PartnerEditableData>()
  const [launchDateValue, setLaunchDateValue] = useState<string | undefined>(undefined)

  const dispatch = useDispatch() // FIXME: Use `useDispatch` when we have typedefs
  const partner = (useBeamSelector(({ partner }) => partner) as TPPPartner) || undefined
  const paymentStructures = useBeamSelector(
    ({ paymentStructures }) => paymentStructures
  ) as TPaymentStructure[] // FIXME: Remove typecasting when we have the typedefs

  // launchDate adjusted to the user's locale
  const localLaunchDate = useMemo(() => {
    const date = partner?.launchDate || new Date()
    const timeZoneOffset = new Date().getTimezoneOffset() * 60 * 1000
    const adjustedDate = new Date(new Date(date).getTime() + timeZoneOffset)
    return htmlDateFormat(adjustedDate)
  }, [partner?.launchDate])

  const closeToast = useCallback(() => {
    dispatch(setToast({ showToast: false, message: null, color: null }))
  }, [dispatch])

  // Fetch all the data on first load
  useEffect(() => {
    if (!paymentStructures || !paymentStructures.length) {
      dispatch(fetchPaymentStructures())
    }
    return () => closeToast()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [partner.launchDate])

  // Populate partner data after first load. Skipped if data was already populated
  useEffect(() => {
    if (isNil(partnerEditData)) {
      setPartnerEditData({
        paymentStructureId: partner?.paymentStructure?.id,
        name: partner?.name,
        invoiceFirstName: partner?.invoiceFirstName || undefined,
        invoiceLastName: partner?.invoiceLastName || undefined,
        invoiceCcEmails: partner?.invoiceCcEmails,
        inKindDonations: partner?.inKindDonations,
        invoiceEmail: partner?.invoiceEmail || undefined,
        usePpgf: !!partner?.usePpgf,
        isActive: partner?.isActive,
        liveInCbe: partner?.liveInCbe,
        onDiscovery: partner?.onDiscovery,
        donateForUnredeemed: partner?.donateForUnredeemed,
        transactionVerificationRequired: partner?.transactionVerificationRequired,
        ppgfEmail: partner?.ppgfEmail,
        invoiceMinimum: Number(partner?.invoiceMinimum || 0),
        locations: partner?.locations,
      })
    }
    if (isNil(launchDateValue)) {
      setLaunchDateValue(localLaunchDate)
    }
  }, [launchDateValue, localLaunchDate, partner, partnerEditData])

  if (!partner || !partnerEditData) return null

  const handlePartnerEditDataInput = (name: string, value: any) => {
    setPartnerEditData({ ...partnerEditData, [name]: value })
  }

  const submitPartnerEdits = () => {
    setSubmittingPartnerEditData(true)

    updatePartnerData(partner.chainId, partnerEditData)
      .then(() => {
        setPartnerEditsSubmitted(true)
        setSubmittingPartnerEditData(false)
      })
      .catch(error => {
        setPartnerEditApiError(error?.response?.data?.error || 'An unknown error occurred...')
        setSubmittingPartnerEditData(false)
      })
  }

  const handleSelectedAction = (_: string, action: string) => {
    switch (action) {
      case 'Edit Info':
        return setShowEditPartnerModal(true)
      default:
        return
    }
  }

  if (!partner) {
    return <h2>No Partner data found.</h2>
  }

  return (
    <div>
      <div className={styles['title-container']}>
        <div>
          <h1>{partner.name}</h1>
          <div className={styles['info-container']}>
            <div className="text-small">
              Launch Date:{' '}
              {partner.launchDate &&
                new Date(partner.launchDate).toLocaleDateString('en-us', {
                  timeZone: 'America/New_York',
                })}
            </div>
          </div>
        </div>
        <BeamDropdown
          placeholderDisplay="Actions"
          options={['Edit Info', 'Update Data']}
          width="175px"
          hideClearSelection={true}
          changeHandler={(name, value) => handleSelectedAction(name, value as string)}
        />
      </div>
      <BeamModal
        title={
          submittingPartnerEditData
            ? 'Submitting Your Edits...'
            : partnerEditsSubmitted
            ? 'Edits Submitted'
            : 'Editing Partner Info'
        }
        titleColor={partnerEditsSubmitted ? '#4caf50' : '#000'}
        open={showEditPartnerModal}
        handleClose={() => {
          setShowEditPartnerModal(false)
          setPartnerEditsSubmitted(false)
        }}>
        {submittingPartnerEditData ? (
          <div className={modalStyles['modal-container']}>
            <BeamProgressBar />
          </div>
        ) : partnerEditsSubmitted ? (
          <div className={modalStyles['modal-container']}>
            <div style={{ color: '#4caf50' }}>&#127881;</div>
          </div>
        ) : (
          <div className={modalStyles['modal-container']}>
            <div
              className={modalStyles['select-container']}
              style={{ justifyContent: 'space-between' }}>
              Name:
              <input
                className={dropdownStyles['beam-dropdown-placeholder']}
                value={partnerEditData?.name || ''}
                style={{ cursor: 'text', maxWidth: '287px' }}
                onChange={e => handlePartnerEditDataInput('name', e.target.value)}
              />
            </div>
            <div
              className={modalStyles['select-container']}
              style={{ justifyContent: 'space-between' }}>
              Launch Date:
              <TextField
                required
                name="launchDate"
                type="date"
                onChange={e => setLaunchDateValue(e.target.value)}
                value={launchDateValue}
              />
            </div>
            <div
              className={modalStyles['select-container']}
              style={{ justifyContent: 'space-between' }}>
              Payment Structure:{' '}
              <BeamDropdown
                options={paymentStructures?.map(p => ({ value: p.id, display: p.name })) || []}
                selectedValue={partnerEditData.paymentStructureId}
                changeHandler={(_: string, value: any) =>
                  handlePartnerEditDataInput('paymentStructureId', value)
                }
              />
            </div>
            {paymentStructures.find(p => p.id === partnerEditData?.paymentStructureId)
              ?.isLocationBased && (
              <div
                className={modalStyles['select-container']}
                style={{ justifyContent: 'space-between' }}>
                Locations:{' '}
                <BeamDropdown
                  options={Array.from({ length: 20 }).map((e, i) => i + 1)}
                  selectedValue={partnerEditData.locations}
                  changeHandler={(_: string, value: any) =>
                    handlePartnerEditDataInput('locations', +value)
                  }
                />
              </div>
            )}
            <div
              className={modalStyles['select-container']}
              style={{ justifyContent: 'space-between' }}>
              Invoice First Name:
              <input
                className={dropdownStyles['beam-dropdown-placeholder']}
                value={partnerEditData.invoiceFirstName || ''}
                style={{ cursor: 'text', maxWidth: '287px' }}
                onChange={e => handlePartnerEditDataInput('invoiceFirstName', e.target.value)}
              />
            </div>
            <div
              className={modalStyles['select-container']}
              style={{ justifyContent: 'space-between' }}>
              Invoice Last Name:
              <input
                className={dropdownStyles['beam-dropdown-placeholder']}
                value={partnerEditData.invoiceLastName || ''}
                style={{ cursor: 'text', maxWidth: '287px' }}
                onChange={e => handlePartnerEditDataInput('invoiceLastName', e.target.value)}
              />
            </div>
            <div
              className={modalStyles['select-container']}
              style={{ justifyContent: 'space-between' }}>
              Invoice Email:
              <input
                className={dropdownStyles['beam-dropdown-placeholder']}
                value={partnerEditData.invoiceEmail || ''}
                style={{ cursor: 'text', maxWidth: '287px' }}
                onChange={e => handlePartnerEditDataInput('invoiceEmail', e.target.value)}
              />
            </div>
            <div
              className={modalStyles['select-container']}
              style={{ position: 'relative', justifyContent: 'space-between' }}>
              Invoice CC Emails*:
              <input
                className={dropdownStyles['beam-dropdown-placeholder']}
                value={
                  partnerEditData.invoiceCcEmails && partnerEditData.invoiceCcEmails.length
                    ? partnerEditData.invoiceCcEmails.join(',')
                    : ''
                }
                style={{ cursor: 'text', maxWidth: '287px' }}
                onChange={e =>
                  handlePartnerEditDataInput('invoiceCcEmails', e.target.value.split(','))
                }
              />
              <div style={{ position: 'absolute', top: '110%', left: '0', fontSize: '12px' }}>
                *Comma separated
              </div>
            </div>
            <br />
            <div
              className={modalStyles['select-container']}
              style={{ position: 'relative', justifyContent: 'space-between' }}>
              PPGF Email:
              <input
                className={dropdownStyles['beam-dropdown-placeholder']}
                value={partnerEditData.ppgfEmail || ''}
                style={{ cursor: 'text', maxWidth: '287px' }}
                onChange={e => handlePartnerEditDataInput('ppgfEmail', e.target.value)}
              />
            </div>
            <div
              className={modalStyles['select-container']}
              style={{ justifyContent: 'space-between' }}>
              <BeamSwitch
                on={partnerEditData.inKindDonations}
                handleClick={() => {
                  setPartnerEditData({
                    ...partnerEditData,
                    inKindDonations: !partnerEditData.inKindDonations,
                  })
                }}
                label="In Kind Donations"
              />
            </div>
            <div
              className={modalStyles['select-container']}
              style={{ justifyContent: 'space-between' }}>
              <BeamSwitch
                on={partnerEditData.usePpgf}
                handleClick={() => {
                  setPartnerEditData({
                    ...partnerEditData,
                    usePpgf: !partnerEditData.usePpgf,
                  })
                }}
                label="Enable PPGF"
              />
            </div>
            <div
              className={modalStyles['select-container']}
              style={{ justifyContent: 'space-between' }}>
              <BeamSwitch
                on={partnerEditData.donateForUnredeemed}
                handleClick={() =>
                  setPartnerEditData({
                    ...partnerEditData,
                    donateForUnredeemed: !partnerEditData.donateForUnredeemed,
                  })
                }
                label="Donate For Unredeemed"
              />
            </div>
            <div
              className={modalStyles['select-container']}
              style={{ justifyContent: 'space-between' }}>
              <BeamSwitch
                on={partnerEditData.isActive}
                handleClick={() =>
                  setPartnerEditData({
                    ...partnerEditData,
                    isActive: !partnerEditData.isActive,
                  })
                }
                label="Active"
              />
            </div>
            {partnerEditApiError && <div className={modalStyles.error}>{partnerEditApiError}</div>}
            <BeamButton text="SUBMIT" handler={submitPartnerEdits} />
          </div>
        )}
      </BeamModal>
    </div>
  )
}
