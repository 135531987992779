export type WidgetSupportedLanguages = 'en' | 'fr' | 'de' | 'es' | 'it' | 'pl'

export const widgetSupportedLanguageSelections: { [key: string]: WidgetSupportedLanguages } = {
  English: 'en',
  French: 'fr',
  German: 'de',
  Spanish: 'es',
  Italian: 'it',
  Polish: 'pl',
}
