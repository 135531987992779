import Grid from '@material-ui/core/Grid'
import { CSSProperties, useRef } from 'react'

import formStyles from '../../style/root/beam-form-fields.module.css'
import BeamButton from './BeamButton'

const BeamFilePicker = ({
  file,
  name,
  label,
  emitHandler,
  inputStyle,
}: {
  file?: string
  name: string
  label: string
  emitHandler: (name: string, value: any) => void
  inputStyle?: CSSProperties
}) => {
  const filePickerRef = useRef<HTMLInputElement>(null)
  const textDisplayRef = useRef<HTMLDivElement>(null)
  const handleButtonClicked = () => {
    filePickerRef?.current?.click()
  }
  const handleFileUpload = (e: any) => {
    const file = e?.target?.files[0]
    const fileName = file?.name
    if (textDisplayRef && textDisplayRef.current) {
      textDisplayRef.current.innerText = fileName
    }
    emitHandler(name, file)
  }
  return (
    <div className={formStyles['beam-form-field-container']}>
      <Grid container spacing={1} style={{ alignItems: 'center', justifyContent: 'space-between' }}>
        <Grid item xs={5} sm={3}>
          <div style={{ minWidth: '100px' }}>{label}:</div>
        </Grid>
        <Grid item xs={7} sm={9} style={{ justifyContent: 'flex-end', ...inputStyle }}>
          <Grid container spacing={1} style={{ alignItems: 'center' }}>
            <Grid item xs={12} sm={8} md={9}>
              <div
                className={formStyles['beam-input']}
                style={{ width: '100%', minHeight: '30px', boxSizing: 'border-box' }}
                ref={textDisplayRef}>
                {file}
              </div>
            </Grid>
            <Grid item xs={12} sm={4} md={3}>
              <input
                ref={filePickerRef}
                name={name}
                type="file"
                style={{ display: 'none' }}
                accept=".png, .jpg, .jpeg, .svg"
                onChange={handleFileUpload}
              />
              <BeamButton
                text="Choose File"
                style={{ height: '35px', width: '100%' }}
                handler={handleButtonClicked}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  )
}
export default BeamFilePicker
