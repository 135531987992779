import { combineReducers } from '@reduxjs/toolkit'

import {
  accessToken,
  allPartners,
  apiKeys,
  causes,
  cbPartner,
  displayListOfSites,
  externalPartners,
  impact,
  invoices,
  loadingStates,
  newApiKey,
  nonprofit,
  nonprofits,
  partner,
  partners,
  paymentStructures,
  reportsV2,
  site,
  sites,
  toasts,
  user,
} from './reducers'

export const rootReducer = combineReducers({
  accessToken,
  allPartners,
  apiKeys,
  causes,
  cbPartner,
  externalPartners,
  impact,
  invoices,
  loadingStates,
  newApiKey,
  nonprofit,
  nonprofits,
  partner,
  partners,
  paymentStructures,
  reportsV2,
  site,
  sites,
  displayListOfSites,
  toasts,
  user,
})
