import { PartnerUploadBody } from '../../../utils/types'

type ActionTypes =
  | { type: 'loading' }
  | { type: 'end_loading' }
  | {
      type: 'data_loaded'
      partnerUploads: PartnerUploadBody[]
      errorMessage?: string | null
    }
  | {
      type: 'error'
      errorMessage: string | null
      loading?: boolean
    }

interface AdminPartnerUploadsState {
  partnerUploads: PartnerUploadBody[]
  loading: boolean
  errorMessage: string | null
}

export const adminPartnerUploadsInitialState: AdminPartnerUploadsState = {
  partnerUploads: [],
  loading: false,
  errorMessage: null,
}

export function adminPartnerUploadsReducer(
  state: AdminPartnerUploadsState,
  action: ActionTypes
): AdminPartnerUploadsState {
  switch (action.type) {
    case 'loading': {
      return {
        ...state,
        loading: true,
        errorMessage: null,
      }
    }
    case 'end_loading': {
      return {
        ...state,
        loading: false,
      }
    }
    case 'data_loaded': {
      return {
        ...state,
        partnerUploads: action.partnerUploads,
        loading: false,
        errorMessage: action.errorMessage || null,
      }
    }
    case 'error': {
      return {
        ...state,
        errorMessage: action.errorMessage,
        loading: action.loading || state.loading,
      }
    }
  }
}
