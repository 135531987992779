import * as Sentry from '@sentry/react'
import { registerIconLibrary } from '@shoelace-style/shoelace/dist/utilities/icon-library.js'

import { API_BASE_PATH_PORTAL } from './api/auth'
import { materialIconLibrary } from './icons/shoelaceMaterialUI'
import { customSystemLibrary } from './icons/shoelaceSystem'

/**
 * Sets up third-party libraries that require configuration outside the React tree
 */
export function setupLibraries() {
  /**
   * Shoelace Icon Sets
   */
  registerIconLibrary('system', customSystemLibrary)
  registerIconLibrary('material', materialIconLibrary)
  registerIconLibrary('default', materialIconLibrary) // used when library is not specified in SlIcon
  /**
   * Sentry
   */
  const isSentryEnabled = !!process.env.REACT_APP_BEAM_ENV
  if (isSentryEnabled) {
    const sentryEnv = process.env.REACT_APP_BEAM_ENV || 'local'
    const sentryTraceSampleRate = parseFloat(process.env.REACT_APP_SENTRY_TRACE_SAMPLE_RATE || '0')
    const sentryDsn =
      process.env.REACT_APP_SENTRY_DSN ||
      'https://30b89b7f16464d9a8910e5fb4ed12c0f@o4504675968745472.ingest.sentry.io/4504697977176064'
    Sentry.init({
      environment: sentryEnv,
      dsn: sentryDsn,
      integrations: [
        new Sentry.BrowserTracing({
          // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
          tracePropagationTargets: [API_BASE_PATH_PORTAL],
        }),
        //new Sentry.Replay(),
      ],
      // Performance Monitoring
      tracesSampleRate: sentryTraceSampleRate, // Range 0-1.0, should be low (less than 1% => 0.001) in production
      // Session Replay
      replaysSessionSampleRate: 0, // This sets the sample rate at 0%. You may want to change it to 00% while in development and then sample at a lower rate in production.
      replaysOnErrorSampleRate: 0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    })
  }
}
