import { Grid } from '@material-ui/core'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { fetchPaymentStructures } from '../../redux/thunks/adminThunks'
import { processApiError } from '../../utils/root'
import { APIError } from '../root/APIError'
import BeamButton from '../root/BeamButton'
import BeamDropdown from '../root/BeamDropdown'
import BeamForm from '../root/BeamForm'
import BeamInput from '../root/BeamInput'
import { BeamModal } from '../root/BeamModal'
import { BeamProgressBar } from '../root/BeamProgressBar'
import { PageNotFound } from '../root/PageNotFound'
import { PortalPartner } from './CreateCentralBackendPartner'

interface EditPortalPartnerFormProps {
  formTitle: string
  partnerData: PortalPartner
  showSubmit?: boolean
  setPartnerData: (partnerData: PortalPartner) => void
  submitHandler?: (formData: PortalPartner) => void
}

/**
 * Reusable form component for editing partner portal partner info.
 */
export const EditPortalPartnerForm = ({
  formTitle,
  partnerData,
  setPartnerData,
  submitHandler,
  showSubmit = true,
}: EditPortalPartnerFormProps) => {
  const dispatch = useDispatch()

  const {
    paymentStructures,
    loadingStates,
    user,
  }: { paymentStructures: any[]; loadingStates: any; user: any } = useSelector((state: any) => ({
    paymentStructures: state.paymentStructures,
    loadingStates: state.loadingStates,
    user: state.user,
  }))

  const [showModal, setShowModal] = useState<boolean>(false)

  useEffect(() => {
    if (
      !paymentStructures.length &&
      !loadingStates?.paymentStructures?.loading &&
      !loadingStates?.paymentStructures?.error
    ) {
      dispatch(fetchPaymentStructures())
    }
  }, [
    dispatch,
    loadingStates?.paymentStructures?.error,
    loadingStates?.paymentStructures?.loading,
    paymentStructures.length,
  ])

  const handleInput = (name: string, value: any) => {
    if (name === 'invoiceMinimum' && value < 0) {
      return
    }

    setPartnerData({ ...partnerData, [name]: value })
  }

  if (['Super', 'Admin'].indexOf(user.type) == -1) return <PageNotFound />

  if (loadingStates?.paymentStructures?.loading) return <div>One moment please...</div>

  if (loadingStates?.paymentStructures?.error)
    return <APIError error={loadingStates.paymentStructures.error} />

  const formFields = [
    {
      name: 'invoiceFirstName',
      label: 'Invoice First Name',
      type: 'text',
      value: partnerData.invoiceFirstName,
    },
    {
      name: 'invoiceLastName',
      label: 'Invoice Last Name',
      type: 'text',
      value: partnerData.invoiceLastName,
    },
    {
      name: 'invoiceEmail',
      label: 'Invoice Email',
      type: 'text',
      value: partnerData.invoiceEmail,
    },
  ]

  const isLoadingPartner = loadingStates?.partner?.loading
  const apiError = loadingStates?.partner?.error

  return (
    <div>
      <h1>{formTitle}</h1>

      <BeamForm fields={formFields} globalChangeHandler={handleInput} />
      <Grid container spacing={1} style={{ alignItems: 'center', justifyContent: 'space-between' }}>
        <Grid item xs={5} sm={3}>
          <div>Payment Structure:</div>
        </Grid>
        <Grid item xs={7} sm={9}>
          <BeamDropdown
            options={
              paymentStructures
                ? paymentStructures.map(p => ({ value: p.id, display: p.name }))
                : []
            }
            selectedValue={partnerData.paymentStructureId}
            changeHandler={(_, value) => handleInput('paymentStructureId', value)}
          />
        </Grid>
      </Grid>

      <div className={'mt-5'}>
        <BeamInput
          label={'Minimum ($)'}
          name={'invoiceMinimum'}
          type={'number'}
          value={partnerData.invoiceMinimum}
          changeHandler={handleInput}
          numberOptions={{ min: 0 }}
        />
      </div>

      {showSubmit && submitHandler && (
        <div>
          <BeamButton
            text="SUBMIT"
            handler={() => {
              submitHandler(partnerData)
            }}
          />
        </div>
      )}
      {showSubmit && (
        <BeamModal
          title={isLoadingPartner ? 'Processing...' : apiError ? 'Error' : 'Success!'}
          titleColor={isLoadingPartner || apiError ? '#000' : '#4caf50'}
          open={showModal}
          handleClose={() => setShowModal(false)}>
          <div>
            {apiError ? (
              processApiError(apiError)
            ) : isLoadingPartner ? (
              <BeamProgressBar />
            ) : (
              <span>&#127881;</span>
            )}
          </div>
        </BeamModal>
      )}
    </div>
  )
}

export default EditPortalPartnerForm
