import { useEffect, useRef } from 'react'
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux'

import type { AppDispatch, RootStore } from './newStore'

// Use throughout the app instead of plain `useDispatch` and `useSelector`
export const useBeamDispatch: () => AppDispatch = useDispatch
export const useBeamSelector: TypedUseSelectorHook<RootStore> = useSelector

// sauce -> https://usehooks.com/usePrevious/
export function usePrevious(value: any) {
  const ref = useRef()
  useEffect(() => {
    ref.current = value
  }, [value])
  return ref.current
}
