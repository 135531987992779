import axios, { Method } from 'axios'

import store from '../store'

export const axiosInstance = axios.create({
  headers: { 'Content-Type': 'application/json' },
  withCredentials: true,
})

export const axiosRequest = (
  method: Method,
  url: string | undefined,
  data: any = {},
  params: any = {},
  contentType = 'application/json'
) => {
  const accessToken = store.getState().accessToken

  return axiosInstance({
    method,
    url,
    data,
    params,
    headers: { Authorization: accessToken || '', 'content-type': contentType },
    withCredentials: true,
  })
}
