import { RowSelectionState } from '@tanstack/react-table'
import { useState } from 'react'

import { BeamButton } from '../../../stories/BeamButton'
import { BeamLoadingIndicator } from '../../../stories/BeamLoadingIndicator'
import { BeamModal } from '../../../stories/BeamModal'
import { BeamTextfield } from '../../../stories/BeamTextfield'
import { BeamToast } from '../../../stories/BeamToast'
import { AdminDiscountsBody } from '../../../utils/types'
import { deleteDiscount } from './AdminDiscountsPage.api'

interface DeleteDiscountModalProps {
  isOpen: boolean
  selectedRows: RowSelectionState
  nonDeletedDiscounts: AdminDiscountsBody[]
  closeModal: () => void
  refreshTableData: () => Promise<void>
}

export const DeleteDiscountModal = ({
  isOpen,
  nonDeletedDiscounts,
  selectedRows,
  closeModal,
  refreshTableData,
}: DeleteDiscountModalProps) => {
  const selectedDiscounts = nonDeletedDiscounts.filter((_discount, index) => {
    return !!selectedRows[index]
  })
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState('')
  const [successMessage, setSuccessMessage] = useState('')
  const [deletionReason, setDeletionReason] = useState('')

  function handleSubmit() {
    if (!deletionReason) {
      return setError('"Reason for deletion" is required.')
    }

    setLoading(true)
    setError('')

    const discountIds = selectedDiscounts.map(d => d.discountId)

    deleteDiscount(discountIds, deletionReason)
      .then(response => {
        if (response) {
          setLoading(false)
          setSuccessMessage('Discounts Deleted!')
          setError('')
          refreshTableData()
          closeModal()
        }
      })
      .catch(e => {
        setLoading(false)
        setError(e.message)
      })
  }

  return (
    <>
      <BeamToast
        variant={'error'}
        name={'Error Deleting Discount'}
        open={!!error}
        text={error}
        onClose={() => setError('')}
      />
      <BeamToast
        variant={'success'}
        name={'Successfully Deleted Discounts'}
        open={!!successMessage}
        text={successMessage}
        onClose={() => setSuccessMessage('')}
      />

      <BeamModal
        open={isOpen}
        label={'Delete Discount'}
        onCloseCallback={closeModal}
        disableClickOutside={loading}
        disableClose={loading}
        body={
          loading ? (
            <div className={'min-h-[400px] flex justify-center items-center'}>
              <BeamLoadingIndicator />
            </div>
          ) : (
            <div className={'overflow-scroll max-h-[500px] pb-8'}>
              <p className={'text-center pt-4 pb-4'}>
                Are you sure you want to delete this discount from invoice(s):{' '}
              </p>

              <BeamTextfield
                name={'deletionReason'}
                label={'Reason for deletion'}
                value={deletionReason}
                onChange={e => setDeletionReason(e.target.value)}
                required={true}
              />

              <ul className={'list-disc list-inside pt-4'}>
                {selectedDiscounts.map(discountInfo => (
                  <li key={discountInfo.discountId}>{discountInfo.reportPeriodName}</li>
                ))}
              </ul>
            </div>
          )
        }
        footer={
          loading ? undefined : (
            <div slot={'footer'} className={'pt-8'}>
              <BeamButton label={'Delete Discount'} onClick={handleSubmit} />
              <BeamButton label={'Cancel'} variant={'text'} onClick={closeModal} />
            </div>
          )
        }
      />
    </>
  )
}
