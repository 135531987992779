import '../../style/root/navigation.css'

import Badge from '@material-ui/core/Badge'
import AccountCircleOutlinedIcon from '@material-ui/icons/AccountCircleOutlined'
import AssessmentOutlinedIcon from '@material-ui/icons/AssessmentOutlined'
import ExitToAppOutlinedIcon from '@material-ui/icons/ExitToAppOutlined'
import HomeOutlinedIcon from '@material-ui/icons/HomeOutlined'
import ReceiptOutlinedIcon from '@material-ui/icons/ReceiptOutlined'
import EngineeringIcon from '@mui/icons-material/Engineering'

import { TUser } from '../../utils/types'
import { SideNavLink } from '../root/SideNavLink'

interface PartnerSideNavBarProps {
  user: TUser
  pathname: string | null
  toggleFunction?: () => void
  invoicesCount?: number
}

export const PartnerSideNavBar = ({
  user,
  pathname,
  toggleFunction,
  invoicesCount,
}: PartnerSideNavBarProps) => {
  return (
    <div className="nav-list" onClick={toggleFunction} onKeyDown={toggleFunction}>
      <SideNavLink path="/" active={pathname === '/'}>
        <HomeOutlinedIcon />
        <div>Overview</div>
      </SideNavLink>
      <SideNavLink path="/partner/reports" active={pathname === '/partner/reports'}>
        <AssessmentOutlinedIcon />
        <div>Reports</div>
      </SideNavLink>
      {user.type === 'Executive' && (
        <SideNavLink path="/partner/invoices" active={pathname === '/partner/invoices'}>
          <ReceiptOutlinedIcon />
          <div>Invoices</div>
          {invoicesCount && (
            <Badge
              overlap="rectangular"
              badgeContent={invoicesCount}
              color="secondary"
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}>
              <div style={{ height: '16px', width: '20px' }} />
            </Badge>
          )}
        </SideNavLink>
      )}
      {user.type === 'Engineering' && (
        <SideNavLink path="/partner/apikeys" active={pathname === '/partner/apikeys'}>
          <EngineeringIcon />
          <div>Api Keys</div>
        </SideNavLink>
      )}
      <SideNavLink path="/profile" active={pathname === '/profile'}>
        <AccountCircleOutlinedIcon />
        <div>{user && user.firstName}</div>
      </SideNavLink>
      <SideNavLink path="/logout" active={pathname === '/logout'}>
        <ExitToAppOutlinedIcon />
        <div>Log Out</div>
      </SideNavLink>
    </div>
  )
}
