import { useEffect } from 'react'

import { PAGE_TITLE_SUFFIX } from '../../../helpers'

interface BeamSEOProps {
  /**
   * Title of document
   */
  title: string
}

/**
 * Centralized Beam SEO Component
 */
export const BeamSEO = function BeamButton({ title }: BeamSEOProps) {
  useEffect(() => {
    document.title = `${title} ${PAGE_TITLE_SUFFIX}`
  }, [title])

  return <></>
}
