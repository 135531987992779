import axios from 'axios'
import { inRange, orderBy } from 'lodash'

import { getV2ApiUrl } from '../../../api/auth'
import { axiosRequest } from '../../../utils/axiosRequest'
import { PartnerUploadBody } from '../../../utils/types'

/**
 * Returns all partnerUploads for the given chain, sorted by newest first.
 */
export async function fetchAdminPartnerUploads(chainId: number) {
  const url = `${getV2ApiUrl()}/admin/partner/${chainId}/reports/uploads/list`
  const res = await axiosRequest('GET', url)

  const data = res?.data as PartnerUploadBody[]
  const sortedData = orderBy(data, ['createdAt'], ['desc'])

  return sortedData
}

interface RequestPresignedUploadUrlParams {
  chainId: number
  fileName: string
  fileExtension: string
}

export async function requestPresignedUploadUrl({
  chainId,
  fileName,
  fileExtension,
}: RequestPresignedUploadUrlParams) {
  const url = `${getV2ApiUrl()}/admin/partner/${chainId}/reports/uploads/requestUpload`
  const reqBody = { fileName, fileExtension }
  const res = await axiosRequest('POST', url, reqBody)

  interface RequestPresignedUploadUrlResponse {
    presignedUploadUrl: string
    metaData: {
      fileName: string
      s3ObjectKey: string
      s3BucketName: string
    }
  }

  return res?.data as RequestPresignedUploadUrlResponse
}

export async function uploadFileToS3(
  presignedUploadUrl: string,
  file: File
): Promise<{ success: boolean }> {
  const formData = new FormData()
  formData.append('file', file)
  formData.append('fileName', file.name)

  // don't use "axiosRequest" because we don't want to send Beam credentials
  const res = await axios.put(presignedUploadUrl, formData, {
    headers: {
      'content-type': 'multipart/form-data',
    },
  })

  return {
    success: inRange(res.status, 200, 300),
  }
}

export async function saveNewPartnerUpload(props: {
  chainId: number
  fileName: string
  s3ObjectKey: string
  s3BucketName: string
}) {
  const { chainId, fileName, s3ObjectKey, s3BucketName } = props
  const url = `${getV2ApiUrl()}/admin/partner/${chainId}/reports/uploads/new`
  const reqBody = {
    fileName,
    s3ObjectKey,
    s3BucketName,
  }

  const res = await axiosRequest('POST', url, reqBody)

  return res?.data as PartnerUploadBody
}

export async function deletePartnerUpload(partnerUploadId: string): Promise<{ ok: boolean }> {
  const url = `${getV2ApiUrl()}/admin/reports/uploads/${partnerUploadId}/delete`
  const res = await axiosRequest('DELETE', url)

  return res?.data
}

export async function fetchPresignedDownloadUrl(
  chainId: number,
  partnerUploadId: string
): Promise<{
  presignedDownloadUrl: string
}> {
  const url = `${getV2ApiUrl()}/partner/${chainId}/partnerUploads/${partnerUploadId}/requestDownload`
  const res = await axiosRequest('GET', url)

  return res?.data
}

export async function restorePartnerUpload(partnerUploadId: string): Promise<PartnerUploadBody> {
  const res = await axiosRequest(
    'POST',
    `${getV2ApiUrl()}/admin/reports/uploads/${partnerUploadId}/restore`
  )

  return res?.data
}
