import { API_BASE_PATH_PORTAL } from '../../../api/auth'
import { axiosRequest } from '../../../utils/axiosRequest'

const KLAVIYO_BASE_URL = `${API_BASE_PATH_PORTAL}/v2/klaviyo`
export interface TKlaviyoTrigger {
  chainId: number
  createdAt: Date | string
  id: number
  isActive: boolean
  oldSdkId?: number
  percent?: number
  template: string
  updatedAt: Date | string
}
export interface TEditTriggerData {
  id?: number | null
  isActive: boolean
  percent?: number
}
export interface TNewTriggerData extends TEditTriggerData {
  triggerType: TriggerType
}
export type TriggerType = 'community_progress' | 'community_progress_restart'

export interface TChain {
  emailServiceProvider: string
  espEpiKey: string
  id: number
  name: string
}

export async function fetchEmailTriggers(
  chainId: string
): Promise<{ chain: TChain; triggers: TKlaviyoTrigger[] }> {
  const { data } = await axiosRequest('GET', `${KLAVIYO_BASE_URL}/${chainId}`)
  return { chain: data.chain, triggers: data.triggers }
}

export async function createKlaviyoTrigger(
  triggerData: TNewTriggerData & { chainId: number }
): Promise<TKlaviyoTrigger> {
  const { data } = await axiosRequest('POST', `${KLAVIYO_BASE_URL}/`, triggerData)
  return data
}

export async function sendSampleKlaviyoTriggers(chainId: string): Promise<{ ok: boolean }> {
  const { data } = await axiosRequest('POST', `${KLAVIYO_BASE_URL}/sample-triggers/${chainId}`)
  return data
}

export async function updateKlaviyoTrigger(triggerData: TNewTriggerData): Promise<TKlaviyoTrigger> {
  const isProgressTrigger = !!triggerData.percent
  const reqBody: TEditTriggerData = {
    id: triggerData.id,
    isActive: triggerData.isActive,
  }

  if (isProgressTrigger) {
    reqBody.percent = triggerData.percent
    const { data } = await axiosRequest('PUT', `${KLAVIYO_BASE_URL}/progress`, reqBody)
    return data
  }

  const { data } = await axiosRequest('PUT', `${KLAVIYO_BASE_URL}/restart`, reqBody)
  return data
}

type UpdateEspInfoData = {
  apiKey: string
  emailServiceProvider: string
}
export async function updateEspInfo(
  newData: UpdateEspInfoData,
  chainId: string
): Promise<{ ok: boolean }> {
  const { apiKey, emailServiceProvider } = newData
  const { data } = await axiosRequest('PUT', `${KLAVIYO_BASE_URL}/esp-info/${chainId}`, {
    apiKey,
    emailServiceProvider,
  })
  return data
}

export function getTriggerType(klaviyoTrigger: TKlaviyoTrigger): TriggerType {
  return klaviyoTrigger.percent === undefined ? 'community_progress_restart' : 'community_progress'
}
