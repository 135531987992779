import { SlIcon } from '@shoelace-style/shoelace/dist/react'
import type { SlInputEvent } from '@shoelace-style/shoelace/dist/react/input'
import { useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router'

import { BeamButton } from '../../../stories/BeamButton'
import { BeamDropdown } from '../../../stories/BeamDropdown'
import { BEAM_DROPDOWN_OPTION } from '../../../stories/BeamDropdown/BeamDropdown'
import { BeamLoadingIndicator } from '../../../stories/BeamLoadingIndicator'
import { BeamModal } from '../../../stories/BeamModal'
import { BeamTextfield } from '../../../stories/BeamTextfield'
import { BeamToast } from '../../../stories/BeamToast'
import { createDiscount, fetchDiscountableReportPeriods } from './AdminDiscountsPage.api'
import {
  DiscountableReportPeriod,
  DiscountCreationRequestPayload,
} from './AdminDiscountsPage.types'

interface CreateDiscountModalProps {
  isOpen: boolean
  closeModal: () => void
  refreshTableData: () => Promise<void>
}

export const CreateDiscountModal = ({
  isOpen,
  closeModal,
  refreshTableData,
}: CreateDiscountModalProps) => {
  const [discountableReportPeriods, setDiscountableReportPeriods] = useState<
    DiscountableReportPeriod[]
  >([])
  const [loading, setLoading] = useState(false)
  const [successMessage, setSuccessMessage] = useState('')
  const [error, setError] = useState<string>('')
  const [formData, setFormData] = useState<DiscountCreationRequestPayload>({
    discountPercentage: 0,
    reportPeriodIds: [],
    internalReason: '',
    externalReason: '',
  })
  const formRef = useRef<HTMLFormElement>(null)
  const { chainId }: { chainId: string } = useParams()

  // fetches discountable report periods
  useEffect(() => {
    if (!isOpen || !chainId || discountableReportPeriods.length > 0) return

    setLoading(true)

    const handleFetchDiscountableReportPeriods = async function () {
      try {
        const data = await fetchDiscountableReportPeriods(+chainId)
        setDiscountableReportPeriods(data)
        setLoading(false)
      } catch (error: any) {
        setError('There was a problem fetching discountable report periods.')
        console.error(error)
        setLoading(false)
      }
    }

    handleFetchDiscountableReportPeriods()
  }, [chainId, discountableReportPeriods.length, isOpen])

  if (!chainId) return null

  const selectMenuOptions: BEAM_DROPDOWN_OPTION[] = discountableReportPeriods.map(reportPeriod => ({
    label: reportPeriod.periodName,
    value: reportPeriod.reportPeriodId,
  }))

  function onChangeHandler(e: SlInputEvent) {
    setFormData(prevState => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }))
  }

  function onOptionSelected(e: SlInputEvent) {
    setFormData(prevState => {
      return {
        ...prevState,
        reportPeriodIds: e.target.value,
      }
    })
  }

  function onSubmit() {
    if (!chainId) return

    setLoading(true)
    setError('')

    const requestData: DiscountCreationRequestPayload = {
      discountPercentage: formData.discountPercentage / 100,
      reportPeriodIds: formData.reportPeriodIds,
      internalReason: formData.internalReason?.trim(),
      externalReason: formData.externalReason?.trim(),
    }

    createDiscount(+chainId, requestData)
      .then(() => {
        setLoading(false)
        setSuccessMessage('Discounts Successfully Created!')
        setError('')
        refreshTableData()
        closeModal()
      })
      .catch(error => {
        setLoading(false)
        setError(error.message)
      })
  }

  return (
    <>
      <BeamToast
        variant={'error'}
        name={'Error Fetching Report Periods'}
        open={!!error}
        text={error}
        onClose={() => setError('')}
      />
      <BeamToast
        variant={'success'}
        name={'Successfully Created Discounts'}
        open={!!successMessage}
        text={successMessage}
        onClose={() => setSuccessMessage('')}
      />

      <BeamModal
        open={isOpen}
        label={'Add Discount'}
        onCloseCallback={closeModal}
        disableClickOutside={true}
        disableClose={loading}
        body={
          loading ? (
            <div className={'min-h-[400px] flex justify-center items-center'}>
              <BeamLoadingIndicator />
            </div>
          ) : (
            <form
              ref={formRef}
              onSubmit={e => {
                e.preventDefault()
                onSubmit()
              }}>
              <div className={'pb-10 overflow-scroll max-h-[500px]'}>
                <p className={'text-center pt-4 pb-4'}>Lorem ipsum</p>

                <BeamTextfield
                  name={'discountPercentage'}
                  label={'Discount'}
                  onChange={onChangeHandler}
                  variant={'small'}
                  type={'number'}
                  autocomplete={'off'}
                  min={0}
                  max={100}
                  required={true}>
                  <SlIcon name="percent" slot="suffix" />
                </BeamTextfield>
                <BeamDropdown
                  name={'reportPeriods'}
                  label={'Invoices Affected by Discount'}
                  options={selectMenuOptions}
                  multiple={true}
                  clearable={true}
                  required={true}
                  onChange={onOptionSelected}
                />
                <BeamTextfield
                  label={'Internal Reason for Discount'}
                  name={'internalReason'}
                  variant={'small'}
                  type={'text'}
                  onChange={onChangeHandler}
                  autocomplete={'off'}
                  required={true}
                />
                <BeamTextfield
                  label={'External Reason for Discount'}
                  name={'externalReason'}
                  variant={'small'}
                  type={'text'}
                  onChange={onChangeHandler}
                  autocomplete={'off'}
                  helptext={`Copy added for “External Reason” will appear in a client’s Beam invoice as a separate line item for the discount amount`}
                />
              </div>
            </form>
          )
        }
        footer={
          loading ? undefined : (
            <div slot={'footer'} className={'pt-8'}>
              <BeamButton
                label={'Submit Discount'}
                type={'submit'}
                onClick={() => formRef.current?.requestSubmit()}
              />
              <BeamButton label={'Cancel'} type={'button'} variant={'text'} onClick={closeModal} />
            </div>
          )
        }
      />
    </>
  )
}
