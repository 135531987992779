import {
  ADD_NONPROFIT,
  SET_ACCESS_TOKEN,
  SET_ALL_PARTNERS,
  SET_API_KEYS,
  SET_CAUSES,
  SET_CB_PARTNER,
  SET_DISPLAY_LIST_OF_SITES,
  SET_EXTERNAL_PARTNERS,
  SET_IMPACT,
  SET_INVOICES,
  SET_LOADING_ALL_PARTNERS,
  SET_LOADING_API_KEYS,
  SET_LOADING_CAUSES,
  SET_LOADING_CB_PARTNER,
  SET_LOADING_IMPACT,
  SET_LOADING_INVOICES,
  SET_LOADING_NONPROFIT,
  SET_LOADING_NONPROFITS,
  SET_LOADING_PARTNER,
  SET_LOADING_PARTNERS,
  SET_LOADING_PAYMENT_STRUCTURES,
  SET_LOADING_REPORT,
  SET_LOADING_SITES,
  SET_LOADING_SUPPORT_EMAIL,
  SET_LOADING_USER,
  SET_NEW_API_KEY,
  SET_NONPROFIT,
  SET_NONPROFITS,
  SET_PARTNER,
  SET_PARTNERS,
  SET_PAYMENT_STRUCTURES,
  SET_REPORT,
  SET_REPORT_V2,
  SET_SITE,
  SET_SITES,
  SET_TOAST,
  SET_USER,
} from './constants'

export const setAccessToken = value => {
  return {
    type: SET_ACCESS_TOKEN,
    value,
  }
}

export const setLoadingUser = value => {
  return {
    type: SET_LOADING_USER,
    value,
  }
}

export const setUser = user => {
  return {
    type: SET_USER,
    user,
  }
}

export const setLoadingSites = value => {
  return {
    type: SET_LOADING_SITES,
    value,
  }
}

export const setSite = site => {
  return {
    type: SET_SITE,
    site,
  }
}

export const setSites = sites => {
  return {
    type: SET_SITES,
    sites,
  }
}

export const setDisplayListOfSites = value => {
  return {
    type: SET_DISPLAY_LIST_OF_SITES,
    value,
  }
}

export const setNonprofits = nonprofits => {
  return {
    type: SET_NONPROFITS,
    nonprofits,
  }
}

export const setLoadingNonprofits = value => {
  return {
    type: SET_LOADING_NONPROFITS,
    value,
  }
}

export const setCauses = causes => {
  return {
    type: SET_CAUSES,
    causes,
  }
}

export const setLoadingCauses = value => {
  return {
    type: SET_LOADING_CAUSES,
    value,
  }
}

export const setPartners = partners => {
  return {
    type: SET_PARTNERS,
    partners,
  }
}

export const setAllPartners = allPartners => {
  return {
    type: SET_ALL_PARTNERS,
    allPartners,
  }
}

export const setExternalPartners = externalPartners => {
  return { type: SET_EXTERNAL_PARTNERS, externalPartners }
}

export const setPartner = partner => {
  return {
    type: SET_PARTNER,
    partner,
  }
}

export const setCBPartner = cbPartner => {
  return {
    type: SET_CB_PARTNER,
    cbPartner,
  }
}

export const setLoadingPartner = value => {
  return {
    type: SET_LOADING_PARTNER,
    value,
  }
}

export const setLoadingAllPartners = value => {
  return {
    type: SET_LOADING_ALL_PARTNERS,
    value,
  }
}

export const setLoadingCBPartner = value => {
  return {
    type: SET_LOADING_CB_PARTNER,
    value,
  }
}

export const setNonprofit = nonprofit => {
  return {
    type: SET_NONPROFIT,
    nonprofit,
  }
}

export const addNonprofit = nonprofit => {
  return {
    type: ADD_NONPROFIT,
    nonprofit,
  }
}

export const setLoadingNonprofit = value => {
  return {
    type: SET_LOADING_NONPROFIT,
    value,
  }
}

export const setLoadingPartners = value => {
  return {
    type: SET_LOADING_PARTNERS,
    value,
  }
}

export const setInvoices = invoices => {
  return {
    type: SET_INVOICES,
    invoices,
  }
}

export const setLoadingInvoices = value => {
  return {
    type: SET_LOADING_INVOICES,
    value,
  }
}

export const setLoadingReport = value => {
  return {
    type: SET_LOADING_REPORT,
    value,
  }
}

export const setReport = ({ partnerId, report, reportType }) => {
  return {
    type: SET_REPORT,
    partnerId,
    report,
    reportType,
  }
}

export const setReportV2 = ({ partnerId, report, reportType, index }) => {
  return {
    type: SET_REPORT_V2,
    partnerId,
    report,
    reportType,
    index,
  }
}

export const setImpact = impact => {
  return {
    type: SET_IMPACT,
    impact,
  }
}

export const setLoadingImpact = value => {
  return {
    type: SET_LOADING_IMPACT,
    value,
  }
}
export const setLoadingSupportEmail = value => {
  return {
    type: SET_LOADING_SUPPORT_EMAIL,
    value,
  }
}
export const setToast = toast => {
  return {
    type: SET_TOAST,
    toast,
  }
}

export const setLoadingPaymentStructures = value => {
  return {
    type: SET_LOADING_PAYMENT_STRUCTURES,
    value,
  }
}

export const setPaymentStructures = paymentStructures => {
  return {
    type: SET_PAYMENT_STRUCTURES,
    paymentStructures,
  }
}

export const setLoadingApiKyes = value => {
  return {
    type: SET_LOADING_API_KEYS,
    value,
  }
}

export const setApiKeys = apiKeys => {
  return { type: SET_API_KEYS, apiKeys }
}

export const setNewApiKey = newApiKey => {
  return { type: SET_NEW_API_KEY, newApiKey }
}
