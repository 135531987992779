import '../../style/root/navigation.css'

import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import Divider from '@material-ui/core/Divider'
import Drawer from '@material-ui/core/Drawer'
import IconButton from '@material-ui/core/IconButton'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import MenuRoundedIcon from '@material-ui/icons/MenuRounded'
import { useState } from 'react'

import { useBeamSelector } from '../../hooks'
import { BeamLogo } from '../../stories/BeamLogo'
import { SideNavigation } from './SideNavigation'

export const TopNavigation = () => {
  const [open, setOpen] = useState(false)
  const user = useBeamSelector(({ user }) => user)

  const toggleDrawer = () => setOpen(!open)

  if (!user.type) return null

  return (
    <ClickAwayListener onClickAway={() => setOpen(false)}>
      <div id="top-nav-container">
        <div id="top-nav-bar">
          <IconButton edge="start" color="inherit" aria-label="menu" onClick={toggleDrawer}>
            <MenuRoundedIcon />
          </IconButton>
          <BeamLogo width="60px" height="60px" />
        </div>
        <Drawer variant="persistent" anchor="left" open={open} id="nav-drawer">
          <div id="nav-drawer-header">
            <IconButton onClick={toggleDrawer}>
              <ChevronLeftIcon />
            </IconButton>
          </div>
          <Divider />
          <SideNavigation showLogo={true} toggleFunction={() => toggleDrawer()} />
        </Drawer>
      </div>
    </ClickAwayListener>
  )
}
