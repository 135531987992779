import React from 'react'

import BeamInput from './BeamInput'

export type BeamInputValue = string | string[] | number | boolean
const BeamForm = ({
  fields,
  globalInputStyle,
  globalChangeHandler,
}: {
  fields: Array<{
    label: string
    name: string
    type: string
    changeHandler?: (name: string, value: any) => void
    value?: BeamInputValue
    detailText?: string
    inputError?: boolean
    errorText?: string
    file?: string
  }>
  globalInputStyle?: { [index: string]: string }
  globalChangeHandler: (name: string, value: any) => void
}) => (
  <div style={{ width: '100%' }}>
    {fields.map(
      ({ label, name, type, changeHandler, value, detailText, file, inputError, errorText }, i) => (
        <BeamInput
          key={`beam-input-${i}`}
          label={label}
          name={name}
          changeHandler={changeHandler || globalChangeHandler}
          type={type}
          inputStyle={globalInputStyle || {}}
          value={value}
          detailText={detailText}
          inputError={inputError}
          errorText={errorText}
          file={file}
        />
      )
    )}
  </div>
)
export default BeamForm
