import SlDropdownElement from '@shoelace-style/shoelace/dist/components/dropdown/dropdown'
import SlButton from '@shoelace-style/shoelace/dist/react/button'
import SlDropdown from '@shoelace-style/shoelace/dist/react/dropdown'
import SlMenu from '@shoelace-style/shoelace/dist/react/menu'
import { forwardRef } from 'react'

import $$ from './beam-true-dropdown-menu.module.css'

interface BeamTrueDropdownMenuProps {
  /**
   * The dropdown display label
   */
  label: string
  /**
   * The contents of the menu. Typically include SlMenuItem, SlMenuLabel or SlDivider.
   * https://shoelace.style/components/menu
   */
  menuContents?: React.ReactNode
  /**
   * Set `hoist` if the dropdown is cut off by a parent container
   * or misaligned/detached from the input box. This makes the dropdown
   * use fixed positioning instead of absolute.
   * See https://shoelace.style/components/dropdown?id=hoisting
   */
  hoist?: boolean
  /**
   * By default, the dropdown is closed when an item is selected.
   * This attribute will keep it open instead.
   * Useful for dropdowns that allow for multiple interactions.
   */
  stayOpenOnSelect?: boolean
  /**
   * Disable interaction on the dropdown
   */
  disabled?: boolean
}

export const BeamTrueDropdownMenu = forwardRef<SlDropdownElement, BeamTrueDropdownMenuProps>(
  function BeamTrueDropdownMenu(
    {
      label,

      hoist = false,
      stayOpenOnSelect = false,
      disabled = false,
      menuContents,
    },
    ref
  ) {
    return (
      <SlDropdown
        className={'beamTrueDropdown'}
        disabled={disabled}
        hoist={hoist}
        ref={ref}
        stayOpenOnSelect={stayOpenOnSelect}>
        <SlButton slot="trigger" className={$$.beamTrueDropdownButton} caret>
          {label}
        </SlButton>

        <SlMenu>{menuContents}</SlMenu>
      </SlDropdown>
    )
  }
)
