import { useMemo } from 'react'

import { useBeamSelector } from '../hooks'
import { TUser } from '../utils/types'

export enum FeatureFlags {
  'partner-uploads' = 'partner-uploads',
  'old-ach-details-message' = 'old-ach-details-message',
  'can-see-multi-site-features' = 'can-see-multi-site-features',
  'can-use-new-promo-ui' = 'can-use-new-promo-ui',
}

/**
 * @example
 *
 * const featureFlags = useFeatureFlags()
 *
 * if (featureFlags['partner-uploads']) {
 *   return <ComponentIncludingPartnerUploadsFeature />
 * }
 *
 * return <ComponentExcludingPartnerUploadsFeature />
 */
export const useFeatureFlags = () => {
  const user: TUser = useBeamSelector(({ user }) => user)

  const featureFlagKeyToStatusMap = useMemo(() => {
    const userFeatureFlags = user.featureFlags || []

    return userFeatureFlags.reduce((prev, curr) => {
      return { ...prev, [curr.key]: curr.active }
    }, {} as Record<FeatureFlags, boolean>)
  }, [user.featureFlags])

  return featureFlagKeyToStatusMap
}
