import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { fetchCentralBackendPartner } from '../../../redux/thunks/adminThunks'
import { WidgetSupportedLanguages } from '../../../utils/types/WidgetLanguages'
import { WidgetName } from '../../../utils/types/WidgetNames'
import { AuthenticatedWidgetWithForm } from './AuthenticatedWidgetWithForm'

function getBaseUrl() {
  const currentLocation = window.location.href
  // dev or vercel preview builds
  if (currentLocation.includes('dev') || currentLocation.includes('partner-portal-web-git')) {
    return 'https://dev-central-backend-ne.beamimpact.com'
  } else if (currentLocation.includes('staging')) {
    return 'https://staging-central-backend-ne.beamimpact.com'
  } else if (currentLocation.includes('localhost')) {
    return 'http://localhost:8081/mocks' // beam-web-mono repo
  } else {
    return 'https://production-central-backend-ne.beamimpact.com'
  }
}
const prodRegex = /^partner-portal.beamimpact.com$/
// Generic user ID for the impact-overview widget. At this time we can't use env vars in deployments.
const genericUserId = prodRegex.test(window.location.hostname)
  ? 'c56a0a94-53e5-4991-93de-6d49a26f5280' // prod user
  : 'CnzVU3B3MH0TASkuDSfrHQCcX'

// Renders widget web components given the name
export const RenderWidget = ({
  widgetName,
  chainId,
  lang = 'en',
}: {
  widgetName?: WidgetName
  chainId: string
  lang?: WidgetSupportedLanguages
}): JSX.Element | null => {
  const cbPartner = useSelector((state: any) => state.cbPartner)
  const loadingStates = useSelector((state: any) => state.loadingStates)
  const dispatch = useDispatch()

  useEffect(() => {
    if (
      chainId &&
      !cbPartner?.id &&
      !loadingStates?.cbPartner?.loading &&
      !loadingStates?.cbPartner?.error
    ) {
      dispatch(fetchCentralBackendPartner(chainId))
    }
  }, [cbPartner?.id, chainId, dispatch, loadingStates?.cbPartner])

  const transactionId = '123'

  const email = 'admin_portal_remote_config_email@beamimpact.com'

  const subscriptionId = 'admin_portal_123'

  const storeId = cbPartner?.stores?.length ? cbPartner?.stores[0].id : '123'
  const nonprofitId =
    cbPartner?.stores?.length && cbPartner?.stores[0].nonprofits.length
      ? cbPartner?.stores[0].nonprofits[0].id
      : ''

  const baseUrl = getBaseUrl()

  if (!chainId || !cbPartner) return null

  // XXX - use a non falsy value in apiKey to retain previous logic that allows widgets that
  // don't use authenticated endpoints to still render
  switch (widgetName) {
    case 'community-impact':
      return (
        <AuthenticatedWidgetWithForm>
          <beam-community-impact
            chainId={chainId}
            cardStyle="image"
            baseUrl={baseUrl}
            lang={lang}
            class="custom"
          />
        </AuthenticatedWidgetWithForm>
      )
    case 'cumulative-impact':
      return (
        <AuthenticatedWidgetWithForm>
          <beam-cumulative-impact
            chainId={chainId}
            cardStyle="image"
            baseUrl={baseUrl}
            lang={lang}
            class="custom"
          />
        </AuthenticatedWidgetWithForm>
      )
    case 'impact-overview':
      return (
        nonprofitId && (
          <AuthenticatedWidgetWithForm>
            <beam-impact-overview
              chainId={chainId}
              cardStyle="image"
              baseUrl={baseUrl}
              class="custom"
              userId={genericUserId}
              nonprofitId={nonprofitId}
              lang={lang}
            />
          </AuthenticatedWidgetWithForm>
        )
      )
    case 'redeem-transaction':
      return (
        <AuthenticatedWidgetWithForm>
          <beam-redeem-transaction
            chainId={chainId}
            cardStyle="image"
            baseUrl={baseUrl}
            transactionId={transactionId}
            class="custom"
            storeId={storeId}
            lang={lang}
          />
        </AuthenticatedWidgetWithForm>
      )
    case 'select-nonprofit':
      return (
        <AuthenticatedWidgetWithForm>
          <beam-select-nonprofit
            chainId={chainId}
            cardStyle="image"
            baseUrl={baseUrl}
            lang={lang}
            class="custom"
          />
        </AuthenticatedWidgetWithForm>
      )
    case 'product-details-page':
      return (
        <AuthenticatedWidgetWithForm>
          <beam-product-details-page storeId={storeId} baseUrl={baseUrl} class="custom" />
        </AuthenticatedWidgetWithForm>
      )
    case 'select-subscription-nonprofit':
      return (
        <AuthenticatedWidgetWithForm>
          <beam-select-subscription-nonprofit
            subscriptionId={subscriptionId}
            chainId={chainId}
            storeId={storeId}
            baseUrl={baseUrl}
            class="custom"
          />
        </AuthenticatedWidgetWithForm>
      )
    case 'subscription-impact':
      return (
        <AuthenticatedWidgetWithForm>
          <beam-subscription-impact
            chainId={chainId}
            storeId={storeId}
            baseUrl={baseUrl}
            beamUserId={genericUserId}
            nonprofitId={nonprofitId}
            class="custom"
          />
        </AuthenticatedWidgetWithForm>
      )
    case 'subscription-management':
      return (
        <AuthenticatedWidgetWithForm>
          <beam-subscription-management
            chainId={chainId}
            email={email}
            subscriptionId={subscriptionId}
            storeId={storeId}
            baseUrl={baseUrl}
            class="custom"
          />
        </AuthenticatedWidgetWithForm>
      )
    default:
      return null
  }
}
