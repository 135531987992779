import { useEffect, useState } from 'react'

import { BeamButton } from '../../../stories/BeamButton'
import { BeamDropdown } from '../../../stories/BeamDropdown'
import { BeamModal } from '../../../stories/BeamModal'
import { BeamTextfield } from '../../../stories/BeamTextfield'
import { BeamToggle } from '../../../stories/BeamToggle'
import {
  createKlaviyoTrigger,
  TKlaviyoTrigger,
  TNewTriggerData,
  TriggerType,
  updateKlaviyoTrigger,
} from './KlaviyoPage.helpers'

interface TriggerEditModalProps {
  chainId: string
  close: () => void
  oldTriggerData: TKlaviyoTrigger | null
  onError: (message: string) => void
  onSuccess: (newTrigger: TKlaviyoTrigger) => void
  open: boolean
}

export const TriggerEditModal = ({
  chainId,
  close,
  oldTriggerData,
  onError,
  onSuccess,
  open,
}: TriggerEditModalProps) => {
  const [triggerData, setTriggerData] = useState<TNewTriggerData>({
    id: null,
    isActive: true,
    percent: 70,
    triggerType: 'community_progress',
  })
  const [submitting, setSubmitting] = useState(false)

  useEffect(() => {
    if (oldTriggerData) {
      // percent from API data is in float form
      const integerPercent = oldTriggerData.percent ? oldTriggerData.percent * 100 : 0

      setTriggerData({
        id: oldTriggerData.id,
        isActive: oldTriggerData.isActive,
        percent: integerPercent,
        triggerType: oldTriggerData.percent ? 'community_progress' : 'community_progress_restart',
      })
    }
  }, [oldTriggerData])

  const editMode = !!oldTriggerData // whether this modal is editing an existing trigger or creating a new one
  const triggerTypeOptions: TriggerType[] = ['community_progress', 'community_progress_restart']

  function onInputChange(e: any) {
    let value = e.target.value

    if (e.target.name === 'percent') {
      value = Number(e.target.value)
    }
    if (e.target.name === 'isActive') {
      value = e.target.checked
    }

    setTriggerData({
      ...triggerData,
      [e.target.name]: value,
    })
  }

  async function handleSubmit() {
    setSubmitting(true)

    try {
      const data = editMode
        ? await updateKlaviyoTrigger(triggerData)
        : await createKlaviyoTrigger({ ...triggerData, chainId: +chainId })

      setSubmitting(false)
      onSuccess(data)
    } catch (error: any) {
      onError(error)
      setSubmitting(false)
    }
  }

  const ModalBody = (
    <div>
      <div className="flex flex-col space-y-4">
        <BeamDropdown
          name="triggerType"
          label="Trigger Type"
          options={triggerTypeOptions.map(opt => ({ label: opt, value: opt }))}
          value={triggerData.triggerType}
          onChange={onInputChange}
          disabled={editMode}
        />
        {triggerData.triggerType === 'community_progress' && (
          <BeamTextfield
            name="percent"
            label="Percent (max=99)"
            value={triggerData.percent ? `${triggerData.percent}` : '0'}
            onChange={onInputChange}
          />
        )}
        <BeamToggle
          name="isActive"
          label={triggerData.isActive ? 'Active' : 'Inactive'}
          checked={triggerData.isActive}
          onChange={onInputChange}
        />
      </div>
    </div>
  )

  return (
    <BeamModal
      open={open}
      label={editMode ? 'Edit Klaviyo trigger' : 'Create new Klaviyo trigger'}
      onCloseCallback={close}
      body={ModalBody}
      disableClose={submitting}
      footer={
        <div className="mt-8">
          <BeamButton label="Submit" variant="elevated" onClick={handleSubmit} />
        </div>
      }
    />
  )
}
