import Grid from '@material-ui/core/Grid'
import { ChangeEventHandler, CSSProperties, HTMLInputTypeAttribute } from 'react'

import formStyles from '../../style/root/beam-form-fields.module.css'
import BeamFilePicker from './BeamFilePicker'
import { BeamInputValue } from './BeamForm'
import { BeamSwitch } from './BeamSwitch'

export interface BeamInputProps {
  label: string
  name: string
  value?: BeamInputValue
  detailText?: string
  inputError?: boolean
  errorText?: string
  type?: HTMLInputTypeAttribute
  file?: string
  changeHandler: (name: string, value: any) => void
  labelStyle?: CSSProperties
  inputStyle?: CSSProperties
  inputContainerStyle?: CSSProperties
  inputContainerStyleOverride?: CSSProperties
  labelContainerStyleOverride?: CSSProperties
  numberOptions?: {
    min?: number
    max?: number
  }
}

const BeamInput = ({
  label,
  name,
  value,
  detailText,
  inputError,
  errorText,
  changeHandler,
  type,
  file,
  inputStyle,
  labelStyle,
  inputContainerStyle,
  inputContainerStyleOverride,
  labelContainerStyleOverride,
  numberOptions,
}: BeamInputProps) => {
  const handleChange = (e: any) => {
    if (type === 'toggle') {
      changeHandler(name, !value)
    } else {
      changeHandler(name, e.target.value)
    }
  }
  const inputType = (
    name: string,
    handleChange: ChangeEventHandler,
    inputStyle?: CSSProperties,
    value?: string | number,
    type?: HTMLInputTypeAttribute
  ) => {
    switch (type) {
      case 'textarea':
        return (
          <textarea
            className={formStyles['beam-input']}
            value={value}
            name={name}
            onChange={handleChange}
            style={inputStyle || {}}
          />
        )
      case 'text':
        return (
          <input
            className={inputError ? formStyles['beam-input-error'] : formStyles['beam-input']}
            value={value}
            type={type}
            name={name}
            onChange={handleChange}
            style={inputStyle || {}}
          />
        )
      case 'number':
        return (
          <input
            className={inputError ? formStyles['beam-input-error'] : formStyles['beam-input']}
            value={value}
            type={type}
            name={name}
            onChange={handleChange}
            onWheel={e => {
              // prevents scrolling from changing input value
              e.currentTarget.blur()
              e.stopPropagation()
            }}
            style={inputStyle || {}}
            min={numberOptions?.min}
            max={numberOptions?.max}
          />
        )
      case 'date':
        return (
          <input
            className={inputError ? formStyles['beam-input-error'] : formStyles['beam-input']}
            value={value}
            type="date"
            name={name}
            onChange={handleChange}
            style={inputStyle || {}}
          />
        )
      default:
        return (
          <input
            className={formStyles['beam-input']}
            value={value}
            type="text"
            name={name}
            onChange={handleChange}
            style={inputStyle || {}}
          />
        )
    }
  }
  const isStringOrNumber = typeof value === 'string' || typeof value === 'number'
  return type && type === 'file' ? (
    <BeamFilePicker
      name={name}
      file={file}
      emitHandler={changeHandler}
      inputStyle={inputStyle}
      label={label}
    />
  ) : type && type === 'toggle' ? (
    <div>
      <Grid container spacing={1} style={{ alignItems: 'center', justifyContent: 'space-between' }}>
        <Grid item xs={5} sm={3}>
          <div style={{ minWidth: '100px', ...labelStyle }}>{label}:</div>
        </Grid>
        <Grid item xs={7} sm={9} style={{ justifyContent: 'flex-end', ...inputStyle }}>
          <BeamSwitch on={!!value} handleClick={handleChange} />
        </Grid>
      </Grid>
    </div>
  ) : (
    <div className={formStyles['beam-form-field-container']}>
      <Grid
        container
        spacing={1}
        style={{ alignItems: 'center', justifyContent: 'space-between', ...inputContainerStyle }}>
        <Grid
          item
          {...(labelContainerStyleOverride
            ? { style: labelContainerStyleOverride }
            : { xs: 5, sm: 3 })}>
          <div style={{ minWidth: '100px', ...labelStyle }}>{label}:</div>
          {inputError && errorText && (
            <div style={{ fontSize: '12px', color: 'red' }}>{errorText}</div>
          )}
        </Grid>
        <Grid
          item
          {...(inputContainerStyleOverride
            ? { style: inputContainerStyleOverride }
            : { xs: 7, sm: 9, style: { justifyContent: 'flex-end' } })}>
          {inputType(name, handleChange, inputStyle || {}, isStringOrNumber ? value : '', type)}
          <div style={{ fontSize: '12px' }}>{detailText}</div>
          <div>{}</div>
        </Grid>
      </Grid>
    </div>
  )
}
export default BeamInput
