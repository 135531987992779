import { useBeamSelector } from '../../hooks'
import { APIError } from '../root/APIError'

// TODO: Break up this file
export const PartnerReportPage = () => {
  // FIXME: Remove all uses of `any` when we have typedefs from reducers
  const loadingStates = useBeamSelector(({ loadingStates }) => loadingStates) as any

  if (loadingStates?.user?.loading)
    return <div className="loading-message">One moment please...</div>
  if (loadingStates?.user?.error)
    return <APIError error={loadingStates.user.error} withRedirect={true} />
  return (
    <div>
      <h1>Page no longer in service</h1>
    </div>
  )
}
