import { SlCopyButton } from '@shoelace-style/shoelace/dist/react'
import { createColumnHelper } from '@tanstack/react-table'
import cx from 'classnames'
import { orderBy } from 'lodash'

import { BeamButton } from '../../../stories/BeamButton'
import { BeamTable } from '../../../stories/BeamTable'
import { PartnerUploadBody } from '../../../utils/types'
import { handleClickDownloadPartnerUpload } from '../../partner/PartnerUploads/PartnerUploadsPage.helpers'
import { gtag } from '../BeamGoogleAnalytics/googleAnalyticsHelpers'
import { partnerUploadsUrl } from '../Root'
import $$ from './partner-uploads-table.module.css'
import { PartnerUploadsTableLoadingOverlay } from './PartnerUploadsTableLoadingOverlay'

interface PartnerUploadsTableProps {
  data: PartnerUploadBody[]
  /**
   * Whether this partnerUploads table is used in the admin side or partner side
   */
  isAdminView: boolean
  /**
   * Loading state for the table
   */
  loading: boolean
  onClickDelete?: (fileToDelete: PartnerUploadBody) => void
}

export const PartnerUploadsTable = (props: PartnerUploadsTableProps) => {
  const { isAdminView = false, data, loading = false, onClickDelete } = props
  const columnHelper = createColumnHelper<PartnerUploadBody>()
  const sortedPartnerUploads = orderBy(data, ['createdAt'], ['desc'])

  const columns = [
    columnHelper.accessor('fileName', {
      header: 'Document Name',
    }),
    columnHelper.display({
      id: 'download',
      header: 'Download',
      enableSorting: false,
      cell: ({ row }) => {
        const rowData = row.original
        return (
          <div>
            <BeamButton
              label={'Download'}
              variant={'text'}
              type={'button'}
              className={$$.tableButton}
              onClick={() => {
                if (!isAdminView) {
                  gtag('Custom Reports | Clicked Download Custom Report', {
                    fileName: rowData.fileName,
                    chainId: rowData.chainId,
                  })
                }

                handleClickDownloadPartnerUpload({
                  chainId: rowData.chainId,
                  partnerUploadId: rowData.id,
                  fileName: rowData.fileName,
                })
              }}
            />
          </div>
        )
      },
    }),
  ]

  // Prevent showing the delete column in non-admin versions
  if (isAdminView) {
    columns.push(
      columnHelper.display({
        id: 'Delete',
        header: 'DELETE',
        enableSorting: false,
        cell: ({ row }) => {
          const rowData = row.original
          return (
            <div>
              <BeamButton
                label={'Delete File'}
                variant={'text'}
                type={'button'}
                className={$$.tableButton}
                onClick={() => {
                  if (!onClickDelete) {
                    console.log('No delete handler provided.')
                    return
                  }

                  onClickDelete(rowData)
                }}
              />
            </div>
          )
        },
      })
    )
    columns.push(
      columnHelper.display({
        id: 'Share',
        header: 'PARTNER LINK',
        enableSorting: false,
        cell: ({ row }) => {
          const rowData = row.original

          return (
            <div className={'text-lg text-center'}>
              <SlCopyButton
                copyLabel={'Copy shareable link to clipboard'}
                value={window.location.origin + `${partnerUploadsUrl}/${rowData.id}`}
                className={$$.copyLinkButton}
              />
            </div>
          )
        },
      })
    )
  }

  return (
    <div className={'relative'} aria-live={'polite'}>
      <PartnerUploadsTableLoadingOverlay loading={loading} />

      <BeamTable
        className={cx($$.partnerUploadsTable)}
        columns={columns}
        data={sortedPartnerUploads}
        condensed={true}
        noDataMessage={'No reports uploaded to Partner Portal yet'}
        enableSorting
      />
    </div>
  )
}
