// import { SlIcon } from '@shoelace-style/shoelace/cdn/react'
import { createColumnHelper } from '@tanstack/react-table'

import { BeamButton } from '../../../stories/BeamButton'
import { BeamTable } from '../../../stories/BeamTable'
import { PartnerUploadBody } from '../../../utils/types'
import { PartnerUploadsTableLoadingOverlay } from '../../root/PartnerUploadsTable/PartnerUploadsTableLoadingOverlay'
import $$ from './archived-partner-uploads-table.module.css'

interface ArchivedPartnerUploadsTableProps {
  data: PartnerUploadBody[]
  /**
   * Loading state for the table
   */
  loading: boolean
  onClickRestore: (fileToRestore: PartnerUploadBody) => void
}

export const ArchivedPartnerUploadsTable = (props: ArchivedPartnerUploadsTableProps) => {
  const { data, loading, onClickRestore } = props
  const columnHelper = createColumnHelper<PartnerUploadBody>()

  const columns = [
    columnHelper.accessor('fileName', {
      header: 'Document Name',
    }),
    columnHelper.display({
      id: 'Recover',
      header: 'RECOVER',
      cell: ({ row }) => {
        const rowData = row.original
        return (
          <div>
            <BeamButton
              label={'Restore'}
              variant={'text'}
              type={'button'}
              className={$$.restoreButton}
              onClick={() => {
                onClickRestore(rowData)
                return
              }}
            />
          </div>
        )
      },
    }),
  ]

  return (
    <div className={'relative'} aria-live={'polite'}>
      <PartnerUploadsTableLoadingOverlay loading={loading} />

      <h2 className={'text-center'}>Archived</h2>
      <BeamTable
        className={$$.archivedPartnerUploads}
        columns={columns}
        data={data}
        customClassnames={{
          thead: $$.thead,
        }}
      />
    </div>
  )
}
