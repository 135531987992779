import { API_BASE_PATH_PORTAL, getV2ApiUrl } from '../../api/auth'
import { axiosRequest } from '../../utils/axiosRequest'
import { setInvoices, setLoadingInvoices, setToast } from '../actions'

export const fetchInvoices = () => {
  return dispatch => {
    dispatch(setLoadingInvoices({ loading: true, error: false, message: 'Getting Invoices' }))
    axiosRequest('get', `${API_BASE_PATH_PORTAL}/invoices/all`)
      .then(res => {
        dispatch(setInvoices(res.data))
        dispatch(setLoadingInvoices({ loading: false, error: false }))
      })
      .catch(error => {
        dispatch(
          setLoadingInvoices({ loading: false, error, message: 'Failed to retrieve invoices' })
        )
      })
  }
}

export const fetchPartnerInvoices = partnerId => {
  return dispatch => {
    dispatch(setLoadingInvoices({ loading: true, error: false, message: 'Getting Invoices...' }))
    axiosRequest('get', `${getV2ApiUrl()}/invoices/partner/${partnerId}`)
      .then(res => {
        dispatch(setInvoices(res.data))
        dispatch(setLoadingInvoices({ loading: false, error: false }))
      })
      .catch(error => {
        dispatch(
          setLoadingInvoices({ loading: false, error, message: 'Failed to retrieve invoices' })
        )
      })
  }
}

export const createInvoices = () => {
  return dispatch => {
    dispatch(
      setLoadingInvoices({ loading: true, error: false, message: 'Creating Beam invoices...' })
    )
    axiosRequest('post', `${API_BASE_PATH_PORTAL}/invoices/create/partners/all`)
      .then(() => {
        dispatch(setLoadingInvoices({ loading: false, error: false }))
        dispatch(fetchPartnerInvoices())
      })
      .catch(error => {
        dispatch(
          setLoadingInvoices({ loading: false, error, message: 'Failed to create Beam invoices' })
        )
      })
  }
}

export const createBeamInvoice = (partnerId, month, withToast) => {
  return dispatch => {
    dispatch(
      setLoadingInvoices({ loading: true, error: false, message: 'Creating Beam invoice...' })
    )
    axiosRequest('post', `${API_BASE_PATH_PORTAL}/invoices/create/partner/one`, {
      partnerId,
      month,
    })
      .then(() => {
        dispatch(setLoadingInvoices({ loading: false, error: false }))
        if (withToast)
          dispatch(setToast({ showToast: true, message: 'Invoice Created', color: '#4caf50' }))
        dispatch(fetchInvoices(partnerId))
      })
      .catch(error => {
        dispatch(
          setLoadingInvoices({ loading: false, error, message: 'Failed to create Beam invoices' })
        )
        if (withToast)
          dispatch(
            setToast({
              showToast: true,
              message:
                (error.response && error.response.data && error.response.data.error) ||
                error.toString(),
              color: 'rgb(220, 0, 78)',
            })
          )
      })
  }
}

export const payPpgfOrder = invoiceId => {
  return dispatch => {
    dispatch(
      setLoadingInvoices({ loading: true, error: false, message: 'Creating Beam invoice...' })
    )
    axiosRequest('post', `${API_BASE_PATH_PORTAL}/invoices/pay/nonprofit/ppgf`, {
      invoiceId,
    })
      .then(res => {
        dispatch(setLoadingInvoices({ loading: false, error: false }))
        dispatch(setToast({ showToast: true, message: JSON.stringify(res.data), color: '#4caf50' }))
      })
      .catch(error => {
        dispatch(
          setLoadingInvoices({
            loading: false,
            error,
            message:
              error.response && error.response.data
                ? JSON.stringify(error.response.data)
                : JSON.stringify(error),
          })
        )
        dispatch(
          setToast({
            showToast: true,
            message:
              error.response && error.response.data
                ? JSON.stringify(error.response.data)
                : JSON.stringify(error),
            color: 'rgb(220, 0, 78)',
          })
        )
      })
  }
}

export const createCombinedInvoice = (
  partnerId,
  month,
  quarter,
  nonprofitDonationsDue,
  emailPartner,
  withToast,
  beamInvoiceDue,
  makeInvoiceVisible,
  scheduleNextInvoice
) => {
  return dispatch => {
    dispatch(
      setLoadingInvoices({ loading: true, error: false, message: 'Creating Beam invoice...' })
    )
    axiosRequest('post', `${API_BASE_PATH_PORTAL}/invoices/create/partner/combo`, {
      partnerId,
      month,
      quarter,
      nonprofitDonationsDue,
      emailPartner,
      beamInvoiceDue,
      makeInvoiceVisible,
      scheduleNextInvoice,
    })
      .then(() => {
        dispatch(setLoadingInvoices({ loading: false, error: false }))
        if (withToast)
          dispatch(setToast({ showToast: true, message: 'Invoice Created', color: '#4caf50' }))
        dispatch(fetchInvoices(partnerId))
      })
      .catch(error => {
        dispatch(
          setLoadingInvoices({
            loading: false,
            error,
            message:
              error.response && error.response.data
                ? JSON.stringify(error.response.data)
                : JSON.stringify(error),
          })
        )
        if (withToast)
          dispatch(
            setToast({
              showToast: true,
              message:
                error.response && error.response.data
                  ? JSON.stringify(error.response.data)
                  : JSON.stringify(error),
              color: 'rgb(220, 0, 78)',
            })
          )
      })
  }
}

export const createNonprofitInvoice = (partnerId, quarter, withToast, quarterName) => {
  return dispatch => {
    dispatch(
      setLoadingInvoices({ loading: true, error: false, message: 'Creating Nonprofit invoice...' })
    )
    axiosRequest('post', `${API_BASE_PATH_PORTAL}/invoices/create/nonprofits/one`, {
      partnerId,
      quarter,
      quarterName,
    })
      .then(() => {
        dispatch(setLoadingInvoices({ loading: false, error: false }))
        if (withToast)
          dispatch(setToast({ showToast: true, message: 'Invoice Created', color: '#4caf50' }))
        dispatch(fetchInvoices(partnerId))
      })
      .catch(error => {
        dispatch(
          setLoadingInvoices({ loading: false, error, message: 'Failed to create Beam invoices' })
        )
        if (withToast)
          dispatch(
            setToast({
              showToast: true,
              message:
                (error.response && error.response.data && error.response.data.error) ||
                error.toString(),
              color: 'rgb(220, 0, 78)',
            })
          )
      })
  }
}

export const createNonprofitInvoices = () => {
  return dispatch => {
    dispatch(
      setLoadingInvoices({ loading: true, error: false, message: 'Creating Nonprofit Invoices...' })
    )
    axiosRequest('post', `${API_BASE_PATH_PORTAL}/invoices/create/nonprofits/all`)
      .then(() => {
        dispatch(setLoadingInvoices({ loading: false, error: false }))
        dispatch(fetchInvoices())
      })
      .catch(error => {
        dispatch(
          setLoadingInvoices({
            loading: false,
            error,
            message: 'Failed to create nonprofit invoices',
          })
        )
      })
  }
}

export const markInvoicePaid = invoiceId => {
  return dispatch => {
    dispatch(setLoadingInvoices({ loading: true, error: false, message: 'Updating invoice...' }))
    axiosRequest('put', `${API_BASE_PATH_PORTAL}/invoices/pay`, { invoiceId })
      .then(() => {
        dispatch(setLoadingInvoices({ loading: false, error: false }))
        dispatch(fetchInvoices())
      })
      .catch(error => {
        dispatch(setLoadingInvoices({ loading: false, error, message: 'Failed to update invoice' }))
      })
  }
}
