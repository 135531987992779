import ExpandLessIcon from '@material-ui/icons/ExpandLess'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { CSSProperties, useEffect, useRef, useState } from 'react'

import styles from '../../style/root/beam-dropdown.module.css'

interface DateRange {
  start: Date
  end: Date
}
export interface DropdownOption {
  display: string
  value: string | number | boolean | DateRange
}

interface BeamDropdownProps {
  backgroundColor?: CSSProperties['backgroundColor']
  changeHandler: (name: string, value: any) => void
  disabled?: boolean
  handleMultiClose?: (name: string, value: string | number | boolean) => void
  hideClearSelection?: boolean
  multipleSelectedValues?: DropdownOption[]
  name?: string
  options: DropdownOption[] | any[]
  padding?: { top?: string; right?: string; bottom?: string; left?: string }
  placeholderDisplay?: string
  selectedValue?: any
  spaceAround?: { top?: string; right?: string; bottom?: string; left?: string }
  tabIndex?: number
  useMultipleSelection?: boolean
  width?: string
}

export const BeamDropdown = ({
  backgroundColor,
  changeHandler,
  disabled,
  handleMultiClose,
  hideClearSelection,
  multipleSelectedValues,
  name,
  options,
  padding,
  placeholderDisplay,
  selectedValue,
  spaceAround,
  tabIndex,
  useMultipleSelection = false,
  width,
}: BeamDropdownProps) => {
  const [expanded, setExpanded] = useState(false)
  const selectPlaceholder = useRef<HTMLDivElement>(null)
  const optionsContainer = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (!selectPlaceholder?.current || !optionsContainer?.current) return
    const placeHolderHeight = selectPlaceholder?.current?.getBoundingClientRect().height

    optionsContainer.current.style.top = `${placeHolderHeight}px`
    if (options.length > 4) {
      optionsContainer.current.style.height = `${placeHolderHeight * 4}px`
      optionsContainer.current.style.overflowY = 'scroll'
    }
  }, [options])

  const toggleExpand = () => {
    if (disabled) return
    setExpanded(!expanded)
  }

  const handleChange = (e: any) => {
    if (disabled) return
    const value = e.target.dataset.value
    const name = e.target.dataset.name

    if (value === undefined) return

    changeHandler(name, value)
    setExpanded(false)
  }

  const handleMultiChange = (e: any) => {
    if (disabled) return
    const value = e.target.dataset.value
    const name = e.target.dataset.name

    if (value === undefined) return

    if (handleMultiClose) {
      handleMultiClose(name, value)
    }
  }

  const displayValue = (value: any) => {
    const selectedValue =
      options[0] && options[0].value !== undefined
        ? options.filter(op => op.value == value)[0]
        : options.filter(op => op == value)[0]
    return selectedValue?.display ? selectedValue.display : selectedValue
  }

  const minimize = () => {
    setExpanded(false)
  }

  return (
    <div>
      <div className={styles['beam-dropdown-multi-item-container']}>
        {useMultipleSelection &&
          multipleSelectedValues?.map(value => (
            <div className={styles['beam-dropdown-multi-item']} key={`${value.value}`}>
              <button
                onClick={handleMultiChange}
                className={styles['beam-dropdown-multi-item-close-button']}
                data-value={value.value}
                data-name={value.display}>
                x
              </button>
              <div style={{ padding: '5px' }}>{value.display}</div>
            </div>
          ))}
      </div>
      <div
        tabIndex={tabIndex || 0}
        onBlur={minimize}
        onClick={handleChange}
        className={styles['beam-dropdown-container']}
        style={{
          margin:
            spaceAround &&
            `${spaceAround.top || '0px'} ${spaceAround.right || '0px'} ${
              spaceAround.bottom || '0px'
            } ${spaceAround.left || '0px'}`,
          backgroundColor,
          width: width || 'auto',
          minWidth: width,
          cursor: disabled ? 'not-allowed' : 'pointer',
        }}>
        <div
          onClick={toggleExpand}
          ref={selectPlaceholder}
          className={styles['beam-dropdown-placeholder']}
          style={{
            paddingTop: padding?.top || '18px',
            paddingRight: padding?.right || '23px',
            paddingBottom: padding?.bottom || '18px',
            paddingLeft: padding?.left || '23px',
            cursor: disabled ? 'not-allowed' : 'pointer',
          }}>
          {displayValue(selectedValue) || placeholderDisplay}
          {expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </div>
        <div
          ref={optionsContainer}
          style={{
            position: 'absolute',
            display: expanded ? '' : 'none',
            cursor: 'pointer',
            zIndex: 100,
            width: width || 'auto',
            boxSizing: 'border-box',
          }}>
          {placeholderDisplay && !hideClearSelection && (
            <option
              className={styles['beam-dropdown-item']}
              data-value=""
              data-name={name}
              style={{
                borderBottom: '1px solid #808191',
                borderTopRightRadius: '8px',
                borderTopLeftRadius: '8px',
                paddingTop: padding?.top || '18px',
                paddingRight: padding?.right || '23px',
                paddingBottom: padding?.bottom || '18px',
                paddingLeft: padding?.left || '23px',
              }}>
              Clear Selection
            </option>
          )}
          {options.map((op, i) => (
            <div
              key={`op-${name}-${i}`}
              style={{
                borderTopRightRadius: i === 0 && !placeholderDisplay ? '8px' : '',
                borderTopLeftRadius: i === 0 && !placeholderDisplay ? '8px' : '',
                borderBottomRightRadius: i === options.length - 1 ? '8px' : '',
                borderBottomLeftRadius: i === options.length - 1 ? '8px' : '',
                borderBottom: i < options.length - 1 ? '1px solid #808191' : 'none',
                paddingTop: padding?.top || '18px',
                paddingRight: padding?.right || '23px',
                paddingBottom: padding?.bottom || '18px',
                paddingLeft: padding?.left || '23px',
              }}
              data-value={op.value !== undefined ? op.value : op}
              data-name={op.display !== undefined ? op.display : name}
              className={styles['beam-dropdown-item']}>
              {op.display !== undefined ? op.display : op}
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default BeamDropdown
