import { Link } from 'react-router-dom'

export const PageNotFound = () => {
  return (
    <div>
      <h1>Page Not Found</h1>
      <Link to={`/`}>Go Home</Link>
    </div>
  )
}
