import React from 'react'

import BeamForm from '../root/BeamForm'
import { AggregateTargetChainNonprofitInfo } from './CreateCentralBackendPartner'

const CreatePartnerNonprofit = ({
  nonprofitInformation,
  handleNonprofitInput,
}: {
  nonprofitInformation: AggregateTargetChainNonprofitInfo
  handleNonprofitInput: (name: string, value: number) => void
}) => {
  const handleInput = (name: string, value: any) => {
    handleNonprofitInput(name, value)
  }

  const formFields = [
    {
      name: 'active',
      label: 'Is Active',
      type: 'toggle',
      value: nonprofitInformation.active,
    },
    {
      name: 'includeInPersonalImpact',
      label: 'Include In Personal Impact',
      type: 'toggle',
      value: nonprofitInformation.includeInPersonalImpact,
    },
    {
      name: 'targetUserDonationAmount',
      label: 'Personal Target Amount',
      type: 'text',
      value: nonprofitInformation.targetUserDonationAmount || '',
      errorText: 'Personal Target Amount must be a number',
      inputError: nonprofitInformation?.targetUserDonationAmount
        ? isNaN(nonprofitInformation.targetUserDonationAmount)
        : false,
    },
    {
      name: 'userImpactDescription',
      label: 'Personal Target Text',
      type: 'textarea',
      value: nonprofitInformation.userImpactDescription || '',
    },
    {
      name: 'targetChainDonationAmount',
      label: 'Community Target Amount',
      type: 'text',
      value: nonprofitInformation.targetChainDonationAmount || '',
      errorText: 'Community Target Amount must be a number',
      inputError: nonprofitInformation?.targetChainDonationAmount
        ? isNaN(nonprofitInformation.targetChainDonationAmount)
        : false,
    },
    {
      name: 'chainImpactDescription',
      label: 'Community Target Text',
      type: 'textarea',
      value: nonprofitInformation.chainImpactDescription || '',
    },
    {
      name: 'chainImpactImage',
      label: 'Community Target Image',
      type: 'file',
      file:
        typeof nonprofitInformation.chainImpactImage === 'string'
          ? nonprofitInformation.chainImpactImage
          : nonprofitInformation.chainImpactImage?.name || '',
      value: nonprofitInformation.chainImpactImage,
    },
    {
      name: 'causeDisplayName',
      label: 'Cause Display Name',
      type: 'text',
      value: nonprofitInformation.causeDisplayName || '',
    },
    {
      name: 'causeDisplayIcon',
      label: 'Cause Display Icon',
      type: 'file',
      file:
        typeof nonprofitInformation.causeDisplayIcon === 'string'
          ? nonprofitInformation.causeDisplayIcon
          : nonprofitInformation.causeDisplayIcon?.name || '',
      value: nonprofitInformation.causeDisplayIcon,
    },
    {
      name: 'causeSelectedImage',
      label: 'Cause Selected Image',
      type: 'file',
      file:
        typeof nonprofitInformation.causeSelectedImage === 'string'
          ? nonprofitInformation.causeSelectedImage
          : nonprofitInformation.causeSelectedImage?.name || '',
      value: nonprofitInformation.causeSelectedImage,
    },
    {
      name: 'sortOrder',
      label: 'Sort Order',
      type: 'text',
      value: nonprofitInformation.sortOrder || '',
      errorText: 'Sort Order must be a number',
      inputError: nonprofitInformation?.sortOrder ? isNaN(nonprofitInformation.sortOrder) : false,
      detailText:
        '* indicates the order nonprofits are displayed in checkout selection & community impact display, lowest is shown first',
    },
    {
      name: 'filters',
      label: 'Filters',
      type: 'text',
      value: nonprofitInformation?.filters?.toString() || '',
      detailText: '*Comma separated list',
    },
  ]

  return (
    <div>
      <h3>{nonprofitInformation.name}</h3>
      <BeamForm fields={formFields} globalChangeHandler={handleInput} />
    </div>
  )
}

export default CreatePartnerNonprofit
