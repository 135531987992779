import '../../style/admin/invoices.css'

import { useEffect } from 'react'
import { useDispatch } from 'react-redux'

import { useBeamSelector } from '../../hooks'
import { fetchPartnerInvoices } from '../../redux/thunks/invoiceThunks'
import { TUser } from '../../utils/types'
import { APIError } from '../root/APIError'
import { PageNotFound } from '../root/PageNotFound'
import { WelcomeNote } from '../root/WelcomeNote'
import PartnerInvoiceTable from './PartnerInvoiceTable'

export const PartnerInvoices = () => {
  const user = useBeamSelector(({ user }) => user) as TUser | undefined
  const invoices = useBeamSelector(({ invoices }) => invoices)
  const loadingStates = useBeamSelector(({ loadingStates }) => loadingStates) as any
  const dispatch = useDispatch()

  useEffect(() => {
    if (invoices || loadingStates.invoices?.loading || loadingStates.invoices?.error) return

    dispatch(fetchPartnerInvoices(user?.partnerId))
  }, [invoices, loadingStates?.invoices, dispatch, user?.partnerId])

  if (loadingStates?.user?.loading)
    return <div className="loading-message">One moment please...</div>
  if (loadingStates?.user?.error)
    return <APIError error={loadingStates.user.error} withRedirect={true} />
  if (user?.type !== 'Executive') return <PageNotFound />

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '100%',
        overflow: 'hidden',
      }}>
      <WelcomeNote
        firstName={user?.firstName}
        lastName={user?.lastName}
        pageTitle="Your Invoices"
      />
      <PartnerInvoiceTable invoices={invoices} user={user} />
    </div>
  )
}
