import { sortBy } from 'lodash'
import { useMemo } from 'react'

import { BeamButton } from '../../../stories/BeamButton'
import { getTriggerType, TKlaviyoTrigger } from './KlaviyoPage.helpers'

interface KlaviyoTriggersGridProps {
  klaviyoTriggers: TKlaviyoTrigger[]
  setModalOpen: (open: true) => void
  setSelectedTrigger: (trigger: TKlaviyoTrigger) => void
}

export const KlaviyoTriggersGrid = ({
  klaviyoTriggers,
  setModalOpen,
  setSelectedTrigger,
}: KlaviyoTriggersGridProps) => {
  // sort by active triggers first
  const sortedTriggers = useMemo(
    () => sortBy(klaviyoTriggers, t => (t.isActive ? 0 : 1)),
    [klaviyoTriggers]
  )

  return (
    <div className="grid w-full grid-flow-row grid-cols-2 gap-4 tablet:grid-cols-3 desktop:grid-cols-6">
      {sortedTriggers.map(klaviyoTrigger => {
        const triggerType = getTriggerType(klaviyoTrigger)
        const displayName =
          triggerType === 'community_progress' ? 'Community Progress' : 'Community Progress Restart'

        return (
          <div
            className="bg-coral-100  shadow-md shadow-charcoal-200 w-full h-[200px] rounded-lg p-2 overflow-hidden flex flex-col items-center justify-between"
            key={klaviyoTrigger.template + klaviyoTrigger.id}>
            <div>
              <p className="pt-4 pb-2 font-bold text-center text-water-800 leading-[1.2]">
                {displayName}
              </p>
              {klaviyoTrigger.percent && (
                <p className="font-semibold text-center text-black text-md leading-[1.2]">
                  Threshold: {klaviyoTrigger.percent * 100}%
                </p>
              )}
              <p className="font-semibold text-center text-black text-md">
                {klaviyoTrigger.isActive ? 'Active ✅' : 'Inactive 🚫'}
              </p>
            </div>

            <div className="mb-3">
              <BeamButton
                label="Edit"
                variant="elevated"
                onClick={() => {
                  setSelectedTrigger(klaviyoTrigger)
                  setModalOpen(true)
                }}
              />
            </div>
          </div>
        )
      })}

      <div
        aria-label="Add new trigger"
        className="bg-charcoal-100 w-full h-[200px] shadow-md shadow-charcoal-200 rounded-lg flex justify-center items-center group cursor-pointer"
        onClick={() => setModalOpen(true)}>
        <div className="flex items-center justify-center w-16 h-16 transition ease-in-out rounded-full bg-charcoal-200 group-hover:bg-cherry-200">
          <div className='relative bg-charcoal-100 h-12 w-3 rounded-md group-hover:bg-charcoal-50 transition ease-in-out after:bg-charcoal-100 after:content-[""] after:h-3 after:w-12 after:absolute after:top-[18px] after:-left-[18px] after:rounded-md after:group-hover:bg-charcoal-50 after:transition after:ease-in-out' />
        </div>
      </div>
    </div>
  )
}
